import { useTranslation } from 'react-i18next';
import { FormatCurrency } from '../../helpers/currency-helper';
import { Market, MarketResolvedTo } from '../../types/types';
import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg';
import { useWebsocket } from '../../context/websocket-context';
import { ResolvedMarketCardComponent } from '../../components/market-card/resolved-market-card-component';
import { MarketResolution } from '../../components/market-resolution/market-resolution-component';
import { formatUnits } from 'viem';
import { getAcquisitionPriceForToken } from '../../helpers/filter-helper';

export const PersonItem = ({
  noHeader,
  className,
  market,
  positionsData,
}: {
  market: Market;
  noHeader?: boolean;
  className?: string;
  positionsData?: any;
}) => {
  const { t } = useTranslation();
  const { sharesByMarketUpdate } = useWebsocket();

  return (
    <div>
      <div
        className={`px-3 flex py-3 items-center justify-between ${noHeader ? 'border-b border-grey-secondary' : ''} pr-10 text-sm ${className ?? ''}`}
      >
        <div className="w-[70%] gap-4 flex items-center">
          {market?.image ? (
            <img
              className="w-[40px] h-[40px] rounded-lg object-cover"
              src={market.image}
            />
          ) : (
            <Avatar className="min-w-[40px] min-h-[40px] bg-grey rounded-lg p-2" />
          )}{' '}
          <div>
            <p className="text-white">{market.name}</p>
            <p className="text-grey-secondary-darker">
              {FormatCurrency(
                +(formatUnits(BigInt(market?.totalBet ?? 0), 6) ?? 0),
                false,
                2
              )}{' '}
              {t`Bet`}
            </p>
          </div>
          {+sharesByMarketUpdate[market.id]?.value?.token1?.balance > 0 &&
            market?.tokenId1 && (
              <p className="hidden md:block py-1 px-2 text-green-light font-bold bg-green-opacity">
                {market?.outcome1}{' '}
                {parseFloat(
                  sharesByMarketUpdate[market.id].value.token1.balance
                ).toFixed(2)}{' '}
                -{' '}
                {Math.floor(
                  parseFloat(
                    getAcquisitionPriceForToken(
                      positionsData,
                      market?.tokenId1
                    )
                  ) * 100
                )}
                ¢
              </p>
            )}
          {+sharesByMarketUpdate[market.id]?.value?.token2?.balance > 0 &&
            market?.tokenId2 && (
              <p className="hidden md:block py-1 px-2 bg-red-opacity font-bold text-red-normal">
                {market?.outcome2}{' '}
                {parseFloat(
                  sharesByMarketUpdate[market.id].value.token2.balance
                ).toFixed(2)}{' '}
                -{' '}
                {Math.floor(
                  parseFloat(
                    getAcquisitionPriceForToken(
                      positionsData,
                      market?.tokenId2
                    )
                  ) * 100
                )}
                ¢
              </p>
            )}
        </div>
        <p className="text-white font-bold text-base ">
          {market.isResolved ? (
            <ResolvedMarketCardComponent
              isMultiMarket
              marketResolvedTo={market.marketResolvedTo as MarketResolvedTo}
              outcome1={market.outcome1 as string}
              outcome2={market.outcome2 as string}
            />
          ) : (
            <MarketResolution resolution={market.outcome1_chance ?? '0'} />
          )}
        </p>
      </div>
    </div>
  );
};
