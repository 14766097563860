import { Button } from '../../components/button/button-component';
import { InputComponent } from '../../components/input/input-component';
import { ReactComponent as USDC } from '../../assets/icons/usdc.svg';
import { ReactComponent as Danger } from '../../assets/icons/danger-icon.svg';
import { useModal } from '../../context/modal-context';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  checkForTxSuccess,
  transferUSDCe,
  waitForTransactionCompletion,
} from '../../helpers/blockchain-helper';
import { useBlockchain } from '../../context/blockchain-context';
import { FormatCurrency } from '../../helpers/currency-helper';
import { getErrorMessage } from '../../helpers/error-helper';
import {
  isEthereumAddress,
  isEthereumAmount,
} from '../../helpers/validation-helper';
import { useWebsocket } from '../../context/websocket-context';
import { useContracts } from '../../context/contracts-context';
import { TransactionReceipt } from 'viem';
import { getTxDetailsUrl } from '../../helpers/link-helper';

interface FormValues {
  recipient: string;
  amount: string;
}

export const WithdrawModal = () => {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(false);
  const { closeModal, openModal } = useModal();
  const { proxyWalletAddress } = useBlockchain();
  const { collateralBalance } = useWebsocket();
  const contracts = useContracts();
  const USDCe_ADDRESS = contracts.data.find(({ name }) => name === 'COLLATERAL')
    ?.address as string;
  const USDC_CONTRACT_ADDRESS = contracts.data.find(
    ({ name }) => name === 'USDC'
  )?.address as string;
  const MULTISEND_ADDRESS = contracts.data.find(
    ({ name }) => name === 'MULTISEND'
  )?.address as string;
  const SWAP_ROUTER = contracts.data.find(({ name }) => name === 'SWAP_ROUTER')
    ?.address as string;
  const [recipient, setRecipient] = useState<string>();
  const [amount, setAmount] = useState<string>();
  const [errors, setErrors] = useState<FormValues>({
    recipient: '',
    amount: '',
  });
  useEffect(() => {
    if (amount !== undefined && +amount > 0) {
      validateAmount();
    }
  }, [amount]);
  useEffect(() => {
    if (recipient && recipient.length > 0) {
      validateEtheriumField();
    }
  }, [recipient]);

  const withdraw = async () => {
    const validAmount = validateAmount(true);
    const validAddress = validateEtheriumField(validAmount.result);
    if (!validAmount.isValid || !validAddress) {
      return;
    }
    if (proxyWalletAddress) {
      let txHash = '';
      try {
        setIsloading(true);

        txHash = await transferUSDCe(
          proxyWalletAddress,
          contracts.data[0].chain_id,
          (amount ?? '').toString(),
          USDCe_ADDRESS,
          MULTISEND_ADDRESS,
          recipient
        );
        const receipt: TransactionReceipt = await waitForTransactionCompletion(
          txHash as `0x${string}`,
          1
        );
        if (checkForTxSuccess(receipt, txHash)) {
          openModal({
            content: (
              <>
                <p className="text-center">{t`Transfer successful`}</p>
                {getTxDetailsUrl(txHash)}
              </>
            ),
            title: 'Transfer',
            type: 'success',
          });
        }
      } catch (e) {
        handleErrorModal(
          typeof e === 'string' ? e : (e as Error)?.message,
          txHash
        );
      } finally {
        setIsloading(false);
      }
    }
  };
  const handleErrorModal = (e: string, txHash: string) => {
    openModal({
      content: (
        <>
          <p className="text-center">{getErrorMessage(e)}</p>
          {getTxDetailsUrl(txHash)}
        </>
      ),
      title: 'Error',
      type: 'error',
    });
    setIsloading(false);
  };
  const validateEtheriumField = (
    errors: FormValues = {} as FormValues
  ): boolean => {
    const tempErrors = { recipient: '', ...{ amount: errors.amount } };
    let isValid = true;

    if (!recipient) {
      tempErrors.recipient = 'Wallet address is required';
      isValid = false;
    } else if (!isEthereumAddress(recipient)) {
      tempErrors.recipient = 'Invalid address';
      isValid = false;
    }

    setErrors({ ...errors, ...tempErrors });
    return isValid;
  };
  const validateAmount = (
    dontChangeState?: boolean
  ): { isValid: boolean; result: FormValues } => {
    const tempErrors = { amount: '' };
    let isValid = true;
    if (!amount) {
      tempErrors.amount = t`Amount is required`;
      isValid = false;
    } else if (+amount > collateralBalance.value) {
      tempErrors.amount = t`Insufficient funds. Please withdraw less USDC than your balance or use the Max button`;
      isValid = false;
    } else if (!isEthereumAmount(amount)) {
      tempErrors.amount = t`Invalid amount`;
      isValid = false;
    }
    const result = { ...errors, ...tempErrors };
    if (!dontChangeState) {
      setErrors(result);
    }
    return { isValid, result };
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;

    // Prevent the input from starting with a dot
    if (value.startsWith('.')) {
      setAmount(''); // or you can set it to '0.' or another default value
      return;
    }

    // Check if the value is a valid number format
    if (/^\d*\.?\d*$/.test(value)) {
      // Handle cases where the number starts with "0"
      if (value === '0' || value === '0.') {
        // Allow "0" or "0."
        setAmount(value);
      } else if (value.startsWith('0') && !value.startsWith('0.')) {
        // Prevent entering multiple leading zeros
        setAmount('0.' + value.slice(1));
      } else {
        // Limit the number of decimal places to 5
        if (value.includes('.')) {
          const [integerPart, decimalPart] = value.split('.');
          if (decimalPart.length > 6) {
            value = `${integerPart}.${decimalPart.slice(0, 6)}`;
          }
        }
        setAmount(value);
      }
    } else if (value === '') {
      // Handle empty input
      setAmount('');
    }
  };

  return (
    <div className="flex flex-col text-sm gap-6" data-testid="withdraw-modal">
      <div className="grid gap-2">
        <p className="text-white font-bold text-xs">{t`Enter your address`}</p>
        <InputComponent
          onChange={({ target: { value } }) => {
            setRecipient(value);
          }}
          data-testid="input-recipient-address"
          placeholder={t`Enter address`}
          className={`bg-surface-modal text-text-secondary py-3 px-4 ${errors.recipient ? 'border border-red-error' : ''}`}
          type="text"
        />
        <div
          className={`text-xs text-red-error flex items-center gap-1 ${errors.recipient ? 'visible' : 'invisible'}`}
        >
          <Danger width={16} height={16} />
          {errors.recipient}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center">
          <p className="text-white font-bold text-xs">{t`Amount`}</p>
          <div
            data-testid="balance-amount"
            className="bg-grey-light py-1 px-3 rounded-md text-xs text-white font-bold"
          >
            {t`Balance`} {FormatCurrency(collateralBalance.value, false, 2)}
          </div>
        </div>
        <div className="flex flex-col gap-2 bg-surface-modal rounded-2xl pt-6 pb-3 px-4">
          <div className="flex justify-between">
            <div className="flex flex-row gap-1 items-center">
              <p className="text-text-secondary w-[100%]">{t`You withdraw`}</p>
            </div>
            <p
              className="rounded-md py-1 px-2 bg-water-blue-opacity text-water-blue font-bold cursor-pointer"
              data-testid="button-MAX"
              onClick={() => {
                setAmount(collateralBalance.value.toString());
              }}
            >
              {t`MAX`}
            </p>
          </div>
          <div className="grid grid-cols-3 justify-between">
            <div className="flex flex-row items-center gap-2">
              <div>
                <USDC />
              </div>
              <div>
                {/* <DropdownComponent
                  data-testid="dropdown-currency"
                  fontSize="text-base"
                  fontBold
                  primaryColor=""
                  bgColor="bg-dropdown-black"
                  border
                  borderColor={errors.amount ? 'border-red-error' : undefined}
                  onChange={(item) => {
                    setSelectedCurrency(item as Option);
                  }}
                  value={selectedCurrency}
                  options={withdrawOptions}
                  minW="w-[150px]"
                /> */}
                <p className="text-white text-base font-bold">USDC</p>
              </div>
            </div>
            <InputComponent
              onChange={handleChange}
              data-testid="input-amount"
              value={amount}
              textsize="text-2xl"
              placeholder="0"
              className="col-span-2 text-text-secondary font-bold w-auto text-right"
              noborder="true"
            />
          </div>
        </div>
        <div
          className={`text-xs text-red-error flex gap-2 min-h-[32px] ${errors.amount ? 'visible' : 'invisible'}`}
        >
          <Danger style={{ minWidth: '16px' }} />
          {errors.amount}
        </div>
      </div>
      <Button
        variant="blue"
        onClick={async () => {
          await withdraw();
        }}
        data-testid="button-Continue"
      >
        {isLoading ? (
          <div
            className="spinner m-auto"
            style={{
              width: 24,
              height: 24,
              border: '4px solid #fff',
              borderColor: '#fff transparent #fff transparent',
            }}
          ></div>
        ) : (
          t`Continue`
        )}
      </Button>
      <Button
        variant="transparent"
        data-testid="button-Cancel"
        onClick={closeModal}
      >{t`Cancel`}</Button>
    </div>
  );
};
