import { useState } from 'react';
import { useModal } from '../../context/modal-context';
import CustomDatePicker from './calendar-component';
import { Button } from '../button/button-component';

export const CustomDatePickerModal = ({
  onApply,
}: {
  onApply: (date: Date) => void;
}) => {
  const { closeModal } = useModal();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleApply = () => {
    if (selectedDate) {
      const adjustedDate = new Date(selectedDate);
      adjustedDate.setHours(23, 59, 59, 999);

      onApply(adjustedDate);
      closeModal();
    }
  };

  return (
    <div className="p-6 bg-black-dark rounded-lg">
      <CustomDatePicker
        selectedDate={selectedDate}
        onChange={(date) => setSelectedDate(date)}
      />
      <div className="flex justify-center gap-4 mt-6">
        <Button
          onClick={() => closeModal()}
          className="bg-grey-lighter text-white py-2 px-14 rounded-md hover:bg-grey-light"
        >
          Cancel
        </Button>
        <Button
          onClick={handleApply}
          className="bg-blue-600 text-white py-2 px-14 rounded-md hover:bg-blue-dark"
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
