import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useReducer,
  useCallback,
} from 'react';
import io, { Socket } from 'socket.io-client';
import { formatUnits } from 'viem';
import { Decimal } from 'decimal.js';
import { User } from '../types/types';
import { useBlockchain } from './blockchain-context';

export enum WsPubEvent {
  PING = 'ping',
  INITIAL_DATA = 'initial-data',
  INITIAL_SHARES_BY_MARKET = 'initial-shares-by-market',
  ORDERBOOK_MARKET_CONNECT = 'orderbook-market-connect',
  ORDERBOOK_MARKET_DISCONNECT = 'orderbook-market-disconnect',
  SHARES_BY_MARKET_DISCONNECT = 'shares-by-market-disconnect',
}

export enum WsSubEvent {
  ERROR = 'app-error',
  PONG = 'pong',
  COLLATERAL_BALANCE = 'collateral-balance',
  SHARES_BY_MARKET_UPDATE = 'shares-by-market-update',
  ORDERBOOK_ORDER_CREATE = 'orderbook-order-create',
  ORDERBOOK_ORDER_UPDATE = 'orderbook-order-update',
  ORDERBOOK_ORDER_DELETE = 'orderbook-order-delete',
  COLLATERAL_BALANCE_LOADING = 'collateral-balance-loading',
}

export interface ICollateralBalanceEvent {
  value: number;
  loading?: boolean;
}

export interface IMarketSharesInfo {
  user?: User;
  marketId: string;
  token1: {
    id: string;
    balance: string;
  };
  token2: {
    id: string;
    balance: string;
  };
  [key: string]: any; // allow for additional properties
}

export interface IMarketSharesInfoValue {
  [key: string]: {
    value: IMarketSharesInfo;
  };
}

const registerAppHandlers = (
  socket: Socket,
  dispatch: React.Dispatch<IReducerEvent>
) => {
  // application errors
  socket.on(WsSubEvent.ERROR, (err) => {
    console.error('WS application error', err);
  });

  // application pull events
  socket.on(WsSubEvent.PONG, (data) => {
    console.log('WS server responsed to ping', data);
  });

  socket.on(
    WsSubEvent.SHARES_BY_MARKET_UPDATE,
    (payload: IMarketSharesInfo) => {
      dispatch({
        type: WsSubEvent.SHARES_BY_MARKET_UPDATE,
        payload: {
          value: {
            ...payload,
            token1: {
              id: payload.token1.id,
              balance: formatUnits(
                payload.token1.balance as unknown as bigint,
                6
              ),
            },
            token2: {
              id: payload.token2.id,
              balance: formatUnits(
                payload.token2.balance as unknown as bigint,
                6
              ),
            },
          },
        },
      });
    }
  );

  socket.on(
    WsSubEvent.COLLATERAL_BALANCE,
    (payload: ICollateralBalanceEvent) => {
      dispatch({
        type: WsSubEvent.COLLATERAL_BALANCE,
        payload: { value: formatUnits(payload.value as unknown as bigint, 6) },
      });
    }
  );
};

interface IReducerEvent {
  type: WsSubEvent;
  payload: any;
}

const initialState = {
  [WsSubEvent.COLLATERAL_BALANCE]: {
    value: 0,
    loading: true,
  },
  [WsSubEvent.ORDERBOOK_ORDER_CREATE]: {
    value: undefined,
  },
  [WsSubEvent.ORDERBOOK_ORDER_DELETE]: {
    value: undefined,
  },
  [WsSubEvent.ORDERBOOK_ORDER_UPDATE]: {
    value: undefined,
  },
  [WsSubEvent.SHARES_BY_MARKET_UPDATE]: {
    '': {
      value: {
        marketId: '',
        token1: {
          id: '',
          balance: '0',
        },
        token2: {
          id: '',
          balance: '0',
        },
      },
    },
  },
};

// Process all incomming ws data.
// Note: Internal state changes in context component will cause
// rerendering in components consuming useWebsocket
// whenever any of the state properties changes.
// WS data should be moved into dedicated state management library if it gets frequent updates.
const reducer = (state: any, action: any) => {
  switch (action.type) {
    case WsSubEvent.COLLATERAL_BALANCE: {
      const { value } = action.payload;
      return {
        ...state,
        [WsSubEvent.COLLATERAL_BALANCE]: {
          value,
          loading: false,
        },
      };
    }
    case WsSubEvent.SHARES_BY_MARKET_UPDATE: {
      const marketId = action?.payload?.value?.marketId;
      if (!marketId) {
        return state;
      }

      const value = {
        [marketId]: action.payload,
      };
      return {
        ...state,
        [WsSubEvent.SHARES_BY_MARKET_UPDATE]: {
          ...state[WsSubEvent.SHARES_BY_MARKET_UPDATE],
          ...value,
        },
      };
    }
    case WsSubEvent.ORDERBOOK_ORDER_CREATE: {
      const { value } = action.payload;
      value.amount_left = Decimal.sub(value.amount, value.matchedAmount).toNumber()
      return {
        ...state,
        [WsSubEvent.ORDERBOOK_ORDER_CREATE]: {
          value,
        },
      };
    }
    case WsSubEvent.ORDERBOOK_ORDER_DELETE: {
      const { value } = action.payload;
      return {
        ...state,
        [WsSubEvent.ORDERBOOK_ORDER_DELETE]: {
          value,
        },
      };
    }
    case WsSubEvent.ORDERBOOK_ORDER_UPDATE: {
      const { value } = action.payload;
      value.amount_left = Decimal.sub(value.amount, value.matchedAmount).toNumber()
      return {
        ...state,
        [WsSubEvent.ORDERBOOK_ORDER_UPDATE]: {
          value,
        },
      };
    }
    default:
      return { ...state };
  }
};

interface WebsocketData {
  socket: Socket | null;
  emit: (eventName: WsPubEvent, payload: any) => Socket | null;
  resetBalance: () => void;
  collateralBalance: ICollateralBalanceEvent;
  sharesByMarketUpdate: IMarketSharesInfoValue;
}

const WebSocketContext = createContext<WebsocketData>({
  socket: null,
  emit: () => null,
  resetBalance: () => null,
  collateralBalance: initialState[WsSubEvent.COLLATERAL_BALANCE],
  sharesByMarketUpdate: initialState[WsSubEvent.SHARES_BY_MARKET_UPDATE],
});

export const WebSocketProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { proxyWalletAddress } = useBlockchain();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [state, dispatch] = useReducer(reducer, initialState);

  const emit = useCallback(
    (eventName: WsPubEvent, payload: any): Socket | null => {
      if (!socket) return null;
      return socket.emit(eventName, payload);
    },
    [socket]
  );

  const resetBalance = () => {
    dispatch({
      type: WsSubEvent.SHARES_BY_MARKET_UPDATE,
      payload: initialState[WsSubEvent.SHARES_BY_MARKET_UPDATE],
    });
    dispatch({
      type: WsSubEvent.COLLATERAL_BALANCE,
      payload: initialState[WsSubEvent.COLLATERAL_BALANCE],
    });
  };

  useEffect(() => {
    if (socket && socket.connected) {
      return;
    }
    if (!proxyWalletAddress) return;
    const _socket = io(`${process.env.REACT_APP_API_HOST}`);

    _socket.on('connect', () => {
      console.log('WS client connected');
    });
    _socket.on('disconnect', () => {
      setSocket(null);
      console.log('WS client disconnected');
    });
    _socket.on('connect_error', (err) => {
      console.error('WS connection error:', err);
      _socket.disconnect();
      setSocket(null);
    });

    registerAppHandlers(_socket, dispatch);

    setSocket(_socket);

    dispatch({
      type: WsSubEvent.COLLATERAL_BALANCE_LOADING,
    });

    // ask for initial data, once a connection is established
    _socket.emit(WsPubEvent.INITIAL_DATA);

    return () => {
      _socket.disconnect();
      setSocket(null);
    };
  }, [proxyWalletAddress]);

  return (
    <WebSocketContext.Provider
      value={{
        socket,
        emit,
        resetBalance,
        collateralBalance: state[WsSubEvent.COLLATERAL_BALANCE],
        sharesByMarketUpdate: state[WsSubEvent.SHARES_BY_MARKET_UPDATE],
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebsocket = () => {
  const ws = useContext(WebSocketContext);
  if (ws === undefined) {
    throw new Error('useWebsocket must be used within a WebsocketProvider');
  }
  return ws;
};
