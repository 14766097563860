import React, { useEffect, useRef } from 'react';
import './radio-component.css';

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onFilterChange: (
    checked: boolean,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void;
  label?: string;
  isCleared?: boolean;
}

const Radio: React.FC<RadioProps> = ({
  label,
  onFilterChange,
  isCleared = false,
  ...rest
}) => {
  useEffect(() => {
    if (radioRef.current && isCleared === true) {
      radioRef.current.checked = rest.defaultChecked ?? false;
    }
  }, [isCleared]);

  const radioRef = useRef<HTMLInputElement>(null);
  return (
    <label htmlFor={rest.id} className="custom-radio">
      <input
        {...rest}
        key={rest.id}
        ref={radioRef}
        type="radio"
        onChange={(e) => {
          console.log('ON chagne', rest.id);
          onFilterChange(radioRef.current?.checked ?? false, e);
        }}
      />
      <span
        className={`radio-mark ${radioRef.current?.checked ? 'checked' : ''} bg-transparent border-2 rounded-full ${radioRef.current?.checked ? 'border-water-blue' : 'border-grey-secondary-darker'}`}
      ></span>
      {label && <span className="radio-label">{label}</span>}
    </label>
  );
};

export default Radio;
