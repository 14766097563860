import { OrderBookGraph } from '../../components/order-book-graph/order-book-graph-component';
import { Positions } from './Positions';
import { History } from './History';
import { Market, Order } from '../../types/types';
import { OpenOrders } from './OpenOrders';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MarketResolution } from '../../components/market-resolution/market-resolution-component';
import { useTranslation } from 'react-i18next';
import { usePositionsData } from '../../hooks/markets-hook';
import restService from '../../helpers/rest-service-helper';
import { useBlockchain } from '../../context/blockchain-context';

export const SingleOutcome = ({
  market,
  selectedOutcome,
  setSelectedOutcome,
}: {
  market: Market;
  selectedOutcome?: string;
  setSelectedOutcome?: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const { id } = market;
  const { t } = useTranslation();
  const [positionsData, setPositionsData] = useState<any[]>([]);
  const [openOrdersData, setOpenOrdersData] = useState<Order[]>([]);
  const { userId } = useBlockchain();

  useEffect(() => {
    const fetchData = async () => {
      const positionsData = await usePositionsData(
        1,
        10,
        '',
        '',
        'latest',
        'DESC'
      );
      setPositionsData(positionsData.data);
      const data = await restService.get<Order[]>(
        `/orders?market=${id}&owner=${userId}&status=PENDING`
      );
      setOpenOrdersData(data);
    };
    if (market.id && userId) {
      fetchData();
    }
  }, [market?.id, userId]);

  return (
    <>
      <div>
        <div className="font-bold my-4">
          <p className="text-sm text-white">{t`Chance`}:</p>
          <p className="text-water-blue text-5xl py-2">
            <MarketResolution
              resolution={
                market[
                  selectedOutcome === market.outcome1
                    ? 'outcome1_chance'
                    : 'outcome2_chance'
                ] ?? '0'
              }
            />{' '}
          </p>
        </div>
      </div>
      {id && (
        <Positions
          variant="singleOutcome"
          market={market}
          positionsData={positionsData.filter(
            (price) => price.marketId === market.id
          )}
        />
      )}
      {!market.isResolved && setSelectedOutcome && (
        <>
          <OrderBookGraph
            setSelectedOutcome={setSelectedOutcome}
            selectedOutcome={selectedOutcome}
            variant={'singleOutcome'}
            market={market}
          />
          <OpenOrders orders={openOrdersData} market={market} />
        </>
      )}
      <History marketId={id} />
    </>
  );
};
