import { useTranslation } from 'react-i18next';
import { Button } from '../../components/button/button-component';
import { FormatCurrency } from '../../helpers/currency-helper';
// import { ReactComponent as Share } from '../../assets/icons/share.svg';
import { Market } from '../../types/types';
import { useWebsocket } from '../../context/websocket-context';
import {
  calculateBet,
  calculateValue,
  calculateTotalReturn,
  calculateTotalReturnPercentage,
  formatTotalReturn,
} from '../../helpers/calculate-helper';

export const Positions = ({
  market,
  positionsData,
}: {
  variant: 'multiOutcome' | 'singleOutcome';
  market: Market;
  positionsData?: any;
}) => {
  const { t } = useTranslation();
  const { sharesByMarketUpdate } = useWebsocket();

  const positionsMap = positionsData.reduce((acc: any, position: any) => {
    acc[position.tokenId] = {
      acquisitionPrice: position.acquisitionPrice || 0,
      latest: position.latest || 0,
    };
    return acc;
  }, {});

  // Function to calculate all details for a token
  const getTokenDetails = (tokenId: string) => {
    const balance =
      +sharesByMarketUpdate[market.id]?.value[tokenId]?.balance || 0;
    const currentMarketPrice = parseFloat(
      positionsMap[sharesByMarketUpdate[market.id]?.value[tokenId]?.id]
        ?.latest || 0
    );
    const acquisitionPrice = parseFloat(
      positionsMap[sharesByMarketUpdate[market.id]?.value[tokenId]?.id]
        ?.acquisitionPrice || 0
    );

    const value = calculateValue(balance, currentMarketPrice);
    const bet = calculateBet(balance, acquisitionPrice);
    const totalReturn = calculateTotalReturn(value, bet);
    const totalReturnPercentage = calculateTotalReturnPercentage(value, bet);
    const formattedTotalReturn = formatTotalReturn(
      totalReturn,
      totalReturnPercentage
    );

    return {
      balance,
      currentMarketPrice,
      acquisitionPrice,
      value,
      bet,
      formattedTotalReturn,
    };
  };

  return (
    <>
      {sharesByMarketUpdate[market.id]?.value?.marketId &&
      Math.max(
        getTokenDetails('token1').balance,
        getTokenDetails('token2').balance
      ) > 0 ? (
        <div className="bg-black-dark rounded-2xl p-4 overflow-auto">
          <p className="font-bold text-white text-xl border-b border-grey-secondary pb-2">{t`Positions`}</p>
          <div className="flex flex-col min-w-[400px]">
            <div className="grid grid-cols-5 text-grey-secondary-darker text-sm pt-6 pb-2  border-b border-grey-secondary font-bold">
              <p className="justify-self-center">{t`Outcome`}</p>
              <p className="justify-self-center">{t`Shares`}</p>
              <p className="justify-self-center">{t`Avg.`}</p>
              <p className="justify-self-center">{t`Value`}</p>
              <p className="justify-self-center">{t`Total Return`}</p>
              {/* <p className="justify-self-center">{t`Actions`}</p> */}
            </div>
            {['token1', 'token2'].map((tokenId) => {
              const details = getTokenDetails(tokenId);
              if (details.balance > 0) {
                const { formattedValue, formattedPercentage, isPositive } =
                  details.formattedTotalReturn;

                return (
                  <div
                    key={tokenId}
                    className="grid grid-cols-5 text-grey-secondary-darker text-sm text-white my-3 items-center"
                  >
                    <p
                      className={`rounded-md py-1 px-2 font-bold max-w-[130px] text-center ${
                        tokenId === 'token1'
                          ? 'bg-green-transparent'
                          : 'bg-red-transparent'
                      }`}
                    >
                      {tokenId === 'token1' ? market.outcome1 : market.outcome2}
                    </p>
                    <p className="justify-self-center">
                      {details.balance.toFixed(2)}
                    </p>
                    <p className="justify-self-center">
                      {Math.floor(
                        parseFloat(details.acquisitionPrice.toFixed(2)) * 100
                      )}
                      ¢
                    </p>
                    <p className="justify-self-center">
                      {FormatCurrency(details.value, false, 2)}
                    </p>
                    <p className="justify-self-center">
                      <span className="flex items-center gap-1">
                        {formattedValue}
                        <span
                          className={
                            isPositive ? 'text-green-light' : 'text-red-normal'
                          }
                        >
                          ({formattedPercentage})
                        </span>
                      </span>
                    </p>
                    {/* <div className="flex gap-3 justify-self-center">
                      <Button variant="blue" size="md">
                        {t`Sell`}
                      </Button>
                    </div> */}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
