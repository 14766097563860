import { useEffect, useState } from 'react';
import { useMarketActivity } from '../../hooks/markets-hook';
import { MarketActivity } from '../../types/types';
import { useTranslation } from 'react-i18next';
import { formatUnits } from 'viem';
import { getElapsedTime } from '../../helpers/date-helper';
import { FormatCurrency } from '../../helpers/currency-helper';
import { Button } from '../../components/button/button-component';
import { useBlockchain } from '../../context/blockchain-context';
import { isBuyOrSell, isSplitOrMergeOrRedeem } from '../../helpers/checkers';

export const History = ({ marketId }: { marketId?: string }) => {
  const [history, setHistory] = useState<MarketActivity[]>([]);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const { proxyWalletAddress, proxyWalletSetup } = useBlockchain();

  useEffect(() => {
    const fetchHistory = async () => {
      const { data, last, total } = await useMarketActivity(
        marketId as string,
        page,
        history,
        proxyWalletAddress?.toLowerCase() as string
      );
      setHasMore(last !== total);
      setHistory(data);
    };
    if (marketId && proxyWalletSetup) {
      fetchHistory();
    }
  }, [marketId, page, proxyWalletSetup, proxyWalletAddress]);
  return (
    <>
      {history.length > 0 ? (
        <div className="text-white w-[100%] flex flex-col border border-grey-secondary gap-6 p-4 rounded-lg">
          <p className="text-xl font-bold border-b border-grey-secondary pb-2">{t`History`}</p>
          <div className="flex flex-col gap-3 text-sm">
            {history.map((item, key) => (
              <div key={`history-${key}`} className="flex justify-between">
                <p className="font-bold text-white">
                  {item.type === 'SPLIT' ? t`Split Order` : ''}
                  {item.type === 'MERGE' ? t`Merge Order` : ''}
                  {item.type === 'BUY' ? t`Bought` : ''}
                  {item.type === 'SELL' ? t`Sold` : ''}
                  {item.type === 'REDEEM' ? t`Redeem Positions` : ''}{' '}
                  {isBuyOrSell(item.type) && item.partition[0] === 1 && (
                    <span className="text-green-light">
                      {' '}
                      {parseFloat(
                        formatUnits(item.amount as unknown as bigint, 6)
                      ).toFixed(2) +
                        ' ' +
                        item.outcome1}{' '}
                    </span>
                  )}
                  {isBuyOrSell(item.type) && item.partition[0] === 2 && (
                    <span className="text-red-normal">
                      {' '}
                      {parseFloat(
                        formatUnits(item.amount as unknown as bigint, 6)
                      ).toFixed(2) +
                        ' ' +
                        item.outcome2}{' '}
                    </span>
                  )}{' '}
                  <span>
                    {isBuyOrSell(item.type)
                      ? t`at` +
                        ' ' +
                        Math.floor(parseFloat(item.price) * 100) +
                        t`¢`
                      : ''}
                  </span>{' '}
                  <span className="text-text-secondary">
                    (
                    <>
                      {isSplitOrMergeOrRedeem(item.type) &&
                        FormatCurrency(
                          parseFloat(
                            formatUnits(item.amount as unknown as bigint, 6)
                          ),
                          false,
                          2
                        )}
                      {isBuyOrSell(item.type) &&
                        FormatCurrency(
                          parseFloat(
                            formatUnits(item.amount as unknown as bigint, 6)
                          ) * parseFloat(item.price),
                          false,
                          2
                        )}
                    </>
                    )
                  </span>
                </p>
                <p className="text-text-secondary">
                  {getElapsedTime(
                    new Date(+item.timestamp * 1000).toISOString()
                  )}
                </p>
              </div>
            ))}
            {hasMore && (
              <div className="grid justify-center">
                <Button
                  onClick={() => setPage(page + 1)}
                >{t`Load more`}</Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="text-white w-[100%] flex flex-col border border-grey-secondary gap-6 p-4 rounded-lg">
            <div className="grid gap-2">
              <p className="text-white text-base font-bold">{t`History is empty.`}</p>
              <p className="text-text-secondary text-sm">{t`This section shows the current market activity.`}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};
