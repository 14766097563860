import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ExpandableComponent } from '../../components/expandable/expandable-component';
import { Button } from '../../components/button/button-component';
import { ReactComponent as Share } from '../../assets/icons/share.svg';
import CustomTable from '../../components/custom-table/custom-table-component';
import { PortfolioTableHeader } from './PortfolioTableHeader';
import { formatUnits } from 'viem';
import { NavLink } from 'react-router-dom';
import { FormatCurrency } from '../../helpers/currency-helper';
import { usePositionsData } from '../../hooks/markets-hook';
import {
  calculateBet,
  calculateTotalReturnPercentage,
  calculateValue,
} from '../../helpers/calculate-helper';

import { PaginatedResponse, Position } from '../../types/types';
import { useBlockchain } from '../../context/blockchain-context';

export const Positions = () => {
  const [tableKey, setTableKey] = useState('initialKey');
  const [sortBy, setSortBy] = useState('latest');
  const [order, setOrder] = useState<'DESC' | 'ASC'>('DESC');
  const { t } = useTranslation();
  const [term, setTerm] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [positions, setPositions] = useState<PaginatedResponse<Position>>();
  const [page, setPage] = useState(1);
  const { userId } = useBlockchain();
  const { proxyWalletSetup } = useBlockchain();

  const fetchPositionsData = async (
    currentPage: number,
    rowsPerPage: number
    // sortBy: string,
    // order: 'ASC' | 'DESC'
  ) => {
    const positionsData = await usePositionsData(
      currentPage,
      rowsPerPage,
      term,
      status,
      sortBy,
      order
    );

    return positionsData;
  };

  useEffect(() => {
    const fetchAndUpdatePositions = async () => {
      try {
        const fetchedPositions = await fetchPositionsData(page, 10);
        const isFirstPage = page === 1;

        const updatedData = isFirstPage
          ? fetchedPositions.data
          : [...(positions?.data ?? []), ...fetchedPositions.data];

        setPositions({
          ...fetchedPositions,
          data: updatedData,
        });
      } catch (error) {
        console.log('Error fetching positions data:', error);
      }
    };

    fetchAndUpdatePositions();
  }, [page, status, term, sortBy, order]);

  return (
    <div className="flex flex-col w-[100%] gap-4 mt-4">
      {positions?.data && (
        <>
          <PortfolioTableHeader
            onChange={(value) => {
              setSortBy(value.split('-')[0]);
              setOrder(
                (value.split('-')?.[1]?.toUpperCase() ?? 'DESC') as
                  | 'ASC'
                  | 'DESC'
              );
              setTableKey(`user-activity-${value}`);
            }}
            onSearch={(term) => setTerm(term)}
            statusOptions={[
              {
                value: 'latest-desc',
                label: t`Latest`,
              },
              {
                value: 'initial-desc',
                label: t`Initial Bet`,
              },
              {
                value: 'current-desc',
                label: t`Current Value`,
              },
              {
                value: 'return-desc',
                label: t`Return`,
              },
            ]}
          />
          <div className="hidden md:block">
            {userId && (
              <CustomTable
                tableKey={tableKey}
                setTableKey={setTableKey}
                rowClasses="h-[81px]"
                emptyState={
                  <tbody>
                    <tr>
                      <td className="py-6 text-center" colSpan={8}>
                        <div className="grid gap-2">
                          <p className="text-white text-base font-bold">{t`You don’t have any Positions currently.`}</p>
                          <p className="text-text-secondary text-sm">{t`Browse and search for markets, you can bet on.`}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                }
                columns={[
                  {
                    id: 'market',
                    label: t('Market'),
                    render: (item) => {
                      return (
                        <NavLink
                          className="flex gap-4 items-center"
                          to={`/market/${item?.eventSlug}/${!item?.singleMarket ? item?.marketSlug : ''}`}
                        >
                          <div className="max-w-10  h-10">
                            <img
                              className="rounded-lg object-cover w-[100%] h-[100%]"
                              src={item?.image}
                            />
                          </div>
                          <div>
                            <p className="font-bold">{item?.marketName}</p>
                            <div className="flex items-center my-1 gap-2">
                              <p
                                className={`font-bold inline-block ${item?.bgColor} ${item?.textColor} py-1 px-2 rounded-md`}
                              >
                                {item?.outcome} -{' '}
                                {Math.floor(
                                  parseFloat(item?.acquisitionPrice) * 100
                                )}
                                ¢
                              </p>
                              <p className="text-text-secondary">
                                {' '}
                                {parseFloat(
                                  formatUnits(item?.amount, 6)
                                ).toFixed(2)}{' '}
                                {t`shares`}
                              </p>
                            </div>
                          </div>
                        </NavLink>
                      );
                    },
                  },
                  {
                    id: 'latest',
                    label: t('Latest'),
                    render: (item) =>
                      item?.latest
                        ? `${Math.floor(
                            parseFloat(item.latest.toFixed(2)) * 100
                          )}¢`
                        : '0¢',
                  },
                  {
                    id: 'bet',
                    label: t('Bet'),
                    type: 'currency',
                    currency: {
                      isAmount: false,
                    },
                    render: (item) =>
                      `$${(parseFloat(formatUnits(item?.amount, 6)) * item?.acquisitionPrice).toFixed(2)}`,
                  },
                  {
                    id: 'current',
                    label: t('Current'),
                    type: 'currency',
                    currency: {
                      isAmount: false,
                    },
                    render: (item) => {
                      const shares = parseFloat(formatUnits(item?.amount, 6));
                      const currentMarketPrice = parseFloat(
                        item?.latest || '0'
                      );
                      const value = calculateValue(shares, currentMarketPrice);
                      const bet = calculateBet(
                        shares,
                        parseFloat(item?.acquisitionPrice)
                      );
                      const totalReturnPercentage =
                        calculateTotalReturnPercentage(value, bet);

                      return (
                        <div className="flex items-center gap-2">
                          <p>${value.toFixed(2)}</p>
                          <span
                            className={
                              totalReturnPercentage >= 0
                                ? 'text-green-light'
                                : 'text-red-normal'
                            }
                          >
                            ({totalReturnPercentage >= 0 && '+'}
                            {totalReturnPercentage.toFixed(2)}
                            %)
                          </span>
                        </div>
                      );
                    },
                  },
                  {
                    id: 'toWin',
                    label: t('To Win'),
                    type: 'currency',
                    render: (item) =>
                      FormatCurrency(
                        parseFloat(formatUnits(item?.amount, 6)),
                        false,
                        2
                      ),
                  },
                  {
                    id: 'action',
                    label: '',
                    render: (item) => (
                      <div className="flex items-center justify-center gap-2">
                        <NavLink
                          className="flex gap-4 items-center"
                          to={`/market/${item?.eventSlug}/${!item?.singleMarket ? item?.marketSlug : ''}`}
                        >
                          <Button variant="darkLighter">
                            {item?.isResolved ? t`Claim` : t`Trade`}
                          </Button>
                        </NavLink>

                        <Share className="cursor-pointer" />
                      </div>
                    ),
                  },
                ]}
                hook={async (page, pageSize) =>
                  usePositionsData(
                    page,
                    pageSize,
                    term,
                    status,
                    sortBy,
                    order,
                    proxyWalletSetup
                  )
                }
              />
            )}
          </div>
          {userId && (
            <>
              {positions.data.length === 0 ? (
                <div className="py-6 text-center block md:hidden">
                  <div className="grid gap-2">
                    <p className="text-white text-base font-bold">{t`You don’t have any Positions currently.`}</p>
                    <p className="text-text-secondary text-sm">{t`Browse and search for markets, you can bet on.`}</p>
                  </div>
                </div>
              ) : (
                <div
                  id="scrollableContainer"
                  style={{
                    overflowY: 'auto',
                  }}
                >
                  <InfiniteScroll
                    scrollableTarget="scrollableContainer"
                    dataLength={(positions?.data ?? []).length}
                    next={() => setPage(page + 1)}
                    hasMore={positions?.last !== positions?.total}
                    loader={<></>}
                    className="bg-black-dark md:hidden flex flex-col gap-2 rounded-2xl p-4"
                  >
                    {positions &&
                      positions.data.length > 0 &&
                      positions.data.map((item: any, index: number) => {
                        const shares = parseFloat(formatUnits(item?.amount, 6));
                        const currentMarketPrice = parseFloat(
                          item?.latest || '0'
                        );
                        const value = calculateValue(
                          shares,
                          currentMarketPrice
                        );
                        const bet = calculateBet(
                          shares,
                          parseFloat(item?.acquisitionPrice)
                        );
                        const totalReturnPercentage =
                          calculateTotalReturnPercentage(value, bet);

                        return (
                          <ExpandableComponent
                            key={`position-${index}`}
                            title={item?.marketName}
                            logo={item?.image}
                            items={
                              <div className="flex justify-around pb-4 px-4 items-center text-text-secondary">
                                <div className="flex flex-col m-4">
                                  <span
                                    className={`rounded-md p-2 ${item.textColor} ${item.bgColor}`}
                                  >
                                    {item?.outcome || 'Outcome'} -{' '}
                                    {Math.floor(
                                      parseFloat(
                                        item?.acquisitionPrice || '0'
                                      ) * 100
                                    )}
                                    ¢
                                  </span>{' '}
                                  <span>
                                    {shares.toFixed(2)} {t('shares')}
                                  </span>
                                </div>
                                •
                                <span>
                                  ${value.toFixed(2)}{' '}
                                  <span
                                    className={
                                      totalReturnPercentage >= 0
                                        ? 'text-green-light'
                                        : 'text-red-normal'
                                    }
                                  >
                                    ({totalReturnPercentage >= 0 && '+'}
                                    {totalReturnPercentage.toFixed(2)}%)
                                  </span>
                                </span>
                              </div>
                            }
                            expandedItems={
                              <>
                                <div className="flex justify-between items-center">
                                  <p className="text-text-secondary">
                                    {t('Avg.')}
                                  </p>
                                  <p>
                                    {Math.floor(
                                      parseFloat(item?.acquisitionPrice) * 100
                                    )}
                                    ¢
                                  </p>
                                </div>
                                <div className="flex justify-between items-center">
                                  <p className="text-text-secondary">
                                    {t('Current price')}
                                  </p>
                                  <p>
                                    {Math.floor(
                                      parseFloat(
                                        currentMarketPrice.toString()
                                      ) * 100
                                    )}
                                    ¢
                                  </p>
                                </div>
                                <div className="flex justify-between items-center">
                                  <p className="text-text-secondary">
                                    {t('Potential Return')}
                                  </p>
                                  <p>
                                    {FormatCurrency(
                                      parseFloat(formatUnits(item?.amount, 6)),
                                      false,
                                      2
                                    )}
                                  </p>
                                </div>
                                <NavLink
                                  to={`/market/${item?.eventSlug}/${!item?.singleMarket ? item?.marketSlug : ''}`}
                                >
                                  <Button variant="blue" className="w-full">
                                    {item?.isResolved ? t`Claim` : t`Trade`}
                                  </Button>
                                </NavLink>
                              </>
                            }
                          />
                        );
                      })}
                  </InfiniteScroll>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
