import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import { ExpandedBet } from '../../components/market-card/expanded-bet';

export const MobileOrderPage = () => {
  const location = useLocation();

  const market = location?.state ?? {
    market: undefined,
  };
  const { selectedOutcome } = useParams();
  const [selectedOutcomeState, setSelectedOutcome] = useState<
    string | undefined
  >(selectedOutcome);
  return (
    <>
      {selectedOutcomeState && (
        <ExpandedBet
          setSelectedOutcome={setSelectedOutcome}
          selectedOutcome={selectedOutcomeState}
          conditionId={market.conditionId}
          isMultiMarket={false}
          market={market}
        />
      )}
    </>
  );
};
