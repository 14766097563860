import { Button } from '../../components/button/button-component';
import TradingMonitor from '../../assets/trading-monitor.png';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../context/modal-context';
import {
  checkForTxSuccess,
  createProxyWallet,
  waitForTransactionCompletion,
} from '../../helpers/blockchain-helper';
import { useBlockchain } from '../../context/blockchain-context';
import { getErrorMessage } from '../../helpers/error-helper';
import { useRef, useState } from 'react';
import { useContracts } from '../../context/contracts-context';
import { TransactionReceipt } from 'viem';
import { getTxDetailsUrl } from '../../helpers/link-helper';

export const EnableTradingModal = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { setProxyWalletAddress } = useBlockchain();
  const [isLoading, setIsLoading] = useState(false);
  const contracts = useContracts();
  const proxyWalletClicked = useRef(false);
  const SAFE_PROXY_FACTORY = contracts.data.find(
    ({ name }) => name === 'SAFE_PROXY_FACTORY'
  )?.address as string;
  const handleErrorModal = (e: string, txHash: string) => {
    openModal({
      content: (
        <>
          <p className="text-center">{getErrorMessage(e)}</p>
          {getTxDetailsUrl(txHash)}
        </>
      ),
      title: 'Error',
      type: 'error',
    });
    setIsLoading(false);
  };

  const enableTrading = async () => {
    if (proxyWalletClicked.current) return;
    proxyWalletClicked.current = true;
    let txHash: `0x${string}` = '0x';
    try {
      setIsLoading(true);
      const proxyAddressData = await createProxyWallet(
        t,
        contracts.data[0].chain_id,
        SAFE_PROXY_FACTORY
      );

      if (!proxyAddressData) {
        throw new Error('Failed to create proxy wallet');
      }

      const { proxyAddress, txHash: txHashProxyData } = proxyAddressData;
      txHash = txHashProxyData;
      setProxyWalletAddress(proxyAddress);

      const receipt: TransactionReceipt = await waitForTransactionCompletion(
        txHash,
        1
      );
      if (checkForTxSuccess(receipt, txHash)) {
        openModal({
          content: (
            <>
              <p className="text-center">
                {t`Your Proxy wallet has been created successfully`}
              </p>
              {getTxDetailsUrl(txHash)}
            </>
          ),
          title: t`Success`,
          type: 'success',
        });
      }
    } catch (e) {
      handleErrorModal(
        typeof e === 'string' ? e : (e as Error)?.message,
        txHash
      );
    } finally {
      setIsLoading(false);
      proxyWalletClicked.current = false;
    }
  };

  return (
    <div className="flex flex-col text-sm gap-6 justify-center items-center">
      <div className="max-w-[215px]">
        <img
          className="object-cover w-[100%] h-[100%]"
          src={TradingMonitor}
          alt=""
        />
      </div>

      <div className="bg-surface-modal p-4 rounded-2xl text-white text-sm flex flex-col gap-2">
        <p>{t`To ensure the best user experience and security measures, it's essential to set up a proxy wallet when utilising the MetaMarket platform.`}</p>
        <p>{t`You can conveniently deposit to and withdraw from your MetaMarket proxy wallet anytime to any other wallet.`}</p>
      </div>

      <Button onClick={enableTrading} className="w-[100%]" variant="blue">
        {isLoading ? (
          <div
            className="spinner m-auto"
            style={{
              width: 24,
              height: 24,
              border: '4px solid #fff',
              borderColor: '#fff transparent #fff transparent',
            }}
          ></div>
        ) : (
          t`Continue`
        )}
      </Button>
    </div>
  );
};
