import { objectToQueryParams } from '../helpers/object-to-params-helper';
import restService from '../helpers/rest-service-helper';
import { Category, PaginatedResponse, CategoriesParams } from '../types/types';

export const useCategories = async (
  categoriesParams: CategoriesParams
): Promise<Category[]> => {
  const categories = await restService.get<PaginatedResponse<Category>>(
    `/categories?${objectToQueryParams(categoriesParams)}`
  );
  return (categories.data ?? [])
    .filter(({ active }) => active === true)
    .sort((a, b) => (a.position ?? Infinity) - (b.position ?? Infinity));
};
