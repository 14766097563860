import { Button } from '../../components/button/button-component';
import TradingMonitor from '../../assets/trading-monitor.png';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../context/modal-context';
import {
  checkForTxSuccess,
  setupProxyWallet,
  waitForTransactionCompletion,
} from '../../helpers/blockchain-helper';
import { useState, useEffect } from 'react';
import { useReadContract } from 'wagmi';
import { SAFE_PROXY_ABI } from '../../constants/contracts';
import { useBlockchain } from '../../context/blockchain-context';
import { getErrorMessage } from '../../helpers/error-helper';
import { useContracts } from '../../context/contracts-context';
import { TransactionReceipt } from 'viem';
import { getTxDetailsUrl } from '../../helpers/link-helper';

export const ApproveTokensModal = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [nonce, setNonce] = useState<string>();
  const { setProxyWalletSetup, proxyWalletAddress, setApproveTokensHeading } =
    useBlockchain();
  const { data, error } = useReadContract({
    address: proxyWalletAddress as `0x${string}`,
    abi: SAFE_PROXY_ABI,
    functionName: 'nonce',
  });
  const contracts = useContracts();
  const USDCe_ADDRESS = contracts.data.find(({ name }) => name === 'COLLATERAL')
    ?.address as string;
  const UMA_CTF_ADAPTER = contracts.data.find(
    ({ name }) => name === 'UMA_CTF_ADAPTER'
  )?.address as string;
  const MULTISEND_ADDRESS = contracts.data.find(
    ({ name }) => name === 'MULTISEND'
  )?.address as string;
  const CTF_EXCHANGE_ADDRESS = contracts.data.find(
    ({ name }) => name === 'CTF_EXCHANGE'
  )?.address as string;
  const CTF_ADDRESS = contracts.data.find(({ name }) => name === 'CTF')
    ?.address as string;
  const NEG_RISK_ADAPTER = contracts.data.find(
    ({ name }) => name === 'NEG_RISK_ADAPTER'
  )?.address as string;
  const NEG_RISK_CTF_EXCHANGE = contracts.data.find(
    ({ name }) => name === 'NEG_RISK_CTF_EXCHANGE'
  )?.address as `0x${string}`;
  useEffect(() => {
    if (data !== undefined) {
      setNonce((data as string).toString());
    } else if (error) {
      console.log('error', error, data);
      setNonce('0');
    }
  }, [data, error]);
  const { openModal } = useModal();

  const setupWallet = async () => {
    if (!nonce) return;
    let txHash = '';
    try {
      setIsLoading(true);
      txHash = await setupProxyWallet(
        proxyWalletAddress as string,
        nonce,
        contracts.data[0].chain_id,
        UMA_CTF_ADAPTER,
        USDCe_ADDRESS,
        MULTISEND_ADDRESS,
        CTF_EXCHANGE_ADDRESS,
        CTF_ADDRESS,
        NEG_RISK_CTF_EXCHANGE,
        NEG_RISK_ADAPTER
      );

      const receipt: TransactionReceipt = await waitForTransactionCompletion(
        txHash as `0x${string}`,
        1
      );
      if (checkForTxSuccess(receipt, txHash)) {
        openModal({
          content: (
            <>
              <p className="text-center">{t`You are now ready to start trading securely with your USDC`}</p>
              {getTxDetailsUrl(txHash)}
            </>
          ),
          title: t`Success`,
          type: 'success',
        });
      }
    } catch (e) {
      openModal({
        content: (
          <>
            <p className="text-center">
              {getErrorMessage((e as Error)?.message)}
            </p>
            {getTxDetailsUrl(txHash)}
          </>
        ),
        title: 'Error',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
      setProxyWalletSetup(true);
      setApproveTokensHeading(false);
    }
  };

  return (
    <div className="flex flex-col text-sm gap-6 justify-center items-center">
      <div className="max-w-[215px]">
        <img
          className="object-cover w-[100%] h-[100%]"
          src={TradingMonitor}
          alt=""
        />
      </div>

      <div className="bg-surface-modal p-4 rounded-2xl text-white text-sm flex flex-col gap-2">
        <p>{t`Start trading securely with your USDC on MetaMarket`}</p>
      </div>

      <Button onClick={setupWallet} className="w-[100%]" variant="blue">
        {isLoading ? (
          <div
            className="spinner m-auto"
            style={{
              width: 24,
              height: 24,
              border: '4px solid #fff',
              borderColor: '#fff transparent #fff transparent',
            }}
          ></div>
        ) : (
          t`Sign`
        )}
      </Button>
    </div>
  );
};
