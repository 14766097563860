import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './calendar-component.module.css';

interface CustomDatePickerProps {
  selectedDate: Date | null;
  onChange: (date: Date) => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  selectedDate,
  onChange,
}) => {
  const [tempDate, setTempDate] = useState<Date | null>(selectedDate);
  const isSameDay = (date1: Date, date2: Date) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const renderDayContents = (day: number, date: Date) => {
    const isSelected = tempDate && isSameDay(date, tempDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const isPastDate = date < today;

    const displayContent = isPastDate ? 'x' : day;

    return (
      <div
        className={`${styles.days} ${isPastDate ? styles.pastDay : ''} ${
          isSelected ? styles.selectedDay : ''
        } flex items-center justify-center w-10 h-10 rounded-md`}
      >
        {displayContent}
      </div>
    );
  };

  return (
    <div className={`p-2 w-80 mx-auto ${styles.calendarContainer}`}>
      <DatePicker
        selected={tempDate}
        onChange={(date) => {
          if (date) {
            setTempDate(date);
            onChange(date);
          }
        }}
        inline
        calendarClassName={styles.customDatepicker}
        renderDayContents={renderDayContents}
        minDate={new Date()}
        renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
          <div
            className={`flex items-center justify-between mb-2 px-2 bg-grey-light ${styles.headerNavigation}`}
          >
            <span className="text-white font-medium">
              {date.toLocaleDateString('en-US', {
                month: 'long',
                year: 'numeric',
              })}
            </span>
            <div className="flex items-center gap-6">
              <button
                type="button"
                onClick={decreaseMonth}
                style={{ color: 'white', backgroundColor: 'transparent' }}
                className="hover:bg-gray-700 rounded-full p-2 transition duration-200"
              >
                {'<'}
              </button>
              <button
                type="button"
                onClick={increaseMonth}
                style={{ color: 'white', backgroundColor: 'transparent' }}
                className="hover:bg-gray-700 rounded-full p-2 transition duration-200"
              >
                {'>'}
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default CustomDatePicker;
