import { t } from 'i18next';
import { ReactNode, useState } from 'react';
import { useBlockchain } from '../../context/blockchain-context';
import { useModal } from '../../context/modal-context';
import { getErrorMessage } from '../../helpers/error-helper';
import { DepositModal } from '../../pages/portfolio/DepositModal';
import { EnableTradingModal } from '../../pages/portfolio/EnableTradingtModal';
import Spinner from '../spinner/spinner-component';

export const DepositButton = ({ children }: { children: ReactNode }) => {
  const { openModal } = useModal();
  const [isLoading, setIsloading] = useState(false);
  const { proxyWalletAddress, setProxyWalletAddress } = useBlockchain();

  const handleErrorModal = (e: string) => {
    openModal({
      content: <p className="text-center">{getErrorMessage(e)}</p>,
      title: 'Error',
      type: 'error',
    });
    setIsloading(false);
  };

  const checkTradingEnabled = async () => {
    let result: boolean | undefined = false;
    if (!proxyWalletAddress) {
      return false;
    } else {
      setProxyWalletAddress(proxyWalletAddress);
      result = true;
    }
    return result;
  };

  const deposit = async () => {
    setIsloading(true);
    let enabled;
    try {
      enabled = await checkTradingEnabled();
      setIsloading(false);
    } catch (e) {
      handleErrorModal(typeof e === 'string' ? e : (e as Error)?.name);
      return;
    }
    openModal({
      title: !enabled ? t`Enable Trading` : t`Deposit USDC`,
      content: !enabled ? <EnableTradingModal /> : <DepositModal />,
    });
  };

  return (
    <div className="pointer-cursor" onClick={deposit}>
      {children}
      {isLoading && <Spinner />}
    </div>
  );
};
