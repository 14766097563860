import { MarketParams } from '../types/types';

export function objectToQueryParams(params: MarketParams): string {
  const queryParams = Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');
  return queryParams;
}
