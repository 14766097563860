import { TFunction } from 'i18next';
import { MarketResolvedTo } from '../types/types';
import { ReactComponent as CheckBlueLight } from '../assets/icons/check-blue-light.svg';

export const renderOutcome = (
  marketResolvedTo: MarketResolvedTo,
  outcome1: string,
  outcome2: string,
  t: TFunction
) => {
  switch (marketResolvedTo) {
    case 'YES':
      return outcome1;
    case 'NO':
      return outcome2;
    case 'TIE':
      return t`Tie`;
  }
};

export const getOutcomeColors = (
  resolvedOutcome?: string,
  textOnly?: boolean
) => {
  switch (resolvedOutcome?.toLocaleLowerCase()) {
    case 'yes':
      return `${!textOnly ? 'bg-green-opacity' : ''} text-green-text`;
    case 'no':
      return `${!textOnly ? 'bg-red-opacity' : ''} text-red-error`;
    default:
      return `${!textOnly ? 'bg-brand-blue-dark' : ''} text-brand-blue-light`;
  }
};

export const getCheckboxIcon = (resolvedOutcome?: string) => {
  switch (resolvedOutcome?.toLocaleLowerCase()) {
    case 'yes':
      return <CheckBlueLight className="resolved-checkbox-icon green" />;
    case 'no':
      return <CheckBlueLight className="resolved-checkbox-icon red" />;
    default:
      return <CheckBlueLight className="resolved-checkbox-icon blue" />;
  }
};
