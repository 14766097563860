import { objectToQueryParams } from '../helpers/object-to-params-helper';
import restService from '../helpers/rest-service-helper';
import {
  MarketActivity,
  MarketParams,
  PaginatedResponse,
  Pagination,
  Event,
  Position,
  Market,
} from '../types/types';
import { useEffect, useState } from 'react';
import { useUserPositions } from './porfolio-hook';

export const useEvents = async (
  marketParams: MarketParams
): Promise<PaginatedResponse<Event>> => {
  const markets = await restService.get<PaginatedResponse<Event>>(
    `/events?${objectToQueryParams(marketParams)}`
  );
  return markets;
};
let prevEventParams: MarketParams;
let prevEventData: Event[] = [];
let paginationData: Pagination;
export const useEventsData = (marketParams?: MarketParams) => {
  const [events, setEvents] = useState<Event[]>(prevEventData);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<Pagination>(paginationData);

  useEffect(() => {
    if (
      !marketParams ||
      JSON.stringify(prevEventParams) === JSON.stringify(marketParams)
    ) {
      return;
    }
    const fetchData = async () => {
      prevEventParams = marketParams;
      if (marketParams.page === 1) {
        setLoading(true);
      }
      try {
        const { data, ...rest } = await useEvents(marketParams);
        if (rest.first <= 1) {
          prevEventData = data;
          setEvents(prevEventData);
        } else {
          setEvents((prevEvents) => {
            prevEventData = [...prevEvents, ...(data as Event[])];
            return prevEventData;
          });
        }
        setPagination(rest);
        paginationData = rest;
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [marketParams]);

  return { events, setLoading, loading, pagination };
};

export const useMarketActivity = async (
  marketId: string,
  page: number,
  prevData: MarketActivity[] = [],
  proxyWalletAddress: string
) => {
  const activity = await restService.get<PaginatedResponse<MarketActivity>>(
    `/markets/activity/${marketId}?page=${page}&limit=10&proxyAddress=${proxyWalletAddress}`
  );

  return {
    ...activity,
    data: [...prevData, ...activity.data],
  };
};

export const useMarketsActivityAll = async (
  prevData: MarketActivity[] = []
) => {
  const activity =
    await restService.get<PaginatedResponse<MarketActivity>>(
      '/markets/activity'
    );

  return {
    ...activity,
    data: [...prevData, ...activity.data],
  };
};

export const settlePosition = async (marketId: string) => {
  const response = await restService.post(
    `/markets/redeem-positions/${marketId}`
  );

  return response;
};

export const usePositionsData = async (
  page: number,
  pageSize: number,
  term?: string,
  status?: string,
  sortBy?: string,
  order?: 'DESC' | 'ASC',
  proxyWalletSetup?: boolean
): Promise<PaginatedResponse<Position>> => {
  if (!proxyWalletSetup) {
    return {
      first: 0,
      last: 0,
      limit: 10,
      total: 0,
      data: Array(0),
    };
  }

  const userPositions = await useUserPositions(
    page,
    pageSize,
    term,
    status,
    sortBy,
    order
  );
  let positions: {
    marketId: string;
    tokenId: string;
    amount: number;
    acquisitionPrice: number;
    image: string;
    marketSlug: string | undefined;
    outcome: string;
    marketName: string;
    singleMarket: boolean | undefined;
    bgColor: string;
    textColor: string;
    shares: number;
    latest: number;
  }[] = [];

  const marketIds = userPositions.data
    .map((position) => position.market_uuid)
    .filter((marketId, index, self) => self.indexOf(marketId) === index);
  if (marketIds.length > 0) {
    const fetchedMarkets = await restService.get<Market[]>(
      `/markets?ids=${marketIds}`
    );
    positions = userPositions.data.map(
      ({ tokenid, acquisitionprice, amount, market_uuid, latest }) => {
        const market = fetchedMarkets.find(
          (market) => market.id === market_uuid
        );
        return {
          marketId: market_uuid,
          tokenId: tokenid,
          amount: parseFloat(amount),
          acquisitionPrice: acquisitionprice,
          image: market?.image ?? '',
          marketSlug: market?.slug,
          outcome:
            (market?.tokenId1 === tokenid
              ? market.outcome1
              : market?.outcome2) ?? '',
          marketName: market?.name ?? '',
          singleMarket: market?.event?.single,
          bgColor:
            market?.tokenId1 === tokenid
              ? 'bg-green-opacity'
              : 'bg-red-opacity',
          textColor:
            market?.tokenId1 === tokenid ? 'text-green-text' : 'text-red-error',
          eventSlug: market?.event?.slug ?? '',
          isResolved: market?.isResolved ?? false,
          shares: parseFloat(amount),
          latest: parseFloat(latest),
        };
      }
    );
  }

  return { ...userPositions, data: positions };
};
