import * as React from 'react';
import { Connector, useConnect } from 'wagmi';
import { Button } from './button/button-component';

export function WalletOptions() {
  const { connectors, connect } = useConnect();
  return (
    <div>
      <h2 className="font-bold text-white pb-2 text-sm font-bold">
        First connect to a provider
      </h2>
      <div className="flex flex-row gap-4">
        {connectors.map((connector, index) => (
          <WalletOption
            index={index + 1}
            key={connector.uid}
            connector={connector}
            onClick={() => connect({ connector })}
          />
        ))}
      </div>
    </div>
  );
}

function WalletOption({
  connector,
  className,
  onClick,
  index,
}: {
  connector: Connector;
  onClick: () => void;
  className?: string;
  index: number;
}) {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const provider = await connector?.getProvider();
      setReady(!!provider);
    })();
  }, [connector]);

  return (
    <Button
      disabled={!ready}
      className={className}
      data-testid={`METAMASK-button-${index}`}
      size="sm"
      variant="dark"
      onClick={onClick}
    >
      {connector.name}
    </Button>
  );
}
