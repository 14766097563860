import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg';
import { FormatCurrency } from '../../helpers/currency-helper';
import { ReactComponent as Anchor } from '../../assets/icons/anchor.svg';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/button/button-component';
import { DropdownComponent } from '../../components/dropdown/dropdown-component';
import { useEffect, useState } from 'react';
import restService from '../../helpers/rest-service-helper';
import { Event, EventPayload, Market } from '../../types/types';
import DateFormatter from '../../components/date-formatter/date-formatter-component';
import { SingleOutcome } from './SingleOutcome';
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import { renderTextWithLinks } from '../../helpers/link-helper';
import { useBlockchain } from '../../context/blockchain-context';
import { ResolvedMarket } from '../../components/resolved-market/resolved-market.component';
import { useLocation, useNavigate } from 'react-router-dom';
import { Contract, useContracts } from '../../context/contracts-context';
import { SplitOrMergeSharesModal } from './split-merge-shares/SplitOrMergeSharesModal';
import { Option } from 'react-tailwindcss-select/dist/components/type';
import { useWebsocket, WsPubEvent } from '../../context/websocket-context';
import { useModal } from '../../context/modal-context';
import Spinner from '../../components/spinner/spinner-component';
import { EventCard } from '../../components/market-card/event-card-component';
import { MultiOutcome } from './MultiOutcome';
import { useOrders } from '../../context/orderbook-context';
import { calculateTotalBetSum } from '../../helpers/calculate-helper';

export const MarketDetailsPage = () => {
  const { t } = useTranslation();
  const contracts = useContracts();
  const { address } = contracts.data.find(
    ({ name }) => name === 'UMA_CTF_ADAPTER'
  ) as Contract;
  const {
    showConfirmDepositHeading,
    showApproveTokensHeading,
    proxyWalletAddress,
    proxyWalletSetup,
  } = useBlockchain();
  const { collateralBalance, emit, sharesByMarketUpdate, socket } =
    useWebsocket();
  const { slug, outcome } = useParams();
  const { openModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { setOrderbookLoading } = useOrders();
  const options = [
    {
      value: '',
      label: '...',
    },
    {
      value: 'merge-shares',
      disabled: !proxyWalletAddress || !proxyWalletSetup,
      label: 'Merge Shares',
    },
    {
      value: 'split-shares',
      disabled:
        !collateralBalance.value || !proxyWalletAddress || !proxyWalletSetup,
      label: 'Split Shares',
    },
  ];
  const [event, setEvent] = useState<Event>();
  const [selectedOutcome, setSelectedOutcome] = useState<string | undefined>(
    outcome
  );
  const [selectedMarket, setSelectedMarket] = useState<Market | undefined>();
  const [expanded, setExpanded] = useState<Market | undefined>();
  const [showPage, setShowPage] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    const fetchEvent = async () => {
      try {
        const event = await restService.get<EventPayload>(`/events/${slug}`);
        if (!event.isInitialized || event.status === 'DRAFT') {
          navigate('/');
          return;
        }
        const eventWithCalculatedBet: Event = calculateTotalBetSum(event);
        setEvent(eventWithCalculatedBet);

        if (
          !selectedOutcome ||
          event.marketsView?.[0].outcome1 !== selectedOutcome ||
          event.marketsView?.[0].outcome2 !== selectedOutcome
        ) {
          setSelectedOutcome(event.marketsView?.[0].outcome1);
        }
        if (!event.single) {
          setSelectedMarket(
            outcome
              ? (event?.marketsView ?? []).find(
                  ({ slug, id }) => slug === outcome || id === outcome
                )
              : event?.marketsView?.[0]
          );
          if (outcome) {
            setExpanded(
              (event?.marketsView ?? []).find(({ slug }) => slug === outcome)
            );
          }
        }
        console.log('window.proxy', window.proxy);
        // window.proxy.sendMessage('metamarket.CREATE_ORDER', { data: 'ok' });

        setTimeout(() => {
          setIsLoading(false);
          setShowPage(true);
        }, 300);
        return event;
      } catch (e) {
        navigate('/');
      }
    };
    if (slug) {
      fetchEvent();
    }
    setOrderbookLoading({
      token1: {
        sell: false,
        buy: false,
      },
      token2: { sell: false, buy: false },
    });
  }, [slug]);

  useEffect(() => {
    if (
      !socket?.connected ||
      !event?.id ||
      !event.marketsView ||
      !event.marketsView.length
    ) {
      return;
    }
    const markets = event.marketsView;

    for (const market of markets) {
      emit(WsPubEvent.INITIAL_SHARES_BY_MARKET, market?.id);
      emit(WsPubEvent.ORDERBOOK_MARKET_CONNECT, market?.id);
    }

    return () => {
      for (const market of markets) {
        emit(WsPubEvent.SHARES_BY_MARKET_DISCONNECT, market?.id);
        emit(WsPubEvent.ORDERBOOK_MARKET_DISCONNECT, market?.id);
      }
    };
  }, [socket?.connected, event?.id]);

  return (
    <>
      {isLoading && <Spinner />}
      <div
        key={`refetch-data-${location?.state?.refetch}`}
        className={`${showPage ? 'ease-in-out transition-opacity duration-700' : 'opacity-0'} `}
      >
        <div className="flex justify-between items-start ">
          <div className="flex gap-3">
            {event?.image ? (
              <img
                className="w-[56px] h-[56px] rounded-xl object-cover"
                src={event.image}
              />
            ) : (
              <Avatar className="min-w-[56px] min-h-[56px] bg-grey rounded-xl p-2" />
            )}
            <div className="flex md:block items-center">
              <p
                data-testid="market-title"
                className="text-xl md:text-2xl font-bold text-white"
              >
                {event?.name}
              </p>
              {event?.endDate && (
                <div className="hidden md:flex gap-4 mt-1">
                  <p className="text-sm text-grey-secondary-darker flex justify-center items-center gap-1">
                    <Clock />
                    <DateFormatter dateString={event?.endDate} />
                  </p>
                  <p className="text-sm text-grey-secondary-darker">
                    {FormatCurrency(+(event.totalBet ?? 0), false, 2)} {t`Bet`}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2 md:gap-8 cursor-pointer">
            <Star />
            <Anchor />
          </div>
        </div>

        <div className="md:flex justify-between">
          <div className="gap-10 flex flex-col md:w-[720px] pb-[130px] md:pb-0">
            <div className="flex flex-col gap-4">
              {event?.single && (
                <SingleOutcome
                  setSelectedOutcome={setSelectedOutcome}
                  selectedOutcome={selectedOutcome}
                  market={selectedMarket ?? (event?.marketsView?.[0] as Market)}
                />
              )}
              {!event?.single && event?.marketsView && selectedMarket && (
                <div className="mt-6">
                  <MultiOutcome
                    setSelectedOutcome={setSelectedOutcome}
                    selectedOutcome={selectedOutcome}
                    selectedMarket={selectedMarket}
                    setSelectedMarket={setSelectedMarket}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    markets={event?.marketsView}
                  />
                </div>
              )}
            </div>
            {event?.description && (
              <div className="text-white w-[100%] flex flex-col gap-6 border border-grey-secondary p-4 rounded-lg">
                <p className="text-xl font-bold border-b border-grey-secondary pb-4">{t`Rules`}</p>
                <div className="text-sm">
                  <pre className="font-sans max-w-[686px] whitespace-break-spaces">
                    {renderTextWithLinks(
                      event.single
                        ? event.description
                        : selectedMarket?.description ?? ''
                    )}
                  </pre>
                </div>
                <p className="text-xs text-grey-secondary-darker border-grey-secondary border rounded-lg p-2 max-w-[230px] overflow-hidden text-ellipsis">
                  Resolver:{' '}
                  <a
                    target="_blank"
                    className="text-brand-blue"
                    href={`https://sepolia.etherscan.io/address/${address}`}
                    rel="noreferrer"
                  >
                    {address}
                  </a>
                </p>
              </div>
            )}
          </div>
          <div
            className={`md:w-[381px] h-full hidden md:block md:sticky ${showConfirmDepositHeading || showApproveTokensHeading ? 'md:top-[168px]' : 'md:top-[136px]'}`}
          >
            <div
              key={`market-${selectedMarket?.id}`}
              className={`md:w-[381px] ${event?.markets?.[0]?.isResolved ? 'p-6' : 'py-6 px-2'}  rounded-lg border border-grey-secondary `}
            >
              {event && !event.single && (
                <>
                  {selectedMarket?.status === 'RESOLVED' &&
                    selectedMarket?.marketResolvedTo && (
                      <ResolvedMarket
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        marketId={selectedMarket.id}
                        conditionId={selectedMarket.conditionId}
                        outcome1={selectedMarket.outcome1}
                        outcome2={selectedMarket.outcome2}
                        marketResolvedTo={selectedMarket?.marketResolvedTo}
                        isMultiMarket={!event.single}
                      />
                    )}
                  {selectedMarket?.status === 'ACTIVE' && (
                    <EventCard
                      slug={event.slug}
                      variant={'expanded'}
                      setSelectedOutcome={setSelectedOutcome}
                      selectedOutcome={selectedOutcome}
                      single={event.single}
                      conditionId={selectedMarket.conditionId}
                      markets={[selectedMarket]}
                      {...{
                        noHeader: true,
                        title: event.name,
                        totalBet: 1112833782,
                      }}
                    />
                  )}
                </>
              )}
              {event?.single && (
                <>
                  {event?.id &&
                    event.marketsView?.[0]?.isResolved &&
                    event.marketsView?.[0].marketResolvedTo && (
                      <ResolvedMarket
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        marketId={event.marketsView?.[0].id}
                        conditionId={event.conditionId}
                        outcome1={event.marketsView?.[0].outcome1}
                        outcome2={event.marketsView?.[0].outcome2}
                        marketResolvedTo={
                          event.marketsView?.[0].marketResolvedTo
                        }
                      />
                    )}
                  {event.marketsView?.[0]?.isResolved !== true && (
                    <EventCard
                      slug={event?.slug}
                      variant={'expanded'}
                      single={event.single}
                      conditionId={event?.marketsView?.[0].conditionId}
                      markets={event.marketsView}
                      selectedOutcome={selectedOutcome}
                      setSelectedOutcome={setSelectedOutcome}
                      {...{
                        noHeader: true,
                        title: event.name,
                        totalBet: 1112833782,
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {event?.single && (
            <div className="md:hidden mt-4  bg-black py-6 px-4 flex gap-2 text-sm fixed bottom-[64px] left-0 w-full ">
              <Button
                size="custom"
                className="overflow-hidden whitespace-nowrap text-ellipsis w-[100%] h-[100%] min-h-14 py-4 px-8"
                variant="green"
                onClick={() =>
                  navigate(
                    `/mobile-order/${encodeURIComponent(event?.marketsView?.[0]?.outcome1 ?? '')}`,
                    {
                      state: event.marketsView?.[0],
                    }
                  )
                }
              >
                {event?.marketsView?.[0]?.outcome1}
              </Button>
              <Button
                size="custom"
                onClick={() =>
                  navigate(
                    `/mobile-order/${encodeURIComponent(event?.marketsView?.[0]?.outcome2 ?? '')}`,
                    {
                      state: event.marketsView?.[0],
                    }
                  )
                }
                className="overflow-hidden whitespace-nowrap text-ellipsis w-[100%] h-[100%] min-h-14 py-4 px-8"
                variant="red"
              >
                {event?.marketsView?.[0]?.outcome2}
              </Button>
              <div className="mobile-dropdown">
                <DropdownComponent
                  primaryColor=""
                  optionTextColor="text-white"
                  onChange={(selectedValue) => {
                    const { value } = selectedValue as Option;
                    if (proxyWalletAddress && proxyWalletSetup) {
                      if (
                        value === 'split-shares' ||
                        value === 'merge-shares'
                      ) {
                        openModal({
                          content: (
                            <SplitOrMergeSharesModal
                              conditionId={event?.marketsView?.[0]?.conditionId}
                              marketId={event?.marketsView?.[0].id as string}
                              type={value}
                              isMultiMarket={!event?.single}
                              selectedToken={
                                selectedOutcome ===
                                event?.marketsView?.[0]?.outcome1
                                  ? 'token1'
                                  : 'token2'
                              }
                            />
                          ),
                          title:
                            value === 'split-shares'
                              ? t`Split Shares`
                              : t`Merge Shares`,
                        });
                      }
                    }
                  }}
                  value={options[0]}
                  noChevron
                  options={options.map((option) => {
                    if (option.value === 'merge-shares')
                      [
                        (option.disabled =
                          +sharesByMarketUpdate[
                            event?.marketsView?.[0].id as string
                          ]?.value?.[
                            selectedOutcome === event?.marketsView?.[0].outcome1
                              ? 'token1'
                              : 'token2'
                          ]?.balance <= 0),
                      ];
                    return option;
                  })}
                ></DropdownComponent>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
