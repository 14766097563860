import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../components/search-input/search-input-component';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Event, MarketParams, Pagination } from '../../types/types';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg';
import { ReactComponent as ChevronBack } from '../../assets/icons/chevron-back.svg';
import { useEventsData } from '../../hooks/markets-hook';
import InfiniteScroll from '../../components/infinite-scroll/infinite-scroll-component';

export const EventsSearchPage = ({
  marketParams,
  setMarketParams,
}: {
  marketParams: MarketParams;
  setMarketParams: Dispatch<SetStateAction<MarketParams>>;
}) => {
  const { t } = useTranslation();
  const [term, setTerm] = useState<string>('');
  const [events, setEvents] = useState<Event[]>();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<Pagination>();
  const data = useEventsData({
    ...marketParams,
    ...(term ? { term } : {}),
  });

  useEffect(() => {
    if (term.length > 0) {
      setEvents(data.events);
      setLoading(data.loading);
      setPagination(data.pagination);
    }
  }, [data, term]);
  const navigate = useNavigate();

  return (
    <div className="bg-black-light fixed top-0 h-full z-10 w-full left-0 flex flex-col gap-8">
      <div className="p-2 flex border-b border-grey-secondary items-center gap-2 px-2">
        <div
          onClick={() => navigate('/')}
          className="cursor-pointer bg-black-dark p-3 rounded-xl w-[44px] h-[44px] flex flex-col items-center justify-center"
        >
          <ChevronBack />
        </div>
        <p className="text-white text-sm">{t`Search`}</p>
      </div>
      <div className="px-4 flex flex-col gap-8">
        <SearchInput
          className="flex-grow bg-transparent bg-search bg-no-repeat bg-[center_left_12px] text-white w-full"
          placeholder={t`Search markets`}
          type="text"
          onChange={({ target: { value } }) => {
            setTerm(value);
          }}
        />
        {term.length > 0 && (events ?? []).length > 0 && (
          <div className="bg-black-dark p-4 rounded-2xl flex flex-col gap-4">
            <p className="text-text-secondary text-sm">{t`RESULTS`}</p>

            <InfiniteScroll
              className={`flex flex-col gap-4 ${loading ? 'invisible' : ''}`}
              dataLength={(events ?? []).length}
              next={() =>
                setMarketParams({
                  ...marketParams,
                  page: marketParams.page + 1,
                })
              }
              hasMore={pagination?.last !== pagination?.total}
              loader={<></>}
            >
              {(events ?? []).map((event, key) => {
                return (
                  <Link key={`card-${key}`} to={`/market/${event.slug}`}>
                    <div className="flex text-sm gap-2 items-center">
                      {!event.image ? (
                        <div className="rounded-xl bg-grey p-2">
                          <Avatar className="w-[32px]" />
                        </div>
                      ) : (
                        <img
                          src={event.image}
                          className="w-[48px] h-[48px] rounded-xl object-cover"
                        />
                      )}
                      <div>
                        <p className="text-white whitespace-nowrap break-all overflow-hidden text-ellipsis">
                          {event.name}
                        </p>
                        <div className="flex">
                          <p className="bg-green-opacity py-[2px] px-2 rounded-md text-sm text-green-light whitespace-nowrap break-all overflow-hidden text-ellipsis">
                            {event.marketsView?.[0]?.outcome1}
                          </p>
                          <p className="bg-red-opacity py-[2px] px-2 rounded-md text-sm text-red-error whitespace-nowrap break-all overflow-hidden text-ellipsis">
                            {event.marketsView?.[0]?.outcome2}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
};
