import React, { useState } from 'react';
import { Button, Variant } from '../button/button-component';
interface Tab {
  title: string;
  content: React.ReactNode;
  ['data-testid']?: string;
}

interface TabsProps {
  tabs: Tab[];
  activeByDefault?: number;
  hasSearch?: boolean;
  onChange: (tab: Tab) => void;
  className?: string;
  variant?: Variant;
  noBorder?: boolean;
  hiddenOnMobile?: boolean;
}

const Tabs: React.FC<TabsProps> = ({
  activeByDefault,
  tabs,
  onChange,
  className,
  variant = 'dark',
  noBorder,
  hiddenOnMobile,
}) => {
  const [activeTab, setActiveTab] = useState<number | undefined>(
    activeByDefault
  );
  const handleTabClick = (index: number) => {
    setActiveTab(index ?? 0);
    onChange(tabs[index]);
  };

  return (
    <div
      className={`${hiddenOnMobile ? 'hidden md:block' : ''} ${!noBorder ? 'md:border-b md:border-grey' : ''} relative py-2`}
    >
      <div
        className={`tabs-container flex md:inline-flex rounded-md w-auto  ${className ?? 'text-xs'}  md:static overflow-x-auto`}
      >
        <div className={`relative flex gap-2 ${className ? '' : 'gap-6'} `}>
          {tabs.map((tab, index) => (
            <Button
              data-testid={`tab-${index}`}
              noHover={activeTab === index}
              key={index}
              className={`text-text-secondary ${className ? '' : 'px-0 pb-1'} hover:text-white hover:bg-black-light ${
                activeTab !== index ? 'text-grey-secondary-darker' : ''
              }`}
              size={className ? 'md' : 'custom'}
              variant={`${activeTab === index ? variant : 'transparent'}`}
              onClick={() => handleTabClick(index)}
            >
              {tab.title}
            </Button>
          ))}
        </div>
      </div>
      <div className="tabs-content">{tabs[activeTab ?? 0].content}</div>
    </div>
  );
};

export default Tabs;
