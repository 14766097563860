import React, { ReactNode, useState } from 'react';
import './checkbox-component.css';

interface CheckboxProps extends React.ButtonHTMLAttributes<HTMLInputElement> {
  onFilterChange: (
    checked: boolean,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => void;
  label?: ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  onFilterChange,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <label htmlFor={rest.id ?? ''} className="custom-checkbox flex gap-2">
      <div>
        <input
          {...rest}
          type="checkbox"
          onChange={(e) => {
            onFilterChange(!isChecked, e);
            setIsChecked(!isChecked);
          }}
        />
        <span
          className={`checkbox-mark ${isChecked ? 'checked' : ''} bg-transparent border-2 rounded-md ${isChecked ? 'border-water-blue' : 'border-grey-secondary-darker'}`}
        ></span>
      </div>
      {label && <div className="checkbox-label text-sm">{label}</div>}
    </label>
  );
};

export default Checkbox;
