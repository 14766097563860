import { useTranslation } from 'react-i18next';
import { FormatCurrency } from '../../helpers/currency-helper';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { Button } from '../../components/button/button-component';
import { useBlockchain } from '../../context/blockchain-context';
import { copyToClipboard } from '../../helpers/copy-to-clipboard';
import { useState } from 'react';
import { useModal } from '../../context/modal-context';
import { useWebsocket } from '../../context/websocket-context';

export const DepositModal = () => {
  const { proxyWalletAddress } = useBlockchain();
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const initialText = t`Copy`;
  const resetTextTime = 3000;
  const [copyBtnText, setCopyBtnText] = useState(initialText);
  const { collateralBalance } = useWebsocket();

  const copyWallet = async () => {
    const copied = await copyToClipboard(proxyWalletAddress as string);
    if (copied) {
      setCopyBtnText(t`Copied!`);
      setTimeout(() => {
        setCopyBtnText(initialText);
      }, resetTextTime);
    }
  };

  return (
    <div className="flex flex-col gap-6" data-testid="deposit-modal">
      <p className="text-text-secondary text-base text-center">{t`Send USDC to your Proxy Wallet bellow`}</p>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <p className="text-sm font-bold text-white">{t`Proxy Wallet`}</p>
          <p
            data-testid="balance-amount"
            className="bg-grey-light text-xs text-white px-3 py-1 font-bold rounded-md"
          >
            {t`Balance`} {FormatCurrency(collateralBalance.value, false)}
          </p>
        </div>
        <div className="rounded-md border border-grey-secondary px-4 py-3 text-sm text-text-secondary flex justify-between items-center">
          <p
            data-testid="button-proxy-wallet-address"
            title={proxyWalletAddress ?? ''}
            className="max-w-[319px] overflow-hidden text-ellipsis"
          >
            {proxyWalletAddress}
          </p>
          <Copy onClick={copyWallet} className="cursor-pointer" />
        </div>
      </div>
      <div className="grid gap-2">
        <Button
          data-testid="button-Copy"
          onClick={copyWallet}
          size="lg"
          variant="blue"
        >
          {t(copyBtnText)}
        </Button>
        <Button
          data-testid="button-Cancel"
          size="lg"
          onClick={closeModal}
          variant="transparent"
        >
          {t`Cancel`}
        </Button>
      </div>
    </div>
  );
};
