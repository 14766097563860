import restService from '../helpers/rest-service-helper';
import { PaginatedResponse, UserActivity, UserPosition } from '../types/types';
import { useEffect, useState } from 'react';
import { usePositionsData } from '../hooks/markets-hook';
import { calculateValue } from '../helpers/calculate-helper';
import { formatUnits } from 'viem';
import { useAccount } from 'wagmi';
import { useBlockchain } from '../../src/context/blockchain-context';

export const useUserActivity = async (
  page: number,
  pageSize: number,
  term?: string,
  status?: string,
  sortBy?: string,
  order?: 'DESC' | 'ASC'
) => {
  const activity = await restService.get<PaginatedResponse<UserActivity>>(
    `/markets/user-activity?page=${page}&sortBy=${sortBy}&order=${order}&limit=${pageSize}${term ? '&term=' + term : ''}${status ? '&status=' + status : ''}`
  );

  return activity;
};

export const useUserPositions = async (
  page: number,
  pageSize: number,
  term?: string,
  status?: string,
  sortBy?: string,
  order?: 'DESC' | 'ASC'
) => {
  const activity = await restService.get<PaginatedResponse<UserPosition>>(
    `/markets/user-positions?page=${page}&sortBy=${sortBy}&order=${order}&limit=${pageSize}${term ? '&term=' + term : ''}${status ? '&status=' + status : ''}`
  );

  return activity;
};

export const useTotalPositionsValue = ():
  | {
      totalPositionsValue: string;
      previousPositionsValue: number;
      isLoading: boolean;
      error: string | null;
    }
  | undefined => {
  const [error, setError] = useState<null | string>(null);
  const [totalPositionsValue, setTotalPositionsValue] = useState<string>('0');
  const [previousPositionsValue, setPreviousPositionsValue] =
    useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  const { isConnected } = useAccount();
  const { evmAddress, proxyWalletAddress, proxyWalletSetup } = useBlockchain();

  useEffect(() => {
    if (
      !isConnected ||
      !evmAddress ||
      !proxyWalletAddress ||
      !proxyWalletSetup
    ) {
      setIsLoading(false);
      setTotalPositionsValue('0');
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const { data } = await usePositionsData(1, 10, '', '', 'latest', 'DESC');
        const totalValue = data.reduce((acc: number, position: any) => {
          const shares = parseFloat(position.amount);
          const currentMarketPrice = parseFloat(position.latest || '0');
          return acc + calculateValue(shares, currentMarketPrice);
        }, 0);
        const formattedTotalValue = formatUnits(
          BigInt(Math.floor(totalValue)),
          6
        );
        const previousValue = 20.71; // TODO: add dynamic value when backend ready
        setPreviousPositionsValue(previousValue);
        setTotalPositionsValue(formattedTotalValue);
        setIsLoading(false);
      } catch (err) {
        setError('Err');
        console.error('Error fetching positions data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [isConnected, evmAddress, proxyWalletAddress, proxyWalletSetup]);

  return { totalPositionsValue, previousPositionsValue, isLoading, error };
};
