import { useTranslation } from 'react-i18next';
import { MarketOverviewPage } from '../market-overview/MarketOverviewPage';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MarketActivity, MarketParams } from '../../types/types';
import { useMarketsActivityAll } from '../../hooks/markets-hook';
import { formatUnits } from 'viem';
import { isBuyOrSell } from '../../helpers/checkers';
import { FormatCurrency } from '../../helpers/currency-helper';
import { getElapsedTime } from '../../helpers/date-helper';
import { NavLink } from 'react-router-dom';

export const HomePage = ({
  marketParams,
  setMarketParams,
}: {
  marketParams: MarketParams;
  setMarketParams: Dispatch<SetStateAction<MarketParams>>;
}) => {
  const [history, setHistory] = useState<MarketActivity[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchHistory = async () => {
      const activityData = await useMarketsActivityAll();
      setHistory(activityData.data);
    };
    fetchHistory();
  }, []);
  return (
    <>
      <MarketOverviewPage
        marketParams={marketParams}
        setMarketParams={setMarketParams}
        hasInfiniteScroll={false}
      />
      <h3 className="text-white text-xl font-bold">{t`Recent Activity`}</h3>
      {history.length > 0 ? (
        <div className="flex flex-col gap-2">
          {history.map((item, key) => (
            <NavLink
              key={`history-${key}`}
              className="flex justify-between px-2 py-3 items-center cursor:pointer hover:bg-grey-light hover:rounded-md border-white-opacity border-b"
              to={`/market/${item?.slug}/`}
            >
              <div>
                <div className="flex gap-3 items-center">
                  {item.icon ? (
                    <img
                      src={item.icon}
                      alt={item.name}
                      className="w-[40px] h-[40px] rounded-xl object-cover"
                    />
                  ) : (
                    <div className="w-[40px] h-[40px] rounded-xl bg-grey"></div>
                  )}
                  <div>
                    <p className="text-md text-grey-secondary-darker">
                      {item.name}
                    </p>
                    <p className="text-white-secondary-darker text-sm">
                      <span>
                        {' '}
                        {item.proxy_address
                          ? item.proxy_address.slice(0, 6) +
                            '...' +
                            item.proxy_address.slice(-4)
                          : ''}
                      </span>{' '}
                      {item.type === 'BUY' ? t`bought` : ''}
                      {item.type === 'SELL' ? t`sold` : ''}
                      {isBuyOrSell(item.type) && item.partition[0] === 1 && (
                        <span className="text-green-lighter">
                          {' '}
                          {item.outcome1}
                        </span>
                      )}
                      {isBuyOrSell(item.type) && item.partition[0] === 2 && (
                        <span className="text-red-lighter">
                          {' '}
                          {item.outcome2}
                        </span>
                      )}
                      {isBuyOrSell(item.type) && (
                        <>
                          {' '}
                          {t`at` +
                            ' ' +
                            Math.floor(parseFloat(item.price) * 100) +
                            t`¢`}
                        </>
                      )}
                      <span className="text-grey-secondary-darker">
                        {' '}
                        (
                        {isBuyOrSell(item.type) &&
                          FormatCurrency(
                            parseFloat(
                              formatUnits(item.amount as unknown as bigint, 6)
                            ) * parseFloat(item.price),
                            false,
                            2
                          )}
                        )
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <p className="text-sm text-grey-secondary">
                  {getElapsedTime(
                    new Date(+item.timestamp * 1000).toISOString()
                  )}
                </p>
              </div>
            </NavLink>
          ))}
        </div>
      ) : (
        <div className="text-white w-[100%] flex flex-col border border-grey-secondary gap-6 p-4 rounded-lg">
          <div className="grid gap-2">
            <p className="text-white text-base font-bold">
              {t`Recent activity is empty.`}
            </p>
            <p className="text-text-secondary text-sm">
              {t`This section shows the current market activity.`}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
