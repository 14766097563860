import React, { useState, useEffect, useRef } from 'react';

interface InfiniteScrollProps {
  children: React.ReactNode; // The content to render inside the scrollable area
  dataLength: number; // Current number of items loaded
  next: () => void; // Function to fetch the next batch of data
  hasMore: boolean; // Boolean indicating whether more data is available
  loader?: React.ReactNode; // Element displayed while fetching new data
  className?: string; // Optional class for styling the container
  threshold?: number; // Threshold in pixels to trigger data fetching before reaching the bottom
}

interface ScrollData {
  scrollTop: number;
  scrollHeight: number;
  clientHeight: number;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  children,
  dataLength,
  next,
  hasMore,
  loader,
  className = '',
  threshold = 100, // Default threshold: 100px
}) => {
  const [scrollData, setScrollData] = useState<ScrollData>({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  });
  const [isFetching, setIsFetching] = useState(false);

  // Debounce ref to store the timer
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleMessage = (event: any) => {
      const { scrollTop, scrollHeight, clientHeight } = event.payload;
      // Clear the previous debounce timer if it's still running
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }

      // Set a new debounce timer to update the scroll data after 100ms
      debounceTimer.current = setTimeout(() => {
        setScrollData({ scrollTop, scrollHeight, clientHeight });
      }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
    };

    window.proxy.addMessageListener('scroll', handleMessage);

    // Clean up the listener and debounce timer on component unmount
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current); // Clear timeout on cleanup
      }
    };
  }, []);

  useEffect(() => {
    const { scrollTop, clientHeight, scrollHeight } = scrollData;

    // Trigger fetching when the user is near the bottom of the scrollable container
    if (
      hasMore &&
      !isFetching &&
      scrollTop + clientHeight >= scrollHeight - threshold &&
      scrollTop > 0 &&
      clientHeight > 0
    ) {
      setIsFetching(true);
      next(); // Call next to load more data
    }
  }, [scrollData, hasMore, isFetching, next, threshold]);

  useEffect(() => {
    setIsFetching(false); // Reset fetching when new data is loaded
  }, [dataLength]);

  return (
    <div className={className}>
      {children}
      {isFetching && hasMore && loader}
    </div>
  );
};

export default InfiniteScroll;
