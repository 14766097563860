import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button } from '../../../components/button/button-component';
import DateFormatter from '../../../components/date-formatter/date-formatter-component';
import { DropdownComponent } from '../../../components/dropdown/dropdown-component';
import { useBlockchain } from '../../../context/blockchain-context';
import { useContracts, Contract } from '../../../context/contracts-context';
import { useModal } from '../../../context/modal-context';
import { useWebsocket, WsPubEvent } from '../../../context/websocket-context';
import { FormatCurrency } from '../../../helpers/currency-helper';
import { renderTextWithLinks } from '../../../helpers/link-helper';
import { Market } from '../../../types/types';
import { SingleOutcome } from '../SingleOutcome';
import { SplitOrMergeSharesModal } from '../split-merge-shares/SplitOrMergeSharesModal';
import { ReactComponent as Avatar } from '../../../assets/icons/avatar.svg';
import { ReactComponent as Clock } from '../../../assets/icons/clock.svg';
import { ReactComponent as Star } from '../../../assets/icons/clock.svg';
import { ReactComponent as Anchor } from '../../../assets/icons/clock.svg';
import { Option } from 'react-tailwindcss-select/dist/components/type';

export const MobileSelectedMarket = () => {
  const { t } = useTranslation();
  const contracts = useContracts();
  const { address } = contracts.data.find(
    ({ name }) => name === 'UMA_CTF_ADAPTER'
  ) as Contract;
  const { proxyWalletAddress, proxyWalletSetup } = useBlockchain();
  const { collateralBalance, emit, sharesByMarketUpdate, socket } =
    useWebsocket();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const [market, setMarket] = useState<Market>(location?.state);
  const { selectedOutcome } = useParams();

  const options = [
    {
      value: '',
      label: '...',
    },
    {
      value: 'merge-shares',
      disabled:
        +sharesByMarketUpdate[market.id]?.value?.[
          selectedOutcome === market.outcome1 ? 'token1' : 'token2'
        ]?.balance <= 0 ||
        !proxyWalletAddress ||
        !proxyWalletSetup,
      label: 'Merge Shares',
    },
    {
      value: 'split-shares',
      disabled:
        !collateralBalance.value || !proxyWalletAddress || !proxyWalletSetup,
      label: 'Split Shares',
    },
  ];
  useEffect(() => {
    if (socket?.connected && market?.id) {
      emit(WsPubEvent.INITIAL_SHARES_BY_MARKET, market?.id);
      // emit(WsPubEvent.ORDERBOOK_MARKET_CONNECT, market?.id);
    }

    return () => {
      emit(WsPubEvent.SHARES_BY_MARKET_DISCONNECT, market?.id);
      // emit(WsPubEvent.ORDERBOOK_MARKET_DISCONNECT, market?.id);
    };
  }, [socket?.connected, market?.id]);

  return (
    <>
      <div>
        <div className="flex justify-between items-start ">
          <div className="flex gap-3">
            {market?.image ? (
              <img
                className="w-[56px] h-[56px] rounded-xl object-cover"
                src={market.image}
              />
            ) : (
              <Avatar className="min-w-[56px] min-h-[56px] bg-grey rounded-xl p-2" />
            )}
            <div className="flex md:block items-center">
              <p
                data-testid="market-title"
                className="text-xl md:text-2xl font-bold text-white"
              >
                {market?.name}
              </p>
              {market?.endDate && (
                <div className="hidden md:flex gap-4 mt-1">
                  <p className="text-sm text-grey-secondary-darker flex justify-center items-center gap-1">
                    <Clock />
                    <DateFormatter dateString={market?.endDate} />
                  </p>
                  <p className="text-sm text-grey-secondary-darker">
                    {FormatCurrency(0)} {t`Bet`}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2 md:gap-8 cursor-pointer">
            <Star />
            <Anchor />
          </div>
        </div>

        <div className="md:flex justify-between">
          <div className="gap-10 flex flex-col md:w-[720px] pb-[130px] md:pb-0">
            <div className="flex flex-col gap-4">
              <SingleOutcome market={market} />
            </div>
            {market?.description && (
              <div className="text-white w-[100%] flex flex-col gap-6 border border-grey-secondary p-4 rounded-lg">
                <p className="text-xl font-bold border-b border-grey-secondary pb-4">{t`Rules`}</p>
                <div className="text-sm">
                  <pre className="font-sans max-w-[686px] whitespace-break-spaces">
                    {renderTextWithLinks(market.description)}
                  </pre>
                </div>
                <p className="text-xs text-grey-secondary-darker border-grey-secondary border rounded-lg p-2 max-w-[230px] overflow-hidden text-ellipsis">
                  Resolver:{' '}
                  <a
                    target="_blank"
                    className="text-brand-blue"
                    href={`https://sepolia.etherscan.io/address/${address}`}
                    rel="noreferrer"
                  >
                    {address}
                  </a>
                </p>
              </div>
            )}
          </div>
          {!market.isResolved && (
            <div className="md:hidden mt-4  bg-black py-6 px-4 flex gap-2 text-sm fixed bottom-[64px] left-0 w-full ">
              <Button
                size="custom"
                className="overflow-hidden whitespace-nowrap text-ellipsis w-[100%] h-[100%] min-h-14 py-4 px-8"
                variant="green"
                onClick={() =>
                  navigate(
                    `/mobile-order/${encodeURIComponent(market.outcome1 ?? '')}`,
                    {
                      state: market,
                    }
                  )
                }
              >
                {market.outcome1}
              </Button>
              <Button
                size="custom"
                onClick={() =>
                  navigate(
                    `/mobile-order/${encodeURIComponent(market.outcome2 ?? '')}`,
                    {
                      state: { ...market },
                    }
                  )
                }
                className="overflow-hidden whitespace-nowrap text-ellipsis w-[100%] h-[100%] min-h-14 py-4 px-8"
                variant="red"
              >
                {market.outcome2}
              </Button>
              <div className="mobile-dropdown">
                <DropdownComponent
                  primaryColor=""
                  optionTextColor="text-white"
                  onChange={(selectedValue) => {
                    const { value } = selectedValue as Option;
                    if (value === 'split-shares' || value === 'merge-shares') {
                      openModal({
                        content: (
                          <SplitOrMergeSharesModal
                            conditionId={market?.conditionId}
                            marketId={market.id}
                            type={value}
                            selectedToken={
                              selectedOutcome === market.outcome1
                                ? 'token1'
                                : 'token2'
                            }
                          />
                        ),
                        title:
                          value === 'split-shares'
                            ? t`Split Shares`
                            : t`Merge Shares`,
                      });
                    }
                  }}
                  value={options[0]}
                  noChevron
                  options={options}
                ></DropdownComponent>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
