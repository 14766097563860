import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import styles from './button-component.module.css';

export type Variant =
  | 'blue'
  | 'dark'
  | 'red'
  | 'green'
  | 'transparent'
  | 'darkLighter'
  | 'darkBlack'
  | 'darkOpacity';
type Size = 'sm' | 'md' | 'lg' | 'custom';
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  size?: Size;
  icon?: boolean;
  noHover?: boolean;
  noWordBreak?: boolean;
}

export const variants = (noHover?: boolean): Record<Variant, string> => ({
  blue: 'bg-brand-blue hover:bg-blue-light disabled:bg-blue-dark disabled:text-grey-inactive active:bg-brand-blue',
  darkLighter:
    'bg-grey-lighter hover:bg-grey-light hover:text-white active:bg-grey-lighter active:text-white disabled:bg-black-light disabled:text-grey-inactive',
  dark: `bg-black-light ${!noHover ? 'hover:bg-grey-light hover:text-white' : ''} active:bg-grey-lighter active:text-white disabled:bg-black-light disabled:text-grey-inactive`,
  darkBlack:
    'bg-black-dark hover:bg-grey-light hover:text-white active:bg-grey-lighter active:text-white disabled:bg-black-light disabled:text-grey-inactive',

  darkOpacity:
    'bg-black-dark-opacity hover:bg-grey-light hover:text-white active:bg-grey-lighter active:text-white disabled:bg-black-light disabled:text-grey-inactive',
  transparent: 'bg-transparent disabled:text-grey-inactive',
  red: 'bg-red-transparent hover:text-white hover:bg-red-light active:text-white active:bg-red-lighter disabled:text-grey-dark-inactive disabled:bg-black-dark disabled:border-grey disabled:border',
  green:
    'bg-green-transparent hover:text-white hover:bg-green-normal disabled:text-grey-dark-inactive disabled:border-grey disabled:bg-black-dark disabled:border active:text-white active:bg-green-lighter',
});
const sizes: Record<Size, string> = {
  sm: 'h-6 py-1 px-3 text-xs',
  md: 'py-3 px-6',
  lg: 'h-14 py-4 px-8',
  custom: '',
};

export const Button = ({
  variant = 'blue',
  size = 'md',
  children,
  icon,
  noHover,
  noWordBreak,
  ...rest
}: ButtonProps) => {
  return (
    <button
      {...rest}
      className={`font-bold transition duration-200 rounded-md font-bold ${
        icon ? styles[`icon-${variant}`] : ''
      } ${variants(noHover)[variant]} ${sizes[size]} ${
        !rest.className?.includes('text-grey') ? 'text-white' : ''
      } ${rest.className ?? ''}`}
    >
      <div className={`${icon ? 'flex items-center gap-3' : ''}`}>
        {icon && <Plus className="plus-icon w-4 h-4" />}
        <div
          className={`${!noWordBreak ? 'whitespace-nowrap break-all overflow-hidden text-ellipsis' : 'flex justify-center'}`}
        >
          {children}
        </div>
        {icon && <Plus className="plus-icon w-4 h-4" />}
      </div>
    </button>
  );
};
