import { Button } from '../../components/button/button-component';
import Tabs from '../../components/tabs/tabs-component';
import { useTranslation } from 'react-i18next';
import { Positions } from './Positions';
import { OpenOrders } from './OpenOrders';
import { HistoryTable } from './History';
import useViewport, { isMobile } from '../../hooks/viewport';
import { useModal } from '../../context/modal-context';
import { WithdrawModal } from './WithdrawModal';
import { useBlockchain } from '../../context/blockchain-context';
import { useEffect, useState } from 'react';
import Spinner from '../../components/spinner/spinner-component';
import { FormatCurrency } from '../../helpers/currency-helper';
import { DepositButton } from '../../components/deposit-button/deposit-button-component';
import { copyToClipboard } from '../../helpers/copy-to-clipboard';
import { ReactComponent as Copy } from '../../assets/icons/copy.svg';
import { useWebsocket } from '../../context/websocket-context';
import { ReactComponent as Checkmark } from '../../assets/icons/checkmark.svg';
import {
  useTotalPositionsValue,
  useUserActivity,
} from '../../hooks/porfolio-hook';
import { formatUnits } from 'viem';
import { useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi';

export const Portfolio = () => {
  const { t } = useTranslation();
  const { width } = useViewport();
  const { openModal } = useModal();
  const [isLoading] = useState(false);
  const { evmAddress, proxyWalletAddress, username, imageURL } =
    useBlockchain();
  const { collateralBalance } = useWebsocket();
  const [isCopied, setIsCopied] = useState(false);
  const [totalMarketsTraded, setTotalMarketsTraded] = useState(0);
  const [totalVolumeTraded, setTotalVolumeTraded] = useState('0');
  const location = useLocation();
  const { isConnected } = useAccount();

  useEffect(() => {
    if (!isConnected || !evmAddress || !proxyWalletAddress) {
      return;
    }
    const fetchData = async () => {
      const uniqueMarketIds = new Set();
      let totalVolume = 0;

      const res = await useUserActivity(1, 1000, '', '', '', 'DESC');
      const activities = res.data;
      const filteredActivitiesMarketsTraded = activities.filter((activity) =>
        ['SPLIT', 'BUY'].includes(activity.type)
      );
      filteredActivitiesMarketsTraded.forEach((activity) => {
        uniqueMarketIds.add(activity.slug);
      });
      const filteredActivitiesVolumeTraded = activities.filter((activity) =>
        ['BUY', 'SELL'].includes(activity.type)
      );

      filteredActivitiesVolumeTraded.forEach((activity) => {
        const shares = parseFloat(activity.amount);
        const pricePerShare = parseFloat(activity.price);
        const tradeValue = shares * pricePerShare;

        totalVolume += tradeValue;
      });
      const formattedTotalVolumeValue = formatUnits(
        BigInt(Math.floor(totalVolume)),
        6
      );

      setTotalVolumeTraded(formattedTotalVolumeValue);
      setTotalMarketsTraded(uniqueMarketIds.size);
    };

    fetchData();
  }, [isConnected, evmAddress, proxyWalletAddress]);
  const totalPositionsValueData = useTotalPositionsValue();

  const TotalPositionsContent = () => {
    if (!totalPositionsValueData) {
      return (
        <span className="text-lg md:text-2xl font-bold w-full">$0.00</span>
      );
    }
    const {
      totalPositionsValue,
      previousPositionsValue,
      isLoading: isTotalPositionsLoading,
      error,
    } = totalPositionsValueData || {};

    // const differenceValue =
    //   Number(totalPositionsValue) - previousPositionsValue;
    // const differencePercentage = previousPositionsValue
    //   ? (differenceValue / previousPositionsValue) * 100
    //   : 0;

    return (
      <>
        {isTotalPositionsLoading ? (
          <div className="animate-pulse w-full">
            <div className="h-8 bg-grey-lighter rounded-md"></div>
          </div>
        ) : error ? (
          <span
            data-testid="portfolio-amount"
            className="text-lg md:text-2xl font-bold w-full"
          >
            $0.00
          </span>
        ) : (
          <span
            data-testid="portfolio-amount"
            className="text-lg md:text-2xl font-bold w-full"
          >
            {FormatCurrency(
              Number(totalPositionsValue) + Number(collateralBalance.value),
              false,
              2
            )}
          </span>
        )}
        {/* <p
              className={`text-sm ${differenceValue >= 0 ? 'text-green-light' : 'text-red-error'}`}
            >
              {differenceValue >= 0 ? '+' : ''}
              {FormatCurrency(differenceValue, false, 2)} (
              {differenceValue >= 0 ? '+' : ''}
              {differencePercentage.toFixed(2)}%){' '}
              <span className="text-text-secondary">{t`today`}</span>
            </p> */}
      </>
    );
  };

  const PositionsValueContent = () => {
    if (!totalPositionsValueData) {
      return <span className="text-lg md:text-2xl font-bold">$0.00</span>;
    }

    const { totalPositionsValue, isLoading: isTotalPositionsLoading } =
      totalPositionsValueData || {};
    return (
      <>
        {!proxyWalletAddress ? (
          <span className="text-lg md:text-2xl font-bold">{'$0.00'} </span>
        ) : isTotalPositionsLoading ? (
          <div className="animate-pulse max-w-20">
            <div className="h-8 bg-grey-lighter rounded-md"></div>
          </div>
        ) : (
          <span className="text-lg md:text-2xl font-bold">
            {FormatCurrency(Number(totalPositionsValue), false, 2)}
          </span>
        )}
      </>
    );
  };

  const TABS = [
    {
      title: t`Positions`,
      content: <Positions />,
    },
    {
      title: t`Open Orders`,
      content: (
        <div key={`refetch-data-${location?.state?.refetch}`}>
          <OpenOrders />
        </div>
      ),
    },
    { title: t`History`, content: <HistoryTable /> },
  ];
  const [showPage, setShowPage] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowPage(true);
    }, 300);
  }, []);
  const copyWallet = async () => {
    await copyToClipboard(proxyWalletAddress as string);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };
  return (
    <div
      className={`${showPage ? 'ease-in-out transition-opacity duration-700' : 'opacity-0'} relative md:pb-0 flex gap-10 flex-col`}
    >
      <div className="flex gap-4">
        {imageURL && (
          <img className="rounded-lg h-[64px]" src={imageURL} alt="" />
        )}
        <div>
          <p className="text-lg md:text-2xl font-bold">{username}</p>
          <div className="text-xs bg-grey-light py-1 px-3 rounded-md font-bold   break-all max-w-[100%]">
            {proxyWalletAddress ? (
              <div
                onClick={copyWallet}
                data-testid="button-proxy-wallet-address"
                className="flex cursor-pointer gap-1 text-ellipsis"
              >
                {proxyWalletAddress}
                {isCopied ? (
                  <Checkmark className="w-[16px] h-[16px]" />
                ) : (
                  <Copy />
                )}
              </div>
            ) : (
              <DepositButton data-testid="button-Enable-Trading">
                <span className="cursor-pointer">{t`Enable trading`}</span>
              </DepositButton>
            )}
          </div>
        </div>
      </div>
      <div className="bg-black-dark p-4 gap-6 flex flex-col md:flex-row rounded-2xl md:justify-between md:items-center">
        <div className="flex">
          <div className="flex flex-col gap-1 border-r border-grey-secondary-darker px-4">
            <p className="text-sm text-text-secondary min-w-[88px]">{t`Portfolio`}</p>
            <TotalPositionsContent />
          </div>
          <div className="flex flex-col gap-1 px-4">
            <p className="text-sm text-text-secondary">{t`Cash`}</p>
            <div
              data-testid="cash-amount"
              className="text-lg md:text-2xl font-bold min-w-[88px]"
            >
              {!proxyWalletAddress ? (
                '$0.00'
              ) : collateralBalance.loading ? (
                <div className="animate-pulse w-full">
                  <div className="h-8 bg-grey-lighter rounded-md"></div>
                </div>
              ) : (
                FormatCurrency(collateralBalance.value, false, 2)
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2">
          <>
            <DepositButton>
              <Button
                data-testid="button-Deposit"
                className={`${isMobile(width) ? 'w-[100%]' : ''}`}
                size={`${isMobile(width) ? 'md' : 'lg'}`}
                variant="dark"
              >
                {t`Deposit`}
              </Button>
            </DepositButton>

            <Button
              data-testid="button-Withdraw"
              onClick={() => {
                openModal({
                  title: 'Withdraw',
                  content: <WithdrawModal />,
                });
              }}
              className={`${isMobile(width) ? 'w-[100%]' : ''}`}
              size={`${isMobile(width) ? 'md' : 'lg'}`}
              variant="blue"
            >
              {t`Withdraw`}
            </Button>
          </>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div className="bg-black-dark px-4 py-6 flex rounded-2xl items-center">
          <div className="flex flex-col ">
            <p className="text-sm text-text-secondary">{t`Positions value`}</p>
            <PositionsValueContent />
          </div>
        </div>
        <div className="bg-black-dark px-4 py-6 flex rounded-2xl items-center">
          <div className="flex flex-col ">
            <p className="text-sm text-text-secondary">{t`Volume Traded`}</p>
            <p className="text-lg md:text-2xl font-bold">
              {FormatCurrency(Number(totalVolumeTraded), false, 2)}
            </p>
          </div>
        </div>
        <div className="bg-black-dark px-4 py-6 flex rounded-2xl items-center">
          <div className="flex flex-col ">
            <p className="text-sm text-text-secondary">{t`Markets Traded`}</p>
            <p className="text-lg md:text-2xl font-bold">
              {totalMarketsTraded}
            </p>
          </div>
        </div>
      </div>
      <Tabs
        noBorder
        onChange={(tab) => {
          console.log(tab);
        }}
        className="bg-black p-2 text-sm"
        activeByDefault={0}
        variant="darkLighter"
        tabs={TABS}
      />
      {/* <div className="md:hidden mt-4  bg-black py-6 px-4 flex gap-2 text-sm fixed bottom-[64px] left-0 w-full ">
        <Button
          size="custom"
          className="w-[100%] h-[100%] min-h-14 py-4 px-8"
          variant="green"
        >
          {t`Claim  Winnings`}
        </Button>
        <div className="mobile-dropdown">
          <TableActionsModal isBottomBar />
        </div>
      </div> */}
      {isLoading && <Spinner />}
    </div>
  );
};
