import { BrowserRouter } from 'react-router-dom';
import { Routes } from '../../routes';
import ModalComponent from '../modal/modal-component';

export const Layout = () => {
  return (
    <BrowserRouter>
      <ModalComponent />
      <Routes />
    </BrowserRouter>
  );
};
