import { useEffect, useRef } from 'react';
import { signInWithEthereum } from '../../helpers/blockchain-helper';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import { useContracts } from '../../context/contracts-context';

export const Login = () => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const {
    data: [{ chain_id }],
  } = useContracts();
  const loginFired = useRef(false);
  useEffect(() => {
    const login = async () => {
      try {
        await signInWithEthereum(t, chain_id, address);
        window.location.href = '/';
      } catch (e) {
        console.log(e);
      }
    };
    if (!loginFired.current) {
      loginFired.current = true;
      login();
    }
  }, []);
  return <></>;
};
