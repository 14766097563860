import { useTranslation } from 'react-i18next';
import {
  getCheckboxIcon,
  getOutcomeColors,
  renderOutcome,
} from '../../helpers/resolved-market-helper';
import { MarketResolvedTo } from '../../types/types';
export const ResolvedMarketCardComponent = ({
  marketResolvedTo,
  outcome1,
  outcome2,
  isMultiMarket = false,
}: {
  marketResolvedTo: MarketResolvedTo;
  outcome1: string;
  outcome2: string;
  isMultiMarket?: boolean;
}) => {
  const { t } = useTranslation();
  const resolvedOutcome = renderOutcome(
    marketResolvedTo,
    outcome1,
    outcome2,
    t
  );
  return (
    <div
      className={`flex gap-1 items-center justify-center ${!isMultiMarket ? 'w-full' : 'min-w-[129px]'} ${getOutcomeColors(resolvedOutcome)} text-sm py-1 px-2 rounded-md h-[24px] self-center justify-self-center text-center`}
    >
      {getCheckboxIcon(resolvedOutcome)}
      {resolvedOutcome}
    </div>
  );
};
