import {
  SelectProps,
  SelectValue,
} from 'react-tailwindcss-select/dist/components/type';
import './dropdown-component.css';
import Select from 'react-tailwindcss-select';
import { useEffect, useRef, useState } from 'react';

interface DropdownProps extends SelectProps {
  border?: boolean;
  className?: string;
  noChevron?: boolean;
  minW?: string;
  bgColor?: string;
  fontSize?: string;
  fontBold?: boolean;
  borderColor?: string;
  optionTextColor?: string;
  ['data-testid']?: string;

}

export const DropdownComponent = ({
  border,
  minW,
  noChevron,
  bgColor,
  fontSize,
  fontBold,
  borderColor = 'border-grey-secondary',
  optionTextColor,
  ...props
}: DropdownProps) => {
  const [selected, setSelected] = useState<SelectValue | undefined>(
    (props.value ?? props.options[0]) as SelectValue | undefined
  );
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectRef.current) {
      // Access the select element within the component and add `data-testid`
      if (props['data-testid'] && selectRef.current) {
        selectRef.current
          .querySelector('div.dropdown')
          ?.setAttribute('data-testid', props['data-testid']);
      }
    }
  }, [selectRef.current]);
  return (
    <div
      onClick={() => {
        setTimeout(() => {
          const isOpened = document.querySelector('.menu.absolute.z-10');
          if (isOpened) {
            isOpened.classList.add('opened');
          }
        });
      }}
      ref={selectRef}
    >
      <Select
        {...props}
        onChange={(value) => {
          if (!noChevron) {
            setSelected(value);
          }
          props.onChange(value);
        }}
        value={selected as SelectValue}
        classNames={{
          menu: `menu absolute z-10 shadow-lg border ${borderColor} ${noChevron ? 'no-chevron rounded-md min-w-[150px] right-0' : 'left-0 rounded-lg'} ${fontSize ?? 'text-sm'} bg-black-light mt-4 ${!noChevron ? minW ?? 'md:min-w-[264px]' : ''} right-0`,
          menuButton: () =>
            `dropdown flex transition-all duration-500 appearance-none ${!noChevron ? `rounded-lg bg-chevron-down bg-[center_right_12px] ${bgColor ?? 'bg-transparent'} bg-no-repeat hover:bg-black-light pr-[30px]` : 'rounded-md bg-black-dark px-2 font-bold text-lg'} py-4 items-center  text-grey-secondary-darker  ${fontSize ?? 'text-sm'} ${fontBold ? 'font-bold' : ''} min-w-[100%]  ${!noChevron ? minW ?? 'md:min-w-[264px]' : ''} max-h-[44px] text-white ${
              border ? `border ${borderColor}` : 'border-0'
            }`,
          listDisabledItem:
            'block transition duration-200 cursor-pointer select-none truncate p-4 text-grey-secondary-darker',
          listItem: (props) => {
            return `block transition duration-200 cursor-pointer select-none truncate p-4 ${
              props?.isSelected
                ? 'text-white bg-grey-light'
                : `${optionTextColor ?? 'text-grey-secondary-darker'} hover:bg-grey-light hover:text-white`
            }`;
          },
        }}
      />
    </div>
  );
};
