import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IMarketPrice {
  outcome1: number;
  outcome2: number;
}

// Define types for context and state
interface MarketPriceContextType {
  marketPrice: IMarketPrice;
  setMarketPrice: (price: IMarketPrice) => void;
  marketAvgPrice: number;
  setMarketAvgPrice: (price: number) => void;
  marketSelectedAmount: number;
  setMarketSelectedAmount: (amount: number) => void;
}

// Create the default context with placeholder values
const MarketPriceContext = createContext<MarketPriceContextType | undefined>(
  undefined
);

// Create a provider component
interface MarketPriceProviderProps {
  children: ReactNode;
}

export const MarketPriceProvider: React.FC<MarketPriceProviderProps> = ({
  children,
}) => {
  const [marketPrice, setMarketPrice] = useState<IMarketPrice>({
    outcome1: 0,
    outcome2: 0,
  }); // Initial price is 0

  const [marketAvgPrice, setMarketAvgPrice] = useState<number>(0); // Initial price is 0
  const [marketSelectedAmount, setMarketSelectedAmount] = useState<number>(0); // Initial price is 0

  // The value provided by the context
  const value = {
    marketPrice,
    setMarketPrice,
    marketAvgPrice,
    setMarketAvgPrice,
    marketSelectedAmount,
    setMarketSelectedAmount,
  };

  return (
    <MarketPriceContext.Provider value={value}>
      {children}
    </MarketPriceContext.Provider>
  );
};

// Custom hook to use the MarketPriceContext
export const useMarketPrice = (): MarketPriceContextType => {
  const context = useContext(MarketPriceContext);
  if (context === undefined) {
    throw new Error('useMarketPrice must be used within a MarketPriceProvider');
  }
  return context;
};
