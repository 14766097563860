import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

export function getElapsedTime(isoDateString: string): string {
  const date = new Date(isoDateString);
  const now = new Date();

  const elapsedMs = now.getTime() - date.getTime();
  const elapsedMinutes = Math.floor(elapsedMs / (1000 * 60));
  const elapsedHours = Math.floor(elapsedMs / (1000 * 60 * 60));
  const elapsedDays = Math.floor(elapsedMs / (1000 * 60 * 60 * 24));
  const elapsedMonths = Math.floor(elapsedDays / 30);
  const elapsedYears = Math.floor(elapsedDays / 365);

  if (elapsedYears > 0) {
    return date.toLocaleString();
  }

  if (elapsedMonths > 11) {
    return `${elapsedYears} year${elapsedYears > 1 ? 's' : ''} ago`;
  }

  if (elapsedMonths >= 1) {
    return `${elapsedMonths} month${elapsedMonths > 1 ? 's' : ''} ago`;
  }

  if (elapsedDays >= 1) {
    return `${elapsedDays} day${elapsedDays > 1 ? 's' : ''} ago`;
  }

  if (elapsedHours >= 1) {
    return `${elapsedHours} hour${elapsedHours > 1 ? 's' : ''} ago`;
  }

  if (elapsedMinutes >= 1) {
    return `${elapsedMinutes} minute${elapsedMinutes > 1 ? 's' : ''} ago`;
  }

  return 'just now';
}

export const getExpirationTimestamp = (
  selectedExpiration: string,
  customTimestamp?: number
) => {
  let expirationTimestamp;

  const now = dayjs();

  switch (selectedExpiration) {
    case 'uc': // Until Cancelled
      expirationTimestamp = 0;
      break;

    case 'eod': // End of Day (UTC)
      expirationTimestamp = now.endOf('day').valueOf();
      break;

    case 'eow': // End of Week (Force Sunday as end)
      // Set the end of the week to Sunday at 23:59:59 UTC
      expirationTimestamp = now
        .add(7 - now.day(), 'day')
        .endOf('day')
        .valueOf();
      break;

    case 'eom': // End of Month (UTC)
      expirationTimestamp = now.endOf('month').valueOf();
      break;

    case 'custom':
      expirationTimestamp = customTimestamp;
      break;

    default:
      expirationTimestamp = 0;
  }

  return expirationTimestamp;
};

export function getRemainingTime(isoDateString: string): string {
  const date = dayjs(isoDateString).utc();
  const now = dayjs().utc();

  const diffInHours = date.diff(now, 'hour');
  const diffInDays = date.diff(now, 'day');

  if (diffInDays >= 1) {
    return `in ${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
  } else if (diffInHours >= 1) {
    return `in ${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
  } else {
    const diffInMinutes = date.diff(now, 'minute');
    if (diffInMinutes >= 1) {
      return `in ${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''}`;
    } else {
      return 'soon';
    }
  }
}