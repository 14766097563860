import { createConfig, http } from 'wagmi';
import { sepolia, localhost, base } from 'wagmi/chains';
import { metaMask } from 'wagmi/connectors';

declare module 'wagmi' {
  interface Register {
    config: typeof config;
  }
}
export const NUMBER_OF_SELECTED_CATEGORIES = 3;

export const config = createConfig({
  chains: [localhost, sepolia],
  connectors: [metaMask()],
  transports: {
    [sepolia.id]: http(
      'https://sepolia.infura.io/v3/c67ec6590f5d4c2daffb9cd4d633db08'
    ),
    [localhost.id]: http('http://127.0.0.1:8545'),
  },
});

export const getBlockTimeForChainInSeconds = (chainId: number) => {
  switch (chainId) {
    case sepolia.id:
      return 12; // Sepolia average block time is around 12 seconds
    case base.id:
      return 2; // Base network has a faster block time, around 2 seconds
    default:
      return 5; // Default value for unknown chains
  }
};
