import { useNavigate } from 'react-router-dom';
import useViewport, { isMobile } from '../../hooks/viewport';
import { Button } from '../button/button-component';
import { ResolvedMarketCardComponent } from './resolved-market-card-component';
import { MarketResolvedTo } from '../../types/types';

export const SingleOutcome = ({
  slug,
  outcome1,
  outcome2,
  conditionId,
  isResolved,
  marketResolvedTo,
}: {
  slug?: string;
  outcome1?: string;
  outcome2?: string;
  conditionId?: string;
  isResolved?: boolean;
  marketResolvedTo?: MarketResolvedTo;
}) => {
  const navigate = useNavigate();
  const { width } = useViewport();
  const mobileView = isMobile(width);

  return (
    <>
      {isResolved && marketResolvedTo ? (
        <ResolvedMarketCardComponent
          marketResolvedTo={marketResolvedTo}
          outcome1={outcome1 as string}
          outcome2={outcome2 as string}
        />
      ) : (
        <div className="grid grid-cols-2 py-8 gap-2">
          <Button
            data-testid="button-category-yes"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              navigate(
                mobileView
                  ? `/mobile-order/${encodeURIComponent(outcome1 ?? '')}`
                  : `/market/${slug}/${encodeURIComponent(outcome1 ?? '')}`,
                {
                  state: mobileView
                    ? {
                        outcome1: outcome1,
                        outcome2: outcome2,
                        conditionId: conditionId,
                      }
                    : {},
                }
              );
            }}
            size="lg"
            variant="green"
          >
            {outcome1}
          </Button>
          <Button
            size="lg"
            data-testid="button-category-no"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              navigate(
                mobileView
                  ? `/mobile-order/${encodeURIComponent(outcome2 ?? '')}`
                  : `/market/${slug}/${encodeURIComponent(outcome2 ?? '')}`,
                {
                  state: mobileView
                    ? {
                        outcome1: outcome1,
                        outcome2: outcome2,
                        conditionId: conditionId,
                      }
                    : {},
                }
              );
            }}
            variant="red"
          >
            {outcome2}
          </Button>
        </div>
      )}
    </>
  );
};
