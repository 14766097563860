import {
  Option,
  SelectValue,
} from 'react-tailwindcss-select/dist/components/type';
import { DropdownComponent } from '../../components/dropdown/dropdown-component';
import { SearchInput } from '../../components/search-input/search-input-component';
import { TableActionsModal } from './TableActionsDropdown';

export const PortfolioTableHeader = ({
  statusOptions,
  datesOptions,
  hasActionsDropdown = true,
  onChange,
  onSearch,
  onStatusChange,
}: {
  onSearch?: (term: string) => void;
  onStatusChange?: (status: string) => void;
  onChange: (value: string) => void;
  statusOptions?: {
    value: string;
    label: string;
  }[];
  datesOptions?: {
    value: string;
    label: string;
  }[];
  hasActionsDropdown?: boolean;
}) => {
  return (
    <div className="font-normal grid grid-cols-2 w-[100%] md:flex md:flex-row md:justify-between md:items-center gap-4">
      <SearchInput
        className="w-[100%] col-span-2 bg-transparent bg-search bg-no-repeat bg-[center_left_12px] pl-[35px]"
        placeholder="Search"
        type="text"
        onChange={({ target: { value } }) => {
          if (onSearch) {
            onSearch(value);
          }
        }}
      />
      {datesOptions && (
        <div>
          <DropdownComponent
            primaryColor=""
            onChange={() => {
              return undefined;
            }}
            border
            value={datesOptions[0]}
            options={datesOptions}
          />
        </div>
      )}
      {statusOptions && (
        <div>
          <DropdownComponent
            primaryColor=""
            onChange={(item: SelectValue) => {
              if (onStatusChange) {
                onStatusChange((item as Option)?.value);
              } else {
                return onChange((item as Option)?.value);
              }
            }}
            border
            value={statusOptions[0]}
            options={statusOptions}
          />
        </div>
      )}
      {/* {hasActionsDropdown && (
        <div className="hidden md:block">
          <TableActionsModal />
        </div>
      )} */}
    </div>
  );
};
