import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route,
  Routes as ReactRoutes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useAccount } from 'wagmi';
import { MarketHeaderComponent } from './components/markets-header/markets-header-component';
import Spinner from './components/spinner/spinner-component';
import { useBlockchain } from './context/blockchain-context';
import { useModal } from './context/modal-context';
import { signInWithEthereum } from './helpers/blockchain-helper';
import restService from './helpers/rest-service-helper';
import { HomePage } from './pages/home/Home';
import { MarketDetailsPage } from './pages/market-details/MarketDetailsPage';
import { MarketOverviewPage } from './pages/market-overview/MarketOverviewPage';
import { Portfolio } from './pages/portfolio/PortfolioPage';
import { MarketParams, User as UserType } from './types/types';
import { watchAccount } from '@wagmi/core';
import { config } from './config';
import { getErrorMessage } from './helpers/error-helper';
import useViewport, { isMobile } from './hooks/viewport';
import { Login } from './pages/login/Login';
import { useContracts } from './context/contracts-context';
import { MobileOrderPage } from './pages/market-details/MobileOrderPage';
import { useWebsocket } from './context/websocket-context';
import { EventsSearchPage } from './pages/market-search/MarketSearchPage';
import { MobileSelectedMarket } from './pages/market-details/mobile-selected-market/mobile-selected-market';
import { isInIframe } from './helpers/iframe-helper';

export const Routes = () => {
  const wagmiAccount = useAccount();
  let address: `0x${string}` | undefined;
  const [isConnected, setIsConnected] = useState(false);
  if (!isInIframe()) {
    address = wagmiAccount.address;
  }
  useEffect(() => {
    if (!isInIframe()) {
      setIsConnected(wagmiAccount.isConnected);
    }
  }, [wagmiAccount.isConnected]);
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const { data, loading } = useContracts();
  const { width } = useViewport();
  const mobileView = isMobile(width);
  const initialMarketParams = {
    page: 1,
    limit: mobileView ? 5 : 12,
    eventStatus: 'ACTIVE',
    sortBy: 'market.createdAt',
  };
  const [marketParams, setMarketParams] =
    useState<MarketParams>(initialMarketParams);
  const { socket, resetBalance } = useWebsocket();
  const {
    evmAddress,
    setProxyWalletAddress,
    setEvmAddress,
    setProxyWalletSetup,
    setShowConfirmDepositHeading,
    setApproveTokensHeading,
    showApproveTokensHeading,
    proxyWalletAddress,
    proxyWalletSetup,
    setUserId,
    setUsername,
    setImageURL,
  } = useBlockchain();
  const { openModal } = useModal();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const hasLoggedInRef = useRef(false); // Ref to track login status
  const location = useLocation();
  const currentAccount = useRef(address);
  useEffect(() => {
    if (!isInIframe()) {
      if (
        isConnected &&
        data?.[0]?.chain_id &&
        !hasLoggedInRef.current &&
        !location.pathname.includes('login')
      ) {
        hasLoggedInRef.current = true; // Set the ref to true at the start of login
        watchAccount(config, {
          onChange(account) {
            if (account.status === 'connected') {
              loginWithEtherium(
                account.address ?? address,
                currentAccount.current !== undefined &&
                  currentAccount.current !== account.address
              );
            }
            currentAccount.current = account.address;
          },
        });
        // login on refresh
        loginWithEtherium(address, false);
      } else if (!isConnected && showApproveTokensHeading) {
        setApproveTokensHeading(false);
      }
    } else if (!hasLoggedInRef.current && isInIframe() && data?.[0]?.chain_id) {
      loginWithJWT().then((loggedIn) => {
        console.log('CONNECTED', loggedIn);
        setIsConnected(loggedIn as boolean);
      });
    }
  }, [isConnected, evmAddress, data?.[0]?.chain_id]);

  useEffect(() => {
    if (proxyWalletAddress && !proxyWalletSetup) {
      setApproveTokensHeading(true);
    } else {
      setApproveTokensHeading(false);
    }
  }, [proxyWalletAddress, proxyWalletSetup, location]);

  const getUser = async () => {
    const user = await restService.get<UserType>('/users');
    if (!user.evmAddress) {
      handleErrorModal('EVM Address is missing');
    }
    if (!user.proxyWalletAddress) {
      console.warn('Proxy wallet address is missing');
    }
    setEvmAddress(user.evmAddress);
    setProxyWalletAddress(user.proxyWalletAddress);
    setProxyWalletSetup(user.proxyWalletSetup);
    setUserId(user.id);
    setUsername(user.name);
    setImageURL(user.imageURL);
    setShowConfirmDepositHeading(false);
    return user;
  };

  const loginWithEtherium = async (
    address?: `0x${string}`,
    accountChanged?: boolean
  ) => {
    setIsLoading(true);
    const signin = async () => {
      const signed = await signInWithEthereum(t, data?.[0]?.chain_id, address);
      await getUser();
      navigate('/');
      if (signed) {
        socket?.disconnect();
        resetBalance();
      }
      closeModal();
    };

    try {
      if (accountChanged) {
        await signin();
      } else {
        try {
          await getUser();
        } catch (e) {
          await signin();
        }
      }
    } catch (e) {
      handleErrorModal(typeof e === 'string' ? e : (e as Error)?.name);
    } finally {
      setIsLoading(false);
    }
  };

  const loginWithJWT = async () => {
    return new Promise((resolve) => {
      window.proxy.addMessageListener(
        'metamarket.JWTTokenRequest',
        async (message) => {
          const JWTToken = message.payload.token;
          window.proxy.setToken(JWTToken);
          console.log('JWTToken', JWTToken);
          try {
            const login = await restService.post('/users/auth/login', {
              token: JWTToken,
            });
            await getUser();
            navigate('/');
            if (login) {
              socket?.disconnect();
              resetBalance();
            }
            closeModal();
            resolve(true);
          } catch (e) {
            console.log('e');
          }
        }
      );

      window.proxy.JWTTokenRequest(data?.[0]?.chain_id);
    });
  };

  const handleErrorModal = (e: string) => {
    openModal({
      content: <p className="text-center">{getErrorMessage(e)}</p>,
      title: 'Error',
      type: 'error',
    });
    setIsLoading(false);
  };

  return (
    <div className="bg-black-light text-white justify-center items-center flex flex-col gap-6 md:gap-0">
      {data?.[0]?.chain_id && (
        <ReactRoutes>
          <Route path="login" element={<Login />} />
          {isConnected && (
            <Route
              path="/"
              element={
                <MarketHeaderComponent
                  marketParams={marketParams}
                  setMarketParams={setMarketParams}
                  login={!isInIframe() ? loginWithEtherium : undefined}
                />
              }
            >
              <Route
                path=""
                element={
                  <HomePage
                    marketParams={marketParams}
                    setMarketParams={setMarketParams}
                  />
                }
              />
              <Route
                path="market-overview/:urlFilter?"
                element={
                  <MarketOverviewPage
                    marketParams={marketParams}
                    setMarketParams={setMarketParams}
                  />
                }
              />
              <Route
                path="mobile-order/:selectedOutcome?/:market?"
                element={<MobileOrderPage />}
              />
              <Route
                path="mobile-market/:selectedOutcome?"
                element={<MobileSelectedMarket />}
              />
              <Route
                path="search"
                element={
                  <EventsSearchPage
                    marketParams={marketParams}
                    setMarketParams={setMarketParams}
                  />
                }
              />
              <Route
                path="market/:slug/:outcome?"
                element={<MarketDetailsPage />}
              />
              <Route path="portfolio" element={<Portfolio />} />
            </Route>
          )}
          <Route path="*" element={<Navigate to="/" replace />} />)
        </ReactRoutes>
      )}
      {(isLoading || loading) && <Spinner />}
    </div>
  );
};
