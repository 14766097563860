import { t } from 'i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { OrderBookGraph } from '../../components/order-book-graph/order-book-graph-component';
import { OrderBookTabs } from '../../components/order-book-tabs-component/order-book-tabs-component';
import { PersonItem } from './PersonItem';
import { History } from './History';
import { Chart } from '../../components/chart/chart-component';
import { Positions } from './Positions';
import { Market, MarketResolvedTo, Order } from '../../types/types';
import React from 'react';
import { useWebsocket } from '../../context/websocket-context';
import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg';
import { Button } from '../../components/button/button-component';
import { useNavigate } from 'react-router-dom';
import { FormatCurrency } from '../../helpers/currency-helper';
import { OpenOrders } from './OpenOrders';
import { ResolvedMarketCardComponent } from '../../components/market-card/resolved-market-card-component';
import { MarketResolution } from '../../components/market-resolution/market-resolution-component';
import { usePositionsData } from '../../hooks/markets-hook';
import restService from '../../helpers/rest-service-helper';
import { useBlockchain } from '../../context/blockchain-context';

export const MultiOutcome = ({
  markets,
  selectedMarket,
  expanded,
  setSelectedMarket,
  setExpanded,
  selectedOutcome,
  setSelectedOutcome,
}: {
  markets: Market[];
  selectedMarket?: Market;
  setSelectedMarket: Dispatch<SetStateAction<Market | undefined>>;
  expanded?: Market;
  selectedOutcome?: string;
  setSelectedOutcome?: Dispatch<SetStateAction<string | undefined>>;
  setExpanded: Dispatch<SetStateAction<Market | undefined>>;
}) => {
  const initialTabValue = selectedMarket?.isResolved ? 'graph' : 'orderBook';
  const [selectedTab, setSelectedTab] = useState<string>(initialTabValue);
  const { sharesByMarketUpdate } = useWebsocket();
  const [marketVisible, setMarketVisible] = useState<string | undefined>(
    expanded?.id
  );
  const [positionsData, setPositionsData] = useState<any[]>([]);
  const [openOrdersData, setOpenOrdersData] = useState<Order[]>([]);
  const { userId } = useBlockchain();

  const [tabs, setTabs] = useState<{
    [key: string]: { id: string; title: string }[];
  }>(() => {
    const initialValue: any = {};
    for (const market of markets) {
      initialValue[market.id] = [
        { id: 'orderBook', title: t`Order Book` },
        // { id: 'openOrders', title: t`Open Orders` },
        // { id: 'graph', title: t`Graph` },
        { id: 'history', title: t`History` },
        { id: 'resolution', title: t`Resolution` },
      ];
    }
    return initialValue;
  });
  useEffect(() => {
    if (
      sharesByMarketUpdate[selectedMarket?.id as string]?.value?.marketId &&
      Math.max(
        +(
          sharesByMarketUpdate[selectedMarket?.id as string]?.value?.token1
            ?.balance ?? 0
        ),
        +(
          sharesByMarketUpdate[selectedMarket?.id as string]?.value?.token2
            ?.balance ?? 0
        )
      ) > 0 &&
      !(tabs[selectedMarket?.id as string] ?? []).find(
        ({ id }) => id === 'positions'
      )
    ) {
      tabs[selectedMarket?.id as string].splice(1, 0, {
        id: 'positions',
        title: t`Positions`,
      });

      setTabs({ ...tabs });
    }
    if (selectedMarket?.isResolved) {
      tabs[selectedMarket?.id] = tabs[selectedMarket?.id].filter(
        (tab) => tab.id !== 'orderBook'
      );
      tabs[selectedMarket?.id] = tabs[selectedMarket?.id].filter(
        (tab) => tab.id !== 'openOrders'
      );
      setTabs({ ...tabs });
    } // remove orderbook tab if the market is resolved

    const fetchData = async () => {
      const data = await restService.get<Order[]>(
        `/orders?market=${selectedMarket?.id}&owner=${userId}&status=PENDING`
      );
      setOpenOrdersData(data);
      if (
        data.length > 0 &&
        !tabs[selectedMarket?.id as string].find(
          (tab) => tab.id === 'openOrders'
        )
      ) {
        tabs[selectedMarket?.id as string].splice(1, 0, {
          id: 'openOrders',
          title: t`Open Orders`,
        });

        setTabs({ ...tabs });
      }
      const positionsData = await usePositionsData(
        1,
        10,
        '',
        '',
        'latest',
        'DESC'
      );
      setPositionsData(positionsData.data);
    };
    if (selectedMarket?.id && userId) {
      fetchData();
    }
  }, [sharesByMarketUpdate, selectedMarket?.id]);

  const navigate = useNavigate();
  return (
    <div>
      {selectedMarket?.id && (
        <div className="bg-black-dark flex px-3 text-white font-bold text-xs w-[100%] h-[44px] items-center justify-between pr-10 rounded-tl-xl rounded-tr-md">
          <p className="w-[70%]">{t`Outcome`}</p>
          <p>
            {selectedMarket.isResolved ? (
              <>{t`Resolved outcome`}</>
            ) : (
              <>%{t`Chance`}</>
            )}
          </p>
        </div>
      )}
      {markets.map((market, key) => (
        <React.Fragment key={`market-${key}`}>
          <>
            <div className="cursor-pointer flex-col gap-2 hidden md:flex">
              <div
                onClick={() => {
                  if (expanded?.id === market.id) {
                    setTimeout(() => {
                      setMarketVisible(undefined); // Hide the element after the transition ends
                    }, 250);

                    return setExpanded(undefined);
                  }
                  setMarketVisible(market.id); // Show the element
                  setExpanded(market);
                  setSelectedMarket(market);
                  setSelectedTab(initialTabValue);
                }}
              >
                <PersonItem
                  market={market}
                  noHeader={selectedMarket?.id !== market.id}
                  className={
                    selectedMarket?.id === market.id
                      ? 'bg-surface-modal'
                      : key % 2 != 0
                        ? 'bg-table-row'
                        : ''
                  }
                  positionsData={positionsData.filter(
                    (data) => data.marketId === market.id
                  )}
                />
              </div>

              <div
                className={`px-2 py-2 origin-top transition-transform duration-200 relative ${marketVisible !== market.id ? 'max-h-0' : ''} ${expanded?.id === market.id ? 'scale-y-100' : 'scale-y-0 overflow-hidden'}`}
              >
                <OrderBookTabs
                  border
                  key={`orderbook-tabs-${expanded?.id}`}
                  tabs={tabs[selectedMarket?.id as string]}
                  onChange={(tab) => {
                    setSelectedTab(tab.id);
                  }}
                />
                <div className="py-4">
                  {selectedTab === 'orderBook' &&
                    setSelectedOutcome &&
                    market.id === expanded?.id && (
                      <OrderBookGraph
                        variant={'multiOutcome'}
                        market={market}
                        selectedOutcome={selectedOutcome}
                        setSelectedOutcome={setSelectedOutcome}
                      />
                    )}
                  {selectedTab === 'positions' &&
                    market.id === expanded?.id && (
                      <Positions
                        variant="multiOutcome"
                        market={expanded as Market}
                        positionsData={positionsData.filter(
                          (data) => data.marketId === expanded.id
                        )}
                      />
                    )}
                  {selectedTab === 'graph' && (
                    <div>
                      <div className=" font-bold my-4">
                        <p className="text-sm text-white">
                          {expanded?.outcome1}
                        </p>
                        <p className="text-lg text-accent">
                          <MarketResolution
                            resolution={
                              market[
                                selectedOutcome === market.outcome1
                                  ? 'outcome1_chance'
                                  : 'outcome2_chance'
                              ] ?? '0'
                            }
                          />{' '}
                          {t`Chance`}
                        </p>
                      </div>
                      <Chart lineColor="#B276FF" />
                    </div>
                  )}
                  {selectedTab === 'history' && market.id === expanded?.id && (
                    <History marketId={expanded?.id} />
                  )}
                  {selectedTab === 'openOrders' &&
                    market.id === expanded?.id && (
                      <OpenOrders
                        orders={openOrdersData}
                        market={selectedMarket as Market}
                      />
                    )}
                </div>
              </div>
            </div>
            <div
              className="my-2 md:hidden bg-black-dark p-4 rounded-lg grid gap-4"
              onClick={() => {
                navigate(
                  `/mobile-market/${encodeURIComponent(selectedOutcome ?? '')}`,
                  { state: market }
                );
              }}
            >
              <div className="flex gap-4 items-center border-b border-grey-secondary py-2">
                <div className="w-full flex gap-4">
                  {market?.image ? (
                    <img
                      className="w-[40px] h-[40px] rounded-lg object-cover"
                      src={market.image}
                    />
                  ) : (
                    <Avatar className="min-w-[40px] min-h-[40px] bg-grey rounded-lg p-2" />
                  )}
                  <div className="flex flex-col gap-2">
                    <p className="text-white font-bold text-sm">
                      {market.name}
                    </p>
                    <p className="text-text-secondary text-sm">
                      $123,213,123 Bet
                    </p>
                    {+sharesByMarketUpdate[market?.id as string]?.value?.token1
                      ?.balance > 0 && (
                      <span className="rounded-md py-[2px] px-2 text-green-light font-bold bg-green-opacity">
                        {market?.outcome1}{' '}
                        {
                          +sharesByMarketUpdate[market?.id as string].value
                            .token1.balance
                        }{' '}
                        -{' '}
                        {FormatCurrency(
                          +sharesByMarketUpdate[market?.id as string].value
                            .token1.balance,
                          false
                        )}
                      </span>
                    )}
                    {+sharesByMarketUpdate[market?.id as string]?.value?.token2
                      ?.balance > 0 && (
                      <span className="rounded-md py-[2px] px-2 bg-red-opacity font-bold text-red-normal">
                        {market?.outcome2}{' '}
                        {
                          +sharesByMarketUpdate[market?.id as string].value
                            .token2.balance
                        }{' '}
                        -{' '}
                        {FormatCurrency(
                          +sharesByMarketUpdate[market?.id as string].value
                            .token2.balance,
                          false
                        )}
                      </span>
                    )}
                  </div>
                </div>

                <p className="text-white font-bold text-lg">
                  <MarketResolution
                    resolution={
                      market[
                        selectedOutcome === market.outcome1
                          ? 'outcome1_chance'
                          : 'outcome2_chance'
                      ] ?? '0'
                    }
                  />{' '}
                </p>
              </div>
              {market.isResolved ? (
                <ResolvedMarketCardComponent
                  marketResolvedTo={market.marketResolvedTo as MarketResolvedTo}
                  outcome1={market.outcome1 as string}
                  outcome2={market.outcome2 as string}
                />
              ) : (
                <div className="flex gap-2 text-sm w-full ">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(
                        `/mobile-order/${encodeURIComponent(market?.outcome1 ?? '')}`,
                        {
                          state: market,
                        }
                      );
                    }}
                    size="custom"
                    className="overflow-hidden whitespace-nowrap text-ellipsis w-[100%] h-[100%] min-h-14 py-4 px-8"
                    variant="green"
                  >
                    {market?.outcome1}
                  </Button>
                  <Button
                    size="custom"
                    className="overflow-hidden whitespace-nowrap text-ellipsis w-[100%] h-[100%] min-h-14 py-4 px-8"
                    variant="red"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(
                        `/mobile-order/${encodeURIComponent(market?.outcome2 ?? '')}`,
                        {
                          state: market,
                        }
                      );
                    }}
                  >
                    {market?.outcome2}
                  </Button>
                </div>
              )}
            </div>
          </>
        </React.Fragment>
      ))}
    </div>
  );
};
