import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Order, OrderbookLoading } from '../types/types';

// Define types for context and state
interface OrderbookContextType {
  buySellOrders: { sell: Order[]; buy: Order[]; all: Order[] };
  setBuySellOrders: (buySellOrders: {
    sell: Order[];
    buy: Order[];
    all: Order[];
  }) => void;
  setOrderbookLoading: (orderbookLoading: OrderbookLoading) => void;
  orderbookLoading: OrderbookLoading;
}

// Create the default context with placeholder values
const OrderbookContext = createContext<OrderbookContextType | undefined>(
  undefined
);

// Create a provider component
interface OrderbookProviderProps {
  children: ReactNode;
}

export const OrderbookProvider: React.FC<OrderbookProviderProps> = ({
  children,
}) => {
  const [buySellOrders, setBuySellOrders] = useState<{
    sell: Order[];
    buy: Order[];
    all: Order[];
  }>({
    sell: [],
    buy: [],
    all: [],
  });
  const [orderbookLoading, setOrderbookLoading] = useState<OrderbookLoading>({
    token1: {
      sell: false,
      buy: false,
    },
    token2: { sell: false, buy: false },
  });
  // The value provided by the context
  const value = {
    buySellOrders,
    setBuySellOrders,
    orderbookLoading,
    setOrderbookLoading,
  };

  return (
    <OrderbookContext.Provider value={value}>
      {children}
    </OrderbookContext.Provider>
  );
};

// Custom hook to use the OrderbookContext
export const useOrders = (): OrderbookContextType => {
  const context = useContext(OrderbookContext);
  if (context === undefined) {
    throw new Error('useOrders must be used within a OrderbookProvider');
  }
  return context;
};
