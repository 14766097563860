export const translations = {
  en: {
    metawin: {
      hello: 'Hello [en]',
      Yes: 'Yes',
      No: 'No',
      Bet: 'Bet',
      Buy: 'Buy',
      Sell: 'Sell',
      Market: 'Market',
      Outcome: 'Outcome',
      Amount: 'Amount',
      Balance: 'Balance',
      'Avg price': 'Avg price',
      Shares: 'Shares',
      'Potential return': 'Potential return',
      'Limit Price': 'Limit Price',
      Share: 'Share',
      Max: 'Max',
      'Your Earnings': 'Your Earnings',
      Position: 'Position',
      'Value per share': 'Value per share',
      'Claim winnings': 'Claim winnings',
      AI: 'AI',
      Gaza: 'Gaza',
      Electronics: 'Electronics',
      'Breaking News': 'Breaking News',
      Top: 'Top',
      'For You': 'For You',
      New: 'New',
      Basketball: 'Basketball',
      'US Election': 'US Election',
      'Predictive Markets': 'Predictive Markets',
      Deposit: 'Deposit',
      Portfolio: 'Portfolio',
      'Bet now': 'Bet now',
      Highlights: 'Highlights',
      'Market Overview': 'Market Overview',
      'View All': 'View All',
      Activity: 'Activity',
      'Back to markets': 'Back to markets',
      'Back to home': 'Back to home',
      Positions: 'Positions',
      'Avg.': 'Avg.',
      Value: 'Value',
      'Total Return': 'Total Return',
      Convert: 'Convert',
      Market2: 'Market2',
      Market3: 'Market3',
      Market4: 'Market4',
      'Order Book': 'Order Book',
      Graph: 'Graph',
      History: 'History',
      Resolution: 'Resolution',
      Chance: 'Chance',
      Rules: 'Rules',
      Trade: 'Trade',
      Price: 'Price',
      Total: 'Total',
      Last: 'Last',
      Spread: 'Spread',
      'Open Orders': 'Open Orders',
      today: 'today',
      Cash: 'Cash',
      Withdraw: 'Withdraw',
      'Volume Traded': 'Volume Traded',
      'Markets Traded': 'Markets Traded',
      'Claim  Winnings': 'Claim  Winnings',
      All: 'All',
      Live: 'Live',
      Ended: 'Ended',
      Latest: 'Latest',
      'Current Value': 'Current Value',
      'Expiration date': 'Expiration date',
      Current: 'Current',
      'To Win': 'To Win',
      'Claim all': 'Claim all',
      'Merge all': 'Merge all ',
      'Deposit USDC': 'Deposit USDC',
      'Send USDC to your Proxy Wallet below':
        'Send USDC to your Proxy Wallet below',
      'Proxy Wallet': 'Proxy Wallet',
      Copy: 'Copy',
      Cancel: 'Cancel',
      'Send USDC to your Proxy Wallet bellow':
        'Send USDC to your Proxy Wallet bellow',
      'Enter your address': 'Enter your address',
      'You withdraw': 'You withdraw',
      MAX: 'MAX',
      USDC: 'USDC',
      Continue: 'Continue',
      'Claim All': 'Claim All',
      Claim: 'Claim',
      'This will {{type}} all market positions for':
        'This will {{type}} all market positions for',
      Name: 'Name',
      'Merge Shares': 'Merge Shares',
      Merge: 'Merge',
      Newest: 'Newest',
      Side: 'Side',
      Filled: 'Filled',
      Expiration: 'Expiration',
      Active: 'Active',
      Type: 'Type',
      Date: 'Date',
      'End Date': 'End Date',
      Any: 'Any',
      'Ends today': 'Ends today',
      'Ends this week': 'Ends this week',
      'Ends this month': 'Ends this month',
      Status: 'Status',
      Resolved: 'Resolved',
      'Sort by': 'Sort by',
      'Nothing in this Category': 'Nothing in this Category',
      'Oops! There are no markets in this category. Try our search or select other category.':
        'Oops! There are no markets in this category. Try our search or select other category.',
      'Sory by': 'Sory by',
      'Clear Filters': 'Clear Filters',
      'To ensure the best user experience and security measures, it\'s essential to set up a proxy wallet when utilising the MetaMarket platform.':
        'To ensure the best user experience and security measures, it\'s essential to set up a proxy wallet when utilising the MetaMarket platform.',
      'Sign in with Ethereum to the app.': 'Sign in with Ethereum to the app.',
      'You can conveniently deposit to and withdraw from your MetaMarket proxy wallet anytime to any other wallet.':
        'You can conveniently deposit to and withdraw from your MetaMarket proxy wallet anytime to any other wallet.',
        'Enable Trading':'Enable Trading',
        'User rejected the request':'User rejected the request',
        'Login':'Login',
        'Setup wallet':'Setup wallet',
        'In order to be able to trade on the platform you need to swap your USDC for USDCe. You can always swap your funds back to USDC upon withdrawal.':'In order to be able to trade on the platform you need to swap your USDC for USDCe. You can always swap your funds back to USDC upon withdrawal.',
        'Confirm Deposit':'Confirm Deposit',
        'Confirm pending deposit':'Confirm pending deposit',
        'Copied!':'Copied!',
        'Your Proxy wallet has been created successfully':'Your Proxy wallet has been created successfully',
        'You are now ready to start trading securely with your USDC':'You are now ready to start trading securely with your USDC',
        'Start trading securely with your USDC on MetaMarket':'Start trading securely with your USDC on MetaMarket',
        'Sign':'Sign',
        'Approve Tokens':'Approve Tokens',
        'Limit':'Limit',
        'Until Cancelled':'Until Cancelled',
        'End of Day':'End of Day',
        'Custom':'Custom',
        'Potential Return':'Potential Return',
        'End of Week':'End of Week',
        'End of Month':'End of Month'
    },
  },
};
