const apiUrl = process.env.REACT_APP_API_HOST; // Replace this with your API URL
interface CustomError {
  error: string;
  message: string[];
  statusCode: number;
}
async function sendRequest<T>(
  endpoint: string,
  method = 'GET',
  body?: unknown,
  headers: any = {}
): Promise<T> {
  const options: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      // Add any additional headers if needed
      ...headers,
    },
  };

  if (body) {
    options.body = JSON.stringify(body, (key, value) =>
      typeof value === 'bigint' ? Number(value) : value
    );
  }

  try {
    const response = await fetch(`${apiUrl}/api/v1${endpoint}`, options);
    if (!response.ok) {
      const error = (await response.json()) as CustomError;
      throw new Error(`${error.message}`);
    }

    // Check if the response is JSON
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return (await response.json()) as T;
    }

    // If not JSON, return response as is
    return response as unknown as T;
  } catch (error: unknown) {
    if ((error as Error).message === 'Forbidden resource') {
      // window.location.href = '/login';
      throw (error as CustomError).message;
    } else if ((error as CustomError).message) {
      throw (error as CustomError).message;
    }
    return { data: [] } as unknown as T;
  }
}

const restService = {
  get: async <T,>(endpoint: string): Promise<T> => sendRequest<T>(endpoint),
  post: async <T,>(
    endpoint: string,
    body?: unknown,
    headers?: unknown
  ): Promise<T> => sendRequest<T>(endpoint, 'POST', body, headers),
  put: async <T,>(endpoint: string, body: unknown): Promise<T> =>
    sendRequest<T>(endpoint, 'PUT', body),
  patch: async <T,>(endpoint: string, body?: unknown): Promise<T> =>
    sendRequest<T>(endpoint, 'PATCH', body),
  delete: async <T,>(endpoint: string): Promise<T> =>
    sendRequest<T>(endpoint, 'DELETE'),
};

export default restService;
