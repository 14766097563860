export const isEthereumAddress = (address: string): boolean => {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
};

export const isTransactionHash = (hash: string): boolean => {
  return /^0x([A-Fa-f0-9]{64})$/.test(hash);
};
export const isEthereumAmount = (amount: string): boolean => {
  const amountNumber = parseFloat(amount);
  return !isNaN(amountNumber) && amountNumber > 0 && amountNumber <= 10 ** 18;
};
