import { useState } from 'react';
import { InputComponent } from '../../../components/input/input-component';
import { ReactComponent as USDC } from '../../../assets/icons/usdc.svg';
import { Button } from '../../../components/button/button-component';
import { useModal } from '../../../context/modal-context';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../components/checkbox/checkbox-component';
import {
  mergePositions,
  splitPosition,
  checkForTxSuccess,
  waitForTransactionCompletion,
} from '../../../helpers/blockchain-helper';
import { useBlockchain } from '../../../context/blockchain-context';
import { useContracts } from '../../../context/contracts-context';
import { parseUnits, TransactionReceipt } from 'viem';
import LoadingDots from '../../../components/loading-dots/loading-dots-component';
import { getTxDetailsUrl } from '../../../helpers/link-helper';

export const SplitOrMergeSharesConfirmTransaction = ({
  amount,
  type,
  conditionId,
  isMultiMarket,
}: {
  type: 'split-shares' | 'merge-shares';
  conditionId?: string;
  amount: number;
  isMultiMarket?: boolean;
}) => {
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const { proxyWalletAddress } = useBlockchain();
  const contracts = useContracts();

  const MULTISEND_ADDRESS = contracts.data.find(
    ({ name }) => name === 'MULTISEND'
  )?.address as string;
  const USDCe_ADDRESS = contracts.data.find(({ name }) => name === 'COLLATERAL')
    ?.address as string;
  const CTF_ADDRESS = contracts.data.find(({ name }) => name === 'CTF')
    ?.address as string;
  const NEG_RISK_ADAPTER = contracts.data.find(
    ({ name }) => name === 'NEG_RISK_ADAPTER'
  )?.address as string;
  const confirmTransaction = async (conditionId?: string) => {
    if (proxyWalletAddress) {
      showProcessingModal();
      let txHash = '';

      try {
        if (type === 'split-shares') {
          txHash = await splitPosition(
            proxyWalletAddress,
            contracts.data[0].chain_id,
            parseUnits(amount.toString(), 6),
            `0x${conditionId}`,
            USDCe_ADDRESS,
            MULTISEND_ADDRESS,
            isMultiMarket ? NEG_RISK_ADAPTER : CTF_ADDRESS
          );
        } else {
          txHash = await mergePositions(
            proxyWalletAddress,
            contracts.data[0].chain_id,
            parseUnits(amount.toString(), 6),
            `0x${conditionId}`,
            USDCe_ADDRESS,
            MULTISEND_ADDRESS,
            isMultiMarket ? NEG_RISK_ADAPTER : CTF_ADDRESS,
            CTF_ADDRESS,
            isMultiMarket
          );
        }

        const receipt: TransactionReceipt = await waitForTransactionCompletion(
          txHash as `0x${string}`,
          1
        );

        if (checkForTxSuccess(receipt, txHash)) {
          showSuccessModal(txHash);
        }
      } catch (e) {
        console.log('e', e);
        showErrorModal(txHash);
      }
    }
  };

  const showProcessingModal = () => {
    openModal({
      content: (
        <div className="text-center">
          <LoadingDots title={t`Transaction in progress`} />
        </div>
      ),
      subTitle: `You will be notified when the ${type === 'split-shares' ? 'split' : 'merge'} is ready`,
      title:
        type === 'split-shares' ? t`Split Processing` : t`Merge Processing`,
      type: 'processing',
    });
  };

  const showSuccessModal = (txHash: string) => {
    openModal({
      content: (
        <>
          <div className="flex flex-col gap-6">
            <p className="text-white text-center">
              {type === 'split-shares'
                ? t`You have successfully split all selected shares.`
                : t`You have successfully merged all matched shares.`}
            </p>
          </div>
          {getTxDetailsUrl(txHash)}
        </>
      ),
      subTitle: '',
      title:
        type === 'split-shares' ? t`Split Successfull` : t`Merge Successfull`,
      type: 'success',
    });
  };
  const showErrorModal = (txHash: string) => {
    openModal({
      content: (
        <>
          <div className="flex flex-col gap-6">
            <p className="text-white text-center">
              {type === 'split-shares'
                ? t`There was an error while splitting shares.`
                : t`There was an error while merging shares.`}
            </p>
          </div>
          {getTxDetailsUrl(txHash)}
        </>
      ),
      subTitle: '',
      title: type === 'split-shares' ? t`Split Failed` : t`Merge Failed`,
      type: 'error',
    });
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="md:grid md:grid-cols-2 md:gap-6">
        {type === 'split-shares' ? (
          <>
            <div>
              <p className="text-white font-bold text-sm mb-2">{t`USDC to be split`}</p>
              <div className="flex gap-2 bg-surface-modal rounded-2xl py-6 px-2 h-[88px] justify-between md:justify-start">
                <div className="flex flex-row items-center gap-2 bg-black p-2 rounded-lg">
                  <USDC />
                  <p className="text-white font-bold text-base">USDC</p>
                </div>
                <InputComponent
                  disabled
                  type="number"
                  textsize="text-2xl"
                  className="text-text-secondary font-bold text-right max-w-[70px] pl-0"
                  noborder="true"
                  value={amount}
                />
              </div>
            </div>
            <div>
              <p className="text-white font-bold text-sm mb-2">{t`Outcome Shares `}</p>
              <div className="flex flex-col gap-2 bg-surface-modal rounded-2xl py-6 px-2 h-[88px]">
                <InputComponent
                  disabled
                  type="number"
                  textsize="text-2xl"
                  value={amount}
                  className="text-text-secondary font-bold text-right"
                  noborder="true"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <p className="text-white font-bold text-sm mb-2">{t`Shares Merged`}</p>
              <div className="flex flex-col gap-2 bg-surface-modal rounded-2xl py-6 px-2 h-[88px]">
                <InputComponent
                  disabled
                  type="number"
                  textsize="text-2xl"
                  value={amount}
                  className="text-text-secondary font-bold text-right"
                  noborder="true"
                />
              </div>
            </div>
            <div>
              <p className="text-white font-bold text-sm mb-2">{t`Received`}</p>
              <div className="flex gap-2 bg-surface-modal rounded-2xl py-6 px-2 h-[88px]">
                <div className="flex flex-row items-center gap-2 bg-black p-2 rounded-lg">
                  <USDC />
                  <p className="text-white font-bold text-base">USDC</p>
                </div>
                <InputComponent
                  disabled
                  type="number"
                  textsize="text-2xl"
                  placeholder="0"
                  className="text-text-secondary font-bold text-right max-w-[70px] pl-0"
                  noborder="true"
                  value={amount}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Checkbox
        id="terms"
        label={
          <>
            {t`By using Metamarket you agree with our `}
            <a
              target="_blank"
              className="text-water-blue"
              href=""
            >{t`Terms of Use`}</a>
            {t` and `}
            <a
              target="_blank"
              className="text-water-blue"
              href=""
            >{t`Privacy Policy`}</a>
          </>
        }
        onFilterChange={(checked) => {
          setCheckboxChecked(checked);
        }}
      />
      <div className="flex flex-col gap-2">
        <Button
          className="w-[100%]"
          onClick={async () => await confirmTransaction(conditionId)}
          variant="blue"
          disabled={!checkboxChecked}
        >
          {t`Continue`}
        </Button>
        <Button
          onClick={() => {
            closeModal();
          }}
          className="w-[100%]"
          variant="darkOpacity"
        >
          {t`Cancel`}
        </Button>
      </div>
    </div>
  );
};
