export const TABS_MOCK = [
  { 'data-testid': 'button-filter-All-markets', title: 'All', content: <></> },
  { 'data-testid': 'button-filter-New-markets', title: 'New', content: <></> },
  {
    'data-testid': 'button-filter-Ended-markets',
    title: 'Ended',
    content: <></>,
  },
  {
    'data-testid': 'button-filter-Volume-markets',
    title: 'Volume',
    content: <></>,
  },
  {
    'data-testid': 'button-filter-Liquidity-markets',
    title: 'Liquidity',
    content: <></>,
  },
];
