import React, {
  useState,
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import { Button } from '../button/button-component';
import { ReactComponent as Danger } from '../../assets/icons/danger-icon.svg';
import { useTranslation } from 'react-i18next';
import { useWebsocket } from '../../context/websocket-context';
import { FormatCurrency } from '../../helpers/currency-helper';

interface MarketInputProps {
  amount?: boolean;
  step?: number;
  limitAmount?: boolean;
  useDecimals?: boolean;
  setIsValid?: Dispatch<SetStateAction<boolean>>;
  onChange?: (value: number) => void;
  showMaxButton?: boolean;
  limitPrice?: number;
  balanceType?: 'shares' | 'collateral';
  orderType?: 'buy' | 'sell';
  showAvailableBalance?: boolean;
  marketId: string;
  defaultValue?: number;
  selectedToken?: string;
}

const MarketInput: React.FC<MarketInputProps> = ({
  step = 10,
  setIsValid,
  onChange,
  showMaxButton,
  useDecimals,
  limitAmount,
  limitPrice,
  orderType,
  balanceType = 'collateral',
  showAvailableBalance,
  marketId,
  defaultValue,
  selectedToken,
}) => {
  const { collateralBalance, sharesByMarketUpdate } = useWebsocket();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(
    useDecimals ? '0.0' : '0'
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState({
    amount: '',
  });
  const calculateInputWidth = () => {
    const length = inputValue.length || 1;
    return `${(length + 1) * 10}px`;
  };

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue.toFixed(0).toString());
    }
  }, [defaultValue]);
  useEffect(() => {
    validateAmount(+inputValue);
  }, [balanceType]);

  const inputStyle: CSSProperties = {
    minWidth: '10px', // Set a minimum width
    width: calculateInputWidth(), // Adjust width based on input length
  };

  const getBalance = (): number => {
    if (balanceType === 'collateral' || (limitPrice && orderType === 'buy')) {
      return +collateralBalance.value;
    } else {
      if (selectedToken === 'token1') {
        return +(sharesByMarketUpdate[marketId]?.value?.token1?.balance ?? 0);
      } else if (selectedToken === 'token2') {
        return +(sharesByMarketUpdate[marketId]?.value?.token2?.balance ?? 0);
      } else {
        return Math.min(
          +(sharesByMarketUpdate[marketId]?.value?.token1?.balance ?? 0),
          +(sharesByMarketUpdate[marketId]?.value?.token2?.balance ?? 0)
        );
      }
    }
  };
  useEffect(() => {
    if (onChange) {
      onChange(+inputValue);
    }
    validateAmount(+inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (limitPrice) {
      validateAmount(+inputValue);
    }
  }, [limitPrice]);

  const validateAmount = (newValue: number) => {
    if (setIsValid) setIsValid(false);
    if (
      limitPrice &&
      orderType === 'sell' &&
      newValue >
        +(
          sharesByMarketUpdate[marketId]?.value?.[
            selectedToken === 'token1' ? 'token1' : 'token2'
          ]?.balance ?? 0
        )
    ) {
      setErrors({
        amount: t`Insufficient Balance`,
      });
    } else if (
      limitPrice &&
      newValue * limitPrice > getBalance() &&
      !limitAmount
    ) {
      setErrors({
        amount: t`Insufficient Balance`,
      });
    } else if (+newValue > getBalance() && !limitPrice && !limitAmount) {
      setErrors({
        amount: t`Insufficient Balance`,
      });
    } else {
      setErrors({ amount: '' });
      if (setIsValid) setIsValid(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    if (limitAmount) {
      // Allow only integers from 0 to 99
      if (/^\d{0,2}$/.test(value)) {
        setInputValue(value);
        validateAmount(+value);
      }
    } else {
      if (value.startsWith('00')) return;
      if (/^\d*\.?\d*$/.test(value)) {
        // Handle cases where the number starts with "0"
        if (value === '0' || value === '0.') {
          setInputValue(value);
        } else if (
          value.startsWith('0') &&
          !value.startsWith('0.') &&
          !value.includes('.')
        ) {
          setInputValue('0.' + value.slice(1));
        } else {
          // Limit the number of decimal places to 6
          if (value.includes('.')) {
            const [integerPart, decimalPart] = value.split('.');
            if (decimalPart.length > 6) {
              value = `${integerPart}.${decimalPart.slice(0, 6)}`;
            }
          }
          setInputValue(value);
        }
      } else if (value === '.') {
        setInputValue('0.');
      }
      validateAmount(+value);
    }
  };
  const getLimitPriceValue = () => {
    if (limitPrice === undefined || limitPrice === 0) {
      return 0;
    }

    const value =
      orderType === 'buy' ? collateralBalance.value / limitPrice : getBalance();

    return parseFloat(value.toFixed(6));
  };

  const handleIncrement = () => {
    let newValue = (parseFloat(inputValue) || 0) + step;
    if (limitAmount) {
      newValue = Math.min(Math.max(newValue, 0.1), 99.9);
    } else {
      newValue = Math.min(newValue, 10000000);
    }
    newValue = +newValue.toFixed(2);
    validateAmount(newValue);
    setInputValue(newValue.toString());
  };

  const handleDecrement = () => {
    let newValue = (parseFloat(inputValue) || 0) - step;
    if (newValue > 0) {
      if (limitAmount) {
        newValue = Math.max(newValue, 0.1);
      } else {
        newValue = Math.max(newValue, 0.1);
      }
      newValue = +newValue.toFixed(2);
      validateAmount(newValue);
      setInputValue(newValue.toString());
    } else {
      setInputValue('0');
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {showMaxButton && (
        <div className="flex justify-between text-white font-bold">
          <>
            <p>{balanceType === 'collateral' ? t`Amount` : t`Shares`}</p>
            <Button
              data-testid="button-max"
              onClick={() => {
                setInputValue(
                  balanceType === 'collateral'
                    ? getBalance().toFixed(6)
                    : (limitPrice
                        ? getLimitPriceValue()
                        : getBalance()
                      ).toFixed(6)
                );
              }}
              size="sm"
            >{t`Max`}</Button>
          </>
        </div>
      )}
      {showAvailableBalance && (
        <div className="flex justify-end">
          <Button
            variant="darkBlack"
            onClick={() => {
              setInputValue(
                balanceType === 'collateral'
                  ? getBalance().toFixed(6)
                  : (limitPrice ? getLimitPriceValue() : getBalance()).toFixed(
                      6
                    )
              );
            }}
            size="sm"
          >
            {t`Available `}
            {balanceType === 'collateral'
              ? FormatCurrency(getBalance(), false, 2)
              : getBalance()}{' '}
            <span className="text-water-blue">{t`Max`}</span>
          </Button>
        </div>
      )}
      <div
        className={`text-white font-bold bg-black-dark rounded-xl flex justify-between p-4 px-1 h-[52px] items-center ${errors.amount ? 'border border-red-error' : ''}`}
      >
        <Button
          data-testid="button-decrement"
          size="custom"
          className="min-w-[36px] min-h-[36px]"
          onClick={handleDecrement}
          variant="dark"
        >
          -
        </Button>
        <div
          className="flex items-center justify-center h-full w-full cursor-text"
          onClick={() => inputRef.current?.focus()}
        >
          {balanceType === 'collateral' && !limitAmount && (
            <p className="text-center">$</p>
          )}

          <input
            data-testid="input-market-value"
            style={inputStyle}
            ref={inputRef}
            onFocus={() => {
              if (inputRef.current?.value === '0') {
                inputRef.current.value = '';
              }
            }}
            onChange={handleChange}
            value={inputValue}
            className="appearance-none m-0 bg-black-dark h-full text-white border-0 active:border-0 focus:border-0 outline-none text-center"
            type="text"
          />
          {balanceType === 'collateral' && limitAmount && (
            <p className="text-center">¢</p>
          )}
        </div>
        <Button
          data-testid="button-increment"
          className="min-w-[36px] min-h-[36px]"
          size="custom"
          onClick={handleIncrement}
          variant="dark"
        >
          +
        </Button>
      </div>
      <div
        className={`text-xs md:flex  text-red-error items-center gap-1 ${errors.amount ? 'visible flex' : 'invisible hidden'}`}
      >
        <Danger width={16} height={16} />
        {errors.amount}
      </div>
    </div>
  );
};

export default MarketInput;
