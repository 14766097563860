import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success-icon.svg';
import { MarketResolvedTo } from '../../types/types';
import { renderOutcome } from '../../helpers/resolved-market-helper';
import { Button } from '../button/button-component';
import { useModal } from '../../context/modal-context';
import { Dispatch, SetStateAction } from 'react';
import Spinner from '../spinner/spinner-component';
import { useWebsocket } from '../../context/websocket-context';
import {
  checkForTxSuccess,
  redeemPositions,
  waitForTransactionCompletion,
} from '../../helpers/blockchain-helper';
import { useContracts } from '../../context/contracts-context';
import { useBlockchain } from '../../context/blockchain-context';
import { FormatCurrency } from '../../helpers/currency-helper';
import './resolved-market.component.css';
import { TransactionReceipt } from 'viem';
import { getTxDetailsUrl } from '../../helpers/link-helper';
export const ResolvedMarket = ({
  marketResolvedTo,
  marketId,
  conditionId,
  outcome1,
  outcome2,
  isLoading,
  setIsLoading,
  isMultiMarket,
}: {
  marketId: string;
  conditionId?: string;
  marketResolvedTo: MarketResolvedTo;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  outcome1?: string;
  outcome2?: string;
  isMultiMarket?: boolean;
}) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { sharesByMarketUpdate } = useWebsocket();
  const contracts = useContracts();
  const MULTISEND_ADDRESS = contracts.data.find(
    ({ name }) => name === 'MULTISEND'
  )?.address as string;
  const USDCe_ADDRESS = contracts.data.find(({ name }) => name === 'COLLATERAL')
    ?.address as string;
  const CTF_ADDRESS = contracts.data.find(({ name }) => name === 'CTF')
    ?.address as string;
  const NEG_RISK_ADAPTER = contracts.data.find(
    ({ name }) => name === 'NEG_RISK_ADAPTER'
  )?.address as string;
  const { proxyWalletAddress } = useBlockchain();
  return (
    <>
      {isLoading && <Spinner />}

      <div className="flex flex-col gap-6 resolved-market-card">
        <div className="flex flex-col gap-4 justify-center items-center border-b border-grey-secondary pb-3">
          <SuccessIcon
            className={`${
              +sharesByMarketUpdate[marketId]?.value?.[
                `token${marketResolvedTo === outcome1 ? '1' : '2'}`
              ]?.balance > 0
                ? 'green'
                : ''
            }`}
          />
          <p className="text-white font-bold text-2xl ">
            {outcome1 && outcome2 && (
              <>
                {t`Outcome: `}{' '}
                {renderOutcome(marketResolvedTo, outcome1, outcome2, t)}
              </>
            )}
          </p>
        </div>
        {Math.max(
          +(sharesByMarketUpdate[marketId]?.value?.token1?.balance ?? 0),
          +(sharesByMarketUpdate[marketId]?.value?.token2?.balance ?? 0)
        ) > 0 && (
          <>
            <p className="text-white text-center font-bold">{t`Your Position`}</p>
            <div className="flex justify-between">
              <p className="text-text-secondary text-sm">{t`Position`}</p>
              <p className="text-white text-sm">
                {
                  sharesByMarketUpdate[marketId]?.value?.[
                    `token${marketResolvedTo === outcome1 ? '1' : '2'}`
                  ]?.balance
                }
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-text-secondary text-sm">{t`Value per share`}</p>
              <p className="text-white text-sm">{FormatCurrency(1, false)}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-text-secondary text-sm">{t`Total`}</p>
              <p
                className={`${
                  +sharesByMarketUpdate[marketId]?.value?.[
                    `token${marketResolvedTo === outcome1 ? '1' : '2'}`
                  ]?.balance > 0
                    ? 'text-green-light'
                    : 'text-white'
                } text-sm`}
              >
                {FormatCurrency(
                  +sharesByMarketUpdate[marketId]?.value?.[
                    `token${marketResolvedTo === outcome1 ? '1' : '2'}`
                  ]?.balance,
                  false
                )}
              </p>
            </div>
            <Button
              onClick={async () => {
                setIsLoading(true);
                let txHash = '';
                try {
                  txHash = await redeemPositions(
                    proxyWalletAddress as string,
                    isMultiMarket ? NEG_RISK_ADAPTER : CTF_ADDRESS,
                    contracts.data[0].chain_id,
                    USDCe_ADDRESS,
                    MULTISEND_ADDRESS,
                    marketId,
                    conditionId,
                    isMultiMarket,
                    [
                      sharesByMarketUpdate[marketId]?.value?.token1?.balance,
                      sharesByMarketUpdate[marketId]?.value?.token2?.balance,
                    ]
                  );
                  const receipt: TransactionReceipt =
                    await waitForTransactionCompletion(
                      txHash as `0x${string}`,
                      1
                    );
                  if (checkForTxSuccess(receipt, txHash)) {
                    openModal({
                      content: (
                        <>
                          <p className="text-center">{t`Your position has been settled successfully!`}</p>
                          {getTxDetailsUrl(txHash)}
                        </>
                      ),
                      title: 'Settle successfull',
                      type: 'success',
                    });
                  }
                } catch (e) {
                  openModal({
                    content: (
                      <p className="text-center">
                        {typeof e === 'string' ? e : (e as Error)?.message}
                        {getTxDetailsUrl(txHash)}
                      </p>
                    ),
                    title: 'Error',
                    type: 'error',
                  });
                } finally {
                  setIsLoading(false);
                }
              }}
              className="w-full "
              value={'blue'}
            >{t`Settle Position`}</Button>
          </>
        )}
      </div>
    </>
  );
};
