import { Dispatch, SetStateAction } from 'react';
import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { FormatCurrency } from '../../helpers/currency-helper';
import { variants } from '../button/button-component';
import { useTranslation } from 'react-i18next';
import './event-card-component.css';
import { Market } from '../../types/types';
import { ExpandedBet } from './expanded-bet';
import { MultiOutcome } from './multi-outcome';
import { SingleOutcome } from './single-outcome';

export interface CardProps {
  slug?: string;
  title: string;
  image?: string;
  totalBet: number;
  variant: 'multiOutcome' | 'singleOutcome' | 'expanded';
  buyMode?: string;
  noHeader?: boolean;
  className?: string;
  setSelectedOutcome?: Dispatch<SetStateAction<string | undefined>>;
  selectedOutcome?: string;
  conditionId?: string;
  single?: boolean;
  markets?: Market[];
}

export const EventCard = ({
  slug,
  title,
  image,
  totalBet,
  variant,
  noHeader,
  className,
  setSelectedOutcome,
  selectedOutcome,
  conditionId,
  single,
  markets,
}: CardProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${single && markets?.[0]?.isResolved && markets?.[0]?.marketResolvedTo ? 'grid' : ''} rounded-2xl p-4 ${
        variant === 'expanded' ? 'bg-black-light' : 'bg-black-dark'
      } ${variant !== 'expanded' ? 'h-[251px] max-h-[251px]' : ''} text-sm text-grey-secondary-darker max-w-[412px] mx-auto mb-6 md:mb-0 ${className ?? ''}`}
    >
      {!noHeader && (
        <div className="flex text-white text-base self-start font-bold mb-4 items-center">
          {!image ? (
            <div className="rounded-xl bg-grey p-2">
              <Avatar className="w-[32px]" />
            </div>
          ) : (
            <img
              src={image}
              className="w-[48px] h-[48px] rounded-xl object-cover"
            />
          )}
          <div className="ml-3 line-clamp-2">{title}</div>
        </div>
      )}

      <>
        {variant === 'expanded' && markets?.[0] && selectedOutcome ? (
          <ExpandedBet
            setSelectedOutcome={setSelectedOutcome}
            selectedOutcome={selectedOutcome}
            market={markets?.[0]}
            conditionId={conditionId}
            isMultiMarket={!single}
          />
        ) : (
          <>
            {!single && <MultiOutcome eventSlug={slug} markets={markets} />}
            {single && (
              <SingleOutcome
                slug={slug}
                outcome1={markets?.[0]?.outcome1}
                outcome2={markets?.[0]?.outcome2}
                isResolved={markets?.[0]?.isResolved}
                marketResolvedTo={markets?.[0]?.marketResolvedTo}
                conditionId={conditionId}
              />
            )}
          </>
        )}
      </>
      {variant !== 'expanded' && (
        <div className="self-end w-full border-white-opacity border-t flex pt-2.5 justify-between">
          <p>
            {FormatCurrency(totalBet, false, 2)} {t`Bet`}
          </p>
          <p
            className={`cursor-pointer plus-icon p-1 rounded-md ${variants(false).dark}`}
          >
            <Plus className="w-4 h-4" />
          </p>
        </div>
      )}
    </div>
  );
};
