import { ExpandableComponent } from '../../components/expandable/expandable-component';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/button/button-component';
import { ReactComponent as X } from '../../assets/icons/close-white.svg';
import { Market, Order, PaginatedResponse } from '../../types/types';
import CustomTable from '../../components/custom-table/custom-table-component';
import { useModal } from '../../context/modal-context';
import { PortfolioTableHeader } from './PortfolioTableHeader';
import { CancelOrderModal } from './CancelOrderModal';
import restService from '../../helpers/rest-service-helper';
import { useBlockchain } from '../../context/blockchain-context';
import { FormatCurrency } from '../../helpers/currency-helper';
import { getRemainingTime } from '../../helpers/date-helper';
import { NavLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

interface OrderTableData extends Order {
  marketData: Market;
}

export const OpenOrders = () => {
  const [tableKey, setTableKey] = useState('initialKey');
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { userId } = useBlockchain();
  const [openOrders, setOpenOrders] =
    useState<PaginatedResponse<OrderTableData>>();

  const [page, setPage] = useState(1);
  const [term, setTerm] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const fetchData = async (page: number, rowsPerPage: number) => {
    const orders = await restService.get<PaginatedResponse<Order>>(
      `/orders/paginated?owner=${userId}&status=PENDING&limit=${rowsPerPage}&page=${page}&term=${term}&sortBy=${sortBy}&order=desc`
    );
    const data: OrderTableData[] = [];
    const marketIds = orders.data
      .map((order) => order.market)
      .filter((marketId, index, self) => self.indexOf(marketId) === index);
    if (marketIds.length > 0) {
      const fetchedMarkets = await restService.get<Market[]>(
        `/markets?ids=${marketIds}`
      );

      for (const order of orders.data) {
        data.push({
          ...order,
          marketData: fetchedMarkets.find(
            (market) => market.id === order.market
          ) as Market,
        });
      }
    }

    return { ...orders, data };
  };

  useEffect(() => {
    const fetchAndUpdateOpenOrders = async () => {
      try {
        const orders = await fetchData(page, 10);
        const isFirstPage = page === 1;

        const updatedData = isFirstPage
          ? orders.data
          : [...(openOrders?.data ?? []), ...orders.data];

        setOpenOrders({
          ...orders,
          data: updatedData,
        });
      } catch (error) {
        console.log('Error fetching open orders data:', error);
      }
    };

    fetchAndUpdateOpenOrders();
  }, [page, term, sortBy]);

  return (
    <div className="flex flex-col w-[100%] gap-4 mt-4">
      {openOrders?.data && (
        <>
          <PortfolioTableHeader
            onChange={(value) => {
              console.log('value', value);
            }}
            onStatusChange={(value) => {
              setSortBy(value);
              setPage(1);
            }}
            onSearch={(term) => {
              setTerm(term);
              setPage(1);
            }}
            statusOptions={[
              {
                value: 'created_at',
                label: t`Newest`,
              },
              {
                value: 'total',
                label: t`Value`,
              },
              {
                value: 'filled',
                label: t`Filled`,
              },
              {
                value: 'amount',
                label: t`Quantity`,
              },
            ]}
            hasActionsDropdown={false}
          />
          <div className="hidden md:block">
            {userId && (
              <CustomTable
                tableKey={tableKey}
                setTableKey={setTableKey}
                rowClasses="h-[81px]"
                emptyState={
                  <tbody>
                    <tr>
                      <td className="py-6 text-center" colSpan={8}>
                        <div className="grid gap-2">
                          <p className="text-white text-base font-bold">{t`No Open Orders were found.`}</p>
                          <p className="text-text-secondary text-sm">{t`When a limit order is placed, it will be displayed here.`}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                }
                columns={[
                  {
                    id: 'market',
                    label: t('Market'),
                    render: (item) => (
                      <NavLink
                        className="flex gap-4 items-center"
                        to={`/market/${item?.marketData?.slug}/${!item?.marketData?.event?.single ? item?.marketData?.id : ''}`}
                      >
                        <div className="max-w-10  h-10">
                          <img
                            className="rounded-lg object-cover w-[100%] h-[100%]"
                            src={item?.marketData.image}
                          />
                        </div>
                        {item?.marketData.name}
                      </NavLink>
                    ),
                  },
                  {
                    id: 'side',
                    label: t('Side'),
                    render: (order) => (
                      <div>{order?.side === 1 ? t`Sell` : t`Buy`}</div>
                    ),
                  },
                  {
                    id: 'outcome',
                    label: t('Outcome'),
                    render: (item) => {
                      return (
                        <span
                          className={`p-2 rounded-md ${item?.outcome?.toLowerCase() === 'no' ? 'text-red-error bg-red-opacity' : 'text-green-light bg-green-opacity'}`}
                        >
                          {item?.outcome}
                        </span>
                      );
                    },
                  },
                  {
                    id: 'price',
                    label: t('Price'),
                    type: 'currency',
                    render: (order) => {
                      return `${Math.floor(order?.price * 100)}¢`;
                    },
                  },
                  {
                    id: 'filled',
                    label: t('Filled'),
                    render: (order) =>
                      `${order?.matchedAmount.toFixed(2)}/${order?.amount.toFixed(2)}`,
                  },
                  {
                    id: 'total',
                    label: t('Total'),
                    render: (order) =>
                      FormatCurrency(order?.amount * order?.price, false, 2),
                  },
                  {
                    id: 'expiration',
                    label: t('Expiration'),
                    render: (order) =>
                      `${
                        order?.expiration
                          ? getRemainingTime(
                              new Date(order?.expiration).toISOString()
                            )
                          : t`Until Canceled`
                      }`,
                  },
                  {
                    id: 'cancel',
                    label: 'Cancel',
                    render: (order) => (
                      <Button
                        onClick={() => {
                          openModal({
                            title: 'Cancel Order?',
                            content: (
                              <CancelOrderModal
                                orderId={order?.id}
                                title={order?.marketData?.name}
                                image={order?.marketData?.image}
                              />
                            ),
                          });
                        }}
                        variant="red"
                      >
                        <X />
                      </Button>
                    ),
                  },
                ]}
                hook={(page: number, rowsPerPage: number) => {
                  if (page > 1) {
                    return fetchData(page, rowsPerPage);
                  } else {
                    return new Promise((resolve) =>
                      resolve(openOrders)
                    ) as Promise<PaginatedResponse<Order>>;
                  }
                }}
              />
            )}
          </div>
          {userId && (
            <>
              {openOrders.data.length === 0 ? (
                <div className="py-6 text-center block md:hidden">
                  <div className="grid gap-2">
                    <p className="text-white text-base font-bold">{t`No Open Orders were found.`}</p>
                    <p className="text-text-secondary text-sm">{t`When a limit order is placed, it will be displayed here.`}</p>
                  </div>
                </div>
              ) : (
                <div
                  id="scrollableContainer"
                  style={{
                    overflowY: 'auto',
                  }}
                >
                  <InfiniteScroll
                    scrollableTarget="scrollableContainer"
                    dataLength={(openOrders.data ?? []).length}
                    next={() => setPage(page + 1)}
                    hasMore={openOrders?.last !== openOrders?.total}
                    loader={<></>}
                    className="bg-black-dark md:hidden flex flex-col gap-2 rounded-2xl p-4"
                  >
                    {openOrders.data.map((item, key) => (
                      <ExpandableComponent
                        key={`open-order-${item.id}-${item.salt}-${item.hash}-${key}`}
                        title={item?.marketData?.name}
                        logo={item?.marketData.image as string}
                        items={
                          <NavLink
                            className=""
                            to={`/market/${item?.marketData?.slug}/${!item?.marketData?.event?.single ? item?.marketData?.id : ''}`}
                          >
                            <div className="flex justify-between pb-4 px-4 items-center">
                              <span>
                                {item.side === 1 ? t`Sell` : t`Buy`}{' '}
                                {item.outcome}
                              </span>
                              <span>
                                {item?.matchedAmount.toFixed(2)}/
                                {item?.amount.toFixed(2)}
                              </span>
                            </div>
                            <div className="flex justify-between pb-4 px-4 items-center text-text-secondary">
                              <span>
                                {FormatCurrency(item.price, false, 2)}
                              </span>
                              <span>
                                {t`Expiration: `}
                                {item?.expiration
                                  ? getRemainingTime(
                                      new Date(item?.expiration).toISOString()
                                    )
                                  : t`Until Canceled`}
                              </span>
                            </div>
                          </NavLink>
                        }
                        expandedItems={
                          <>
                            <div className="flex justify-between items-center">
                              <p className="text-text-secondary">{t`Total Order`}</p>
                              <p>
                                {FormatCurrency(
                                  item?.amount * item?.price,
                                  false,
                                  2
                                )}
                              </p>
                            </div>
                            <Button
                              onClick={() => {
                                openModal({
                                  title: 'Cancel Order?',
                                  content: (
                                    <CancelOrderModal
                                      orderId={item?.id}
                                      title={item?.marketData?.name}
                                      image={item?.marketData?.image}
                                    />
                                  ),
                                });
                              }}
                              variant="blue"
                            >{t`Cancel Order`}</Button>
                          </>
                        }
                      />
                    ))}
                  </InfiniteScroll>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
