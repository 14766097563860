import { TFunction } from 'i18next';
import { MarketParams } from '../types/types';

export const marketHeadingFilters = (
  urlFilter: string,
  t: TFunction,
  marketParams?: MarketParams
) => [
  {
    isOpenedByDefault: !urlFilter,
    title: t`End Date`,
    items: [
      {
        label: t`Any`,
        value: 'any',
        id: 'end-date-any',
        defaultChecked: !urlFilter,
      },
      {
        label: t`Ends today`,
        value: 'TODAY',
        id: 'end-date-today',
      },
      {
        label: t`Ends this week`,
        value: 'THIS_WEEK',
        id: 'end-date-this-week',
      },
      {
        label: t`Ends this month`,
        value: 'THIS_MONTH',
        id: 'end-date-this-month',
      },
    ],
    name: 'ends',
  },
  {
    title: t`Status`,
    isOpenedByDefault: urlFilter === 'ended',
    items: [
      {
        label: t`Active`,
        value: 'ACTIVE',
        id: 'status-active',
        defaultChecked:
          marketParams?.resolved !== 'TRUE' && urlFilter !== 'ended',
      },
      {
        label: t`Resolved`,
        value: 'RESOLVED',
        id: 'status-resolved',
        defaultChecked: urlFilter === 'ended',
      },
    ],
    name: 'eventStatus',
  },
  {
    title: t`Sory by`,
    name: 'sortBy',
    isOpenedByDefault: urlFilter === 'new',
    items: [
      { label: t`Name`, value: 'market.name', id: 'sort-name' },
      {
        label: t`Date`,
        value: 'market.createdAt',
        id: 'sort-date',
        defaultChecked: urlFilter === 'new',
      },
    ],
  },
];
