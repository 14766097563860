import React from 'react';
import './spinner-component.css';

interface SpinnerProps {
  size?: number;
  color?: string;
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({
  size = 40,
  color = '#ffffff',
  className = '',
}) => {
  return (
    <div className={`spinner-container z-50 ${className}`}>
      <div
        className="spinner"
        style={{
          width: size,
          height: size,
          border: `4px solid ${color}`,
          borderColor: `${color} transparent ${color} transparent`,
        }}
      ></div>
    </div>
  );
};

export default Spinner;
