import React, { InputHTMLAttributes, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { InputComponent } from '../input/input-component';

export const SearchInput = (props: InputHTMLAttributes<HTMLInputElement>) => {
  const { onChange, ...restProps } = props;

  // Create a debounced version of the onChange handler
  const debouncedOnChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
    }, 300), // Adjust the delay (in milliseconds) as needed
    [onChange]
  );

  // Cleanup debounce on component unmount
  React.useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  return (
    <InputComponent
      {...restProps}
      onChange={debouncedOnChange}
      className={`bg-transparent bg-search bg-no-repeat bg-[center_left_12px] pl-[35px] ${
        props.className ?? ''
      }`}
      type="text"
    />
  );
};
