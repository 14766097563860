import { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  noborder?: string;
  textsize?: string;
}

export const InputComponent = (props: InputProps) => {
  return (
    <input
      {...props}
      className={`md:mt-0 ${props.textsize ?? 'text-sm'} bg-transparent max-h-[44px] p-4 text-grey-secondary-darker rounded-lg ${!props.noborder ? 'border border-grey-secondary' : ''} focus:outline-none ${
        props.className ?? ''
      }`}
    />
  );
};
