import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/button/button-component';
import MarketInput from '../../../components/market-input/market-input-component';
import { useModal } from '../../../context/modal-context';
import { useState } from 'react';
import { useWebsocket } from '../../../context/websocket-context';
import { SplitOrMergeSharesConfirmTransaction } from './SplitOrMergeSharesConfirmTransactionModal';

export const SplitOrMergeSharesModal = ({
  type,
  conditionId,
  isMultiMarket,
  marketId,
  selectedToken = 'token1',
}: {
  type: 'split-shares' | 'merge-shares';
  conditionId?: string;
  isMultiMarket?: boolean;
  marketId: string;
  selectedToken?: 'token1' | 'token2';
}) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const [amount, setAmount] = useState(0);
  const { collateralBalance, sharesByMarketUpdate } = useWebsocket();
  const balance =
    type === 'split-shares'
      ? collateralBalance.value
      : parseFloat(
          sharesByMarketUpdate[marketId]?.value?.[selectedToken]?.balance
        ) ?? 0;
  return (
    <div className="flex flex-col gap-6">
      <p className="text-text-secondary text-base text-center">
        {type === 'split-shares'
          ? t`Split a USDC into a share of Yes and No.`
          : t`Merge shares from both sides in order to receive 1 USDC`}
      </p>
      <MarketInput
        amount
        showAvailableBalance
        onChange={(amount) => setAmount(amount)}
        balanceType={type === 'merge-shares' ? 'shares' : 'collateral'}
        marketId={marketId}
      />
      <Button
        variant="blue"
        disabled={amount <= 0 || amount > balance}
        onClick={() => {
          openModal({
            content: (
              <SplitOrMergeSharesConfirmTransaction
                type={type}
                conditionId={conditionId}
                amount={amount}
                isMultiMarket={isMultiMarket}
              />
            ),
            title: t`Confirm Transaction`,
          });
        }}
      >
        {type === 'split-shares' ? t`Split Shares` : t`Merge Shares`}
      </Button>
    </div>
  );
};
