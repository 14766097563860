import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useBlockchain } from '../../context/blockchain-context';
import { OpenModal, useModal } from '../../context/modal-context';
import { useWebsocket, WsSubEvent } from '../../context/websocket-context';
import { FormatCurrency } from '../../helpers/currency-helper';
import { SplitOrMergeSharesModal } from '../../pages/market-details/split-merge-shares/SplitOrMergeSharesModal';
import {
  Market,
  Order,
  OrderbookLoading,
  OrderType,
  Type,
} from '../../types/types';
import { Button } from '../button/button-component';
import { DropdownComponent } from '../dropdown/dropdown-component';
import MarketInput from '../market-input/market-input-component';
import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg';
import { Option } from 'react-tailwindcss-select/dist/components/type';
import { ReactComponent as Gear } from '../../assets/icons/gear.svg';
import { createOrder } from '../../helpers/blockchain-helper';
import { useContracts } from '../../context/contracts-context';
import { ZeroAddress } from '../../constants/contracts';
import { parseUnits, formatUnits } from 'viem';
import { Decimal } from 'decimal.js';
import { useOrders } from '../../context/orderbook-context';
import { getErrorMessage } from '../../helpers/error-helper';
import {
  createOpositeOrders,
  getOrderbookLoading,
  getOrders,
  groupOrdersByPrice,
  markeBuySellOrders,
} from '../order-book-graph/orders-hook';
import { getExpirationTimestamp } from '../../helpers/date-helper';
import { CustomDatePickerModal } from '../calendar/calendar-modal';
import { calcSum } from '../../helpers/calculate-helper';

const TOKEN_DECIMALS = 6;

interface IOrderAmounts {
  makerAmount: bigint;
  takerAmount: bigint;
}

const getMakerAndTakerAmountForLimitOrder = (
  type: string,
  sharesAmount: number,
  limitPrice: number
): IOrderAmounts => {
  let makerAmount = BigInt(0);
  let takerAmount = BigInt(0);
  if (type === 'buy') {
    makerAmount = BigInt(
      Decimal.mul(Decimal.mul(sharesAmount, limitPrice), 10 ** TOKEN_DECIMALS)
        .ceil()
        .toString()
    );
    takerAmount = parseUnits(sharesAmount.toString(), TOKEN_DECIMALS);
  } else {
    makerAmount = parseUnits(sharesAmount.toString(), TOKEN_DECIMALS);
    takerAmount = BigInt(
      Decimal.mul(Decimal.mul(sharesAmount, limitPrice), 10 ** TOKEN_DECIMALS)
        .floor()
        .toString()
    );
  }

  return {
    makerAmount,
    takerAmount,
  };
};

const getMakerAndTakerAmountForMarketOrder = (
  type: string, // `buy` || `sell`
  buySellOrders: {
    sell: Order[];
    buy: Order[];
    all: Order[];
  },
  marketOrderAmount: number // collateral amount to spend on the market if buy, outcome tokens if sell
): IOrderAmounts => {
  const orders = [
    ...(type === 'sell' ? buySellOrders.buy : buySellOrders.sell),
  ].sort((a: Order, b: Order) => a.price - b.price); // asc
  if (type === 'sell') {
    orders.reverse();
  }

  console.log(
    `calculating maker and taker amounts for ${type} against orders`,
    orders
  );

  let makerAmount = BigInt(0);
  let takerAmount = BigInt(0);

  if (!orders.length) {
    return {
      makerAmount,
      takerAmount,
    };
  }

  if (type === 'buy') {
    // search for the highest sell price in potentially matched orders
    // needed to fill the spent collateral
    let price = 0;
    let collateralLeft = marketOrderAmount;
    for (const order of orders) {
      if (collateralLeft <= 0) {
        break;
      }

      price = order.price;
      const orderTokensLeft = order.amount - order.matchedAmount;
      collateralLeft -= price * orderTokensLeft;
    }

    if (+price > 0 && marketOrderAmount > 0) {
      makerAmount = parseUnits(marketOrderAmount.toString(), TOKEN_DECIMALS);
      takerAmount = BigInt(
        Decimal.mul(Decimal.div(marketOrderAmount, price), 10 ** TOKEN_DECIMALS)
          .floor()
          .toString()
      );
    }
  } else {
    // search for lowest buy price in potentially matched orders
    // needed to fill the spent outcome tokens
    let price = 0;
    let outcomeTokensLeft = marketOrderAmount;
    for (const order of orders) {
      if (outcomeTokensLeft <= 0) {
        break;
      }

      price = order.price;
      const orderTokensLeft = order.amount - order.matchedAmount;
      outcomeTokensLeft -= orderTokensLeft;
    }

    if (price > 0) {
      makerAmount = parseUnits(marketOrderAmount.toString(), TOKEN_DECIMALS);
      takerAmount = BigInt(
        Decimal.mul(Decimal.mul(marketOrderAmount, price), 10 ** TOKEN_DECIMALS)
          .floor()
          .toString()
      );
    }
  }

  return {
    makerAmount,
    takerAmount,
  };
};

const checkOrderbookIsLoading = async (
  market: Market,
  openModal: OpenModal,
  showOrderSuccessModal: boolean,
  userId: string
) => {
  const executingOrdersData = await getOrders(market, 'EXECUTING', userId);
  const loading: OrderbookLoading = {
    token1: {
      sell: false,
      buy: false,
    },
    token2: { sell: false, buy: false },
  };
  loading.token1 = getOrderbookLoading(
    executingOrdersData.orders1,
    market.tokenId1
  );
  loading.token2 = getOrderbookLoading(
    executingOrdersData.orders2,
    market.tokenId2
  );
  if (
    !loading.token1.buy &&
    !loading.token1.sell &&
    !loading.token2.buy &&
    !loading.token2.sell &&
    showOrderSuccessModal
  ) {
    openModal({
      content: (
        <div className="flex flex-col gap-6">
          <p className="text-white text-center">
            You have successfully made order
          </p>
        </div>
      ),
      subTitle: '',
      title: 'Order Successfull',
      type: 'success',
    });
  }
  return loading;
};

export const ExpandedBet = ({
  setSelectedOutcome,
  selectedOutcome,
  conditionId,
  isMultiMarket,
  market,
}: {
  setSelectedOutcome?: Dispatch<SetStateAction<string | undefined>>;
  selectedOutcome: string;
  conditionId?: string;
  isMultiMarket?: boolean;
  market: Market;
}) => {
  const { t } = useTranslation();
  const [orderType, setOrderType] = useState<OrderType>('market');
  const [type, setType] = useState<Type>('buy');
  const { proxyWalletAddress, proxyWalletSetup, evmAddress, userId } =
    useBlockchain();
  const [limitPrice, setLimitPrice] = useState<number>(0);
  const [sharesAmount, setSharesAmount] = useState<number>(0);
  const { openModal } = useModal();
  const { collateralBalance, sharesByMarketUpdate, socket } = useWebsocket();
  const location = useLocation();
  const { pathname } = location;
  const contracts = useContracts();
  const NEG_RISK_CTF_EXCHANGE = contracts.data.find(
    ({ name }) => name === 'NEG_RISK_CTF_EXCHANGE'
  )?.address as `0x${string}`;
  const CTF_EXCHANGE = contracts.data.find(
    ({ name }) => name === 'CTF_EXCHANGE'
  )?.address as `0x${string}`;
  const {
    data: [{ chain_id }],
  } = useContracts();
  const [marketAvgPrice, setMarketAvgPrice] = useState(0);
  const [marketOrderAmount, setMarketOrderAmount] = useState(0);
  const [estimatedAmount, setEsitmatedAmount] = useState(0);
  const [potentialSharesBuy, setPotentialSharesBuy] = useState(0);
  const [potentialReturnPercentage, setPotentialReturnPercentage] = useState(0);
  const [potentialReturn, setPotentialReturn] = useState(0);
  const {
    setBuySellOrders,
    buySellOrders,
    setOrderbookLoading,
    orderbookLoading,
  } = useOrders();
  const [orders, setOrders] = useState<Order[]>([]);
  const ordersFetched = useRef(false);
  const socketListenersCalled = useRef(false);
  const [showOrderSuccessModal, setShowOrderSuccessModal] = useState(false);
  const [marketPrice, setMarketPrice] = useState<{
    outcome1: number;
    outcome2: number;
  }>({ outcome1: 0, outcome2: 0 });
  const [selectedExpiration, setSelectedExpiration] = useState<{
    type: Option;
    customTimestamp?: number;
  }>({
    type: {
      value: 'uc',
      label: 'Until Cancelled',
    },
    customTimestamp: 0,
  });

  const ordersRef = useRef<Order[]>([]);
  const serverResponseRef = useRef<Order[]>([]);
  const orderTypes = [
    {
      value: 'market',
      label: t`Market`,
      'data-testid': 'dropdown-option-market',
    },
    {
      value: 'limit',
      label: t`Limit`,
      'data-testid': 'dropdown-option-limit',
    },
  ];
  let wsCreatedOrdersTimeouts: {
    orderId: string;
    timeoutId: NodeJS.Timeout;
    matched: boolean;
  }[] = [];
  useEffect(() => {
    if (buySellOrders.all.length > 0) {
      const { makerAmount, takerAmount } = getMakerAndTakerAmountForMarketOrder(
        type,
        { ...buySellOrders },
        marketOrderAmount
      );
      if (type === 'sell' && orderType === 'market') {
        setEsitmatedAmount(
          parseFloat(formatUnits(makerAmount, TOKEN_DECIMALS))
        );
      } else if (type === 'buy' && orderType === 'market') {
        // outcome tokens to receive
        const shares = parseFloat(formatUnits(takerAmount, TOKEN_DECIMALS));
        setPotentialSharesBuy(parseFloat(shares.toFixed(2)));
        setPotentialReturn(shares);
        setPotentialReturnPercentage(
          ((shares - marketOrderAmount) / marketOrderAmount) * 100
        );
      }
    }
  }, [type, buySellOrders, marketOrderAmount]);
  const fetchData = async () => {
    ordersFetched.current = true;

    const { orders1, orders2, data } = await getOrders(market, 'PENDING');
    serverResponseRef.current = data;
    ordersRef.current = [
      ...groupOrdersByPrice(orders1),
      ...groupOrdersByPrice(orders2),
    ];
    setOrders([...ordersRef.current]);
  };
  useEffect(() => {
    if (socket && !socketListenersCalled.current && ordersFetched.current) {
      socketListenersCalled.current = true;
      socket.on(WsSubEvent.ORDERBOOK_ORDER_CREATE, (payload: Order) => {
        console.log('WsSubEvent.ORDERBOOK_ORDR_CREATE', payload);
        if (
          payload &&
          !serverResponseRef.current.find((order) => order.id === payload.id)
        ) {
          const timeoutId = setTimeout(() => {
            console.log('wsCreatedOrdersTimeouts', wsCreatedOrdersTimeouts);
            if (
              wsCreatedOrdersTimeouts.find(
                (order) => order.orderId === payload.id
              )?.matched === false
            ) {
              const indexToRemove = wsCreatedOrdersTimeouts.findIndex(
                (order) => order.orderId === payload.id
              );
              if (indexToRemove !== -1) {
                wsCreatedOrdersTimeouts.splice(indexToRemove, 1);
              }
              serverResponseRef.current = [
                ...serverResponseRef.current,
                { ...payload, highlightTimestamp: new Date().getTime() },
              ];
              const { orders1, orders2 } = createOpositeOrders(
                [...serverResponseRef.current].map((order) => ({
                  ...order,
                  amount_left: Decimal.sub(
                    order.amount,
                    order.matchedAmount
                  ).toNumber(),
                })),
                market.tokenId1,
                market.tokenId2
              );

              ordersRef.current = [
                ...groupOrdersByPrice(orders1),
                ...groupOrdersByPrice(orders2),
              ];
              setOrders([...ordersRef.current]);
            }
          }, 20000);

          wsCreatedOrdersTimeouts.push({
            orderId: payload.id,
            timeoutId,
            matched: false,
          });
        }
      });
      socket.on(WsSubEvent.ORDERBOOK_ORDER_DELETE, (payload: Order) => {
        console.log('WsSubEvent.ORDERBOOK_ORDER_DELETE', payload);
        if (payload) {
          ordersRef.current = [...ordersRef.current].filter(
            (order) => order.id !== payload.id
          );
          setOrders([...ordersRef.current]);
        }
      });
      socket.on(WsSubEvent.ORDERBOOK_ORDER_UPDATE, (payload: Order) => {
        if (payload) {
          if (payload.status !== 'PENDING') {
            wsCreatedOrdersTimeouts = [
              ...wsCreatedOrdersTimeouts.map((order) => {
                return order.orderId === payload.id
                  ? {
                      ...order,
                      matched: true,
                    }
                  : order;
              }),
            ];
            serverResponseRef.current = [...serverResponseRef.current].filter(
              (order) => order.id !== payload.id
            );
            console.log('wsCreatedOrdersTimeouts', wsCreatedOrdersTimeouts);
          } else {
            serverResponseRef.current = [
              ...serverResponseRef.current.map((order) => {
                return order.id === payload.id
                  ? {
                      ...payload,
                      highlightTimestamp: new Date().getTime(),
                    }
                  : order;
              }),
            ];
          }
          console.log('WsSubEvent.ORDERBOOK_ORDER_UPDATE', payload);

          const { orders1, orders2 } = createOpositeOrders(
            [...serverResponseRef.current].map((order) => ({
              ...order,
              amount_left: Decimal.sub(
                order.amount,
                order.matchedAmount
              ).toNumber(),
            })),
            market.tokenId1,
            market.tokenId2
          );

          ordersRef.current = [
            ...groupOrdersByPrice(orders1),
            ...groupOrdersByPrice(orders2),
          ];
          setOrders([...ordersRef.current]);
        }
      });
    }
    if (!ordersFetched.current) {
      fetchData();
    }
  }, [orders]);

  useEffect(() => {
    if (
      orderbookLoading.token1.buy ||
      orderbookLoading.token1.sell ||
      orderbookLoading.token2.buy ||
      orderbookLoading.token2.sell
    ) {
      setTimeout(async () => {
        const loading = await checkOrderbookIsLoading(
          market,
          openModal,
          showOrderSuccessModal,
          userId as string
        );
        setOrderbookLoading(loading);
      }, 5000);
    }
  }, [orderbookLoading]);

  useEffect(() => {
    if (orders.length > 0) {
      const data = markeBuySellOrders([...orders], market);

      const maxComulativeVolume = Math.max(
        calcSum(
          data[selectedOutcome]?.buyOrdersData.map((order) => order.amount_left)
        ),
        calcSum(
          data[selectedOutcome]?.sellOrdersData.map(
            (order) => order.amount_left
          )
        )
      );
      setBuySellOrders({
        buy: (data[selectedOutcome]
          ? data[selectedOutcome].buyOrdersData
          : []
        ).map((order) => ({
          ...order,
          barWidth: (order.comulativeShares / maxComulativeVolume) * 100,
        })),
        sell: (data[selectedOutcome]
          ? data[selectedOutcome].sellOrdersData
          : []
        ).map((order) => ({
          ...order,
          barWidth: (order.comulativeShares / maxComulativeVolume) * 100,
        })),
        all: [...orders],
      });
    }
  }, [selectedOutcome, orders]);

  useEffect(() => {
    if (buySellOrders.all.length > 0) {
      const outcome1Orders = buySellOrders.all
        .filter((order) => order.side === (type === 'buy' ? 1 : 0))
        .filter((order) => order.tokenId === market.tokenId1);

      const outcome2Orders = buySellOrders.all
        .filter((order) => order.side === (type === 'buy' ? 1 : 0))
        .filter((order) => order.tokenId === market.tokenId2);
      setMarketPrice({
        outcome1:
          type === 'buy'
            ? outcome1Orders?.[outcome1Orders.length - 1]?.price
            : outcome1Orders?.[0]?.price,
        outcome2:
          type === 'buy'
            ? outcome2Orders?.[outcome2Orders.length - 1]?.price
            : outcome2Orders?.[0]?.price,
      });
      let avgOrders = [
        ...(buySellOrders?.[type === 'sell' ? 'buy' : 'sell'] ?? []),
      ];
      if (type === 'buy') {
        avgOrders = avgOrders.reverse();
      }
      const avgOrder = avgOrders.find(
        (order) => order.total && order.total >= marketOrderAmount
      );

      setMarketAvgPrice(parseFloat(((avgOrder?.price ?? 0) * 100).toFixed(2)));
    }
  }, [buySellOrders, type]);

  const expirationOptions = [
    {
      value: 'uc',
      label: t`Until Cancelled`,
      'data-testid': 'expiration-until-cancelled',
    },
    {
      value: 'eod',
      label: t`End of Day`,
      'data-testid': 'expiration-end-of-day',
    },
    {
      value: 'eow',
      label: t`End of Week`,
    },
    {
      value: 'eom',
      label: t`End of Month`,
      'data-testid': 'expiration-end-of-week',
    },
    {
      value: 'custom',
      label:
        selectedExpiration.type.value === 'custom' &&
        selectedExpiration.customTimestamp
          ? t`Custom: ` +
            `${new Date(selectedExpiration.customTimestamp).toLocaleDateString('en-GB')}`
          : t`Custom`,
      'data-testid': 'expiration-custom',
    },
  ];

  const buyOptions = [
    {
      value: '',
      label: '...',
    },
    {
      value: 'merge-shares',
      disabled:
        +sharesByMarketUpdate[market.id]?.value?.[
          selectedOutcome === market.outcome1 ? 'token1' : 'token2'
        ]?.balance <= 0 ||
        (!proxyWalletAddress && !proxyWalletSetup),
      label: 'Merge Shares',
    },
    {
      value: 'split-shares',
      disabled:
        !collateralBalance.value || !proxyWalletAddress || !proxyWalletSetup,
      label: 'Split Shares',
    },
  ];
  const [isValid, setIsValid] = useState(true);

  const BuyButtions = () => (
    <div className="flex gap-2 items-center">
      {(type === 'buy' || type === 'sell') && (
        <Button
          data-testid="button-buy-shares"
          disabled={
            !isValid ||
            !proxyWalletAddress ||
            !proxyWalletSetup ||
            (orderType === 'market' &&
              (type === 'sell' ? buySellOrders.buy : buySellOrders.sell)
                .length === 0) ||
            (orderType === 'market' && marketOrderAmount === 0) ||
            (orderType === 'limit' &&
              (limitPrice === 0 || sharesAmount === 0)) ||
            orderbookLoading.token1.buy ||
            orderbookLoading.token1.sell ||
            orderbookLoading.token2.buy ||
            orderbookLoading.token2.sell
          }
          className="w-full"
          onClick={async () => {
            if (orderType === 'limit' && (!sharesAmount || !limitPrice)) {
              return;
            }
            if (orderType === 'market' && marketOrderAmount <= 0) {
              return;
            }
            if (proxyWalletAddress && evmAddress) {
              try {
                const { takerAmount, makerAmount } =
                  orderType === 'limit'
                    ? getMakerAndTakerAmountForLimitOrder(
                        type,
                        sharesAmount,
                        limitPrice
                      )
                    : getMakerAndTakerAmountForMarketOrder(
                        type,
                        buySellOrders,
                        marketOrderAmount
                      );
                const expirationTimestamp = getExpirationTimestamp(
                  selectedExpiration.type.value,
                  selectedExpiration.customTimestamp
                );

                await createOrder(
                  {
                    salt: Date.now().toString(),
                    maker: proxyWalletAddress,
                    signer: evmAddress.toLowerCase(),
                    taker: ZeroAddress,
                    tokenId:
                      selectedOutcome === market.outcome1
                        ? market.tokenId1
                        : market.tokenId2,
                    takerAmount,
                    makerAmount,
                    expiration: expirationTimestamp,
                    // Nonces are a way for an user to invalidate all previously placed pending orders
                    // by calling incrementNonce on the exchange.
                    // Ideally we would want to query the current user nonce from the contract before making the order
                    // but for now we take for granted this won't be used and just add the default - 0
                    nonce: 0,
                    feeRateBps: 0,
                    side: type === 'buy' ? 0 : 1,
                    signatureType: 2,
                  } as any,
                  chain_id,
                  market.id,
                  isMultiMarket ? NEG_RISK_CTF_EXCHANGE : CTF_EXCHANGE
                );
                if (orderType !== 'limit') {
                  setShowOrderSuccessModal(true);
                  setTimeout(async () => {
                    const loading = await checkOrderbookIsLoading(
                      market,
                      openModal,
                      true,
                      userId as string
                    );
                    setOrderbookLoading(loading);
                  }, 1000);
                } else {
                  openModal({
                    content: (
                      <div className="flex flex-col gap-6">
                        <p className="text-white text-center">
                          {t`You have successfully made order`}
                        </p>
                      </div>
                    ),
                    subTitle: '',
                    title: 'Order Successfull',
                    type: 'success',
                  });
                }
              } catch (e) {
                const balanceValue = Number(collateralBalance.value ?? 0);
                openModal({
                  content: (
                    <div className="flex flex-col gap-6">
                      <p className="text-white text-center">
                        {getErrorMessage((e as Error)?.name)
                          ? getErrorMessage((e as Error)?.name)
                          : type === 'buy'
                            ? t`Your Buy limit orders for this market are exceeding your available USDC balance of $` +
                              `${balanceValue.toFixed(2)}` +
                              t`. Please review your Open Orders.`
                            : type === 'sell'
                              ? t`Your Sell limit orders for this market are exceeding your available shares for outcome "` +
                                `${selectedOutcome}` +
                                t`". Please review your Open Orders.`
                              : null}
                      </p>
                    </div>
                  ),
                  subTitle: '',
                  title: t`Order Error`,
                  type: 'error',
                });
              }
            }
          }}
        >
          {orderbookLoading.token1.buy ||
          orderbookLoading.token1.sell ||
          orderbookLoading.token2.buy ||
          orderbookLoading.token2.sell ? (
            <>
              <div
                className="spinner m-auto"
                style={{
                  width: 24,
                  height: 24,
                  border: '4px solid #fff',
                  borderColor: '#fff transparent #fff transparent',
                }}
              ></div>
            </>
          ) : (
            <>{type === 'buy' ? t`Buy` : t`Sell`}</>
          )}
        </Button>
      )}
      <div>
        <DropdownComponent
          data-testid="dropdown-order-type"
          primaryColor=""
          noChevron
          optionTextColor="text-white"
          onChange={(selectedValue) => {
            const { value } = selectedValue as Option;
            if (proxyWalletAddress && proxyWalletSetup) {
              if (value === 'split-shares' || value === 'merge-shares') {
                openModal({
                  content: (
                    <SplitOrMergeSharesModal
                      conditionId={conditionId}
                      marketId={market.id}
                      type={value}
                      isMultiMarket={isMultiMarket}
                      selectedToken={
                        selectedOutcome === market.outcome1
                          ? 'token1'
                          : 'token2'
                      }
                    />
                  ),
                  title:
                    value === 'split-shares'
                      ? t`Split Shares`
                      : t`Merge Shares`,
                });
              }
            }
          }}
          value={buyOptions[0]}
          options={buyOptions}
        />
      </div>
    </div>
  );

  const OrderFooterSection = ({ border }: { border?: boolean }) => (
    <div
      className={`${border ? 'border border-grey-secondary rounded-lg' : ''} md:p-0 p-6 gap-2 md:gap-4 flex flex-col`}
    >
      {type === 'buy' && orderType === 'market' && (
        <>
          <div className="flex justify-between text-sm text-text-secondary">
            <p>{t`Avg price`}</p>
            <p className="text-xs text-accent" data-testid="market-avg-price">
              {marketAvgPrice}¢
            </p>
          </div>
          <div className="flex justify-between text-sm text-text-secondary">
            <p>{t`Shares`}</p>
            <p
              className="text-white text-xs text-accent"
              data-testid="market-shares"
            >
              {potentialSharesBuy}
            </p>
          </div>
          <div className="flex justify-between text-sm text-text-secondary">
            <p>{t`Potential Return`}</p>
            <p
              className="text-green-light text-xs"
              data-testid="market-potential-return"
            >
              {FormatCurrency(potentialReturn, false, 2)}(
              {potentialReturnPercentage > 0
                ? potentialReturnPercentage.toFixed(2)
                : 0}
              %)
            </p>
          </div>
        </>
      )}
      {type === 'buy' && orderType === 'limit' && (
        <>
          <div className="flex justify-between text-sm">
            <p className="text-grey-secondary-darker">{t`Total`}</p>
            <p className="text-white text-xs text-accent">
              <>{FormatCurrency(limitPrice * sharesAmount, false, 2)}</>
            </p>
          </div>
          <div className="flex justify-between text-sm">
            <p className="text-grey-secondary-darker">{t`Potential Return`}</p>
            <p className="text-green-light text-xs">
              <>
                {FormatCurrency(sharesAmount, false, 2)}(
                {(
                  ((sharesAmount - limitPrice * sharesAmount) /
                    (limitPrice * sharesAmount === 0
                      ? 1
                      : limitPrice * sharesAmount)) *
                  100
                ).toFixed(2)}
                %)
              </>
            </p>
          </div>
        </>
      )}
      {type === 'sell' && orderType === 'market' && (
        <>
          <div className="flex justify-between text-sm">
            <p className="text-grey-secondary-darker">{t`Avg price`}</p>
            <p className="text-xs text-accent">{marketAvgPrice}¢</p>
          </div>
          <div className="flex justify-between text-sm">
            <p
              className="text-grey-secondary-darker"
              data-testid="market-est-amount"
            >{t`Est. amount`}</p>
            <p className="text-xs text-white">
              {FormatCurrency(estimatedAmount, false, 2)}
            </p>
          </div>
        </>
      )}
      {type === 'sell' && orderType === 'limit' && (
        <>
          <div className="flex justify-between text-sm">
            <p className="text-grey-secondary-darker">{t`Total`}</p>
            <p className="text-white text-xs text-accent">
              {FormatCurrency(limitPrice * sharesAmount, false, 2)}
            </p>
          </div>
        </>
      )}
    </div>
  );

  const handleCustomExpiration = () => {
    openModal({
      content: (
        <CustomDatePickerModal
          onApply={(date) => {
            const unixTimestamp = date.getTime(); // Already adjusted to end of day
            const formattedLabel = `Custom: ${date.toLocaleDateString('en-GB')}`;

            setSelectedExpiration({
              type: {
                value: 'custom',
                label: formattedLabel,
              },
              customTimestamp: unixTimestamp,
            });
          }}
        />
      ),
    });
  };

  return (
    <div className="gap-6 md:gap-4 flex flex-col text-sm md:text-base">
      {market.name && (
        <div className="gap-4 flex items-center">
          {market.image ? (
            <img
              className="w-[48px] h-[48px] rounded-lg object-cover"
              src={market.image}
            />
          ) : (
            <Avatar className="min-w-[48px] min-h-[48px] bg-grey rounded-lg p-2" />
          )}{' '}
          <div>
            <p className="text-white">{market.name}</p>
          </div>
        </div>
      )}

      <div className="bg-black-dark rounded-xl p-2 flex items-center justify-between">
        <div className="">
          <Button
            data-testid="button-order-type-buy"
            onClick={() => setType('buy')}
            className={`h-full ${type !== 'buy' ? 'text-grey-secondary-darker' : ''} `}
            variant={type !== 'buy' ? 'transparent' : 'dark'}
          >
            {t`Buy`}
          </Button>
          <Button
            data-testid="button-order-type-sell"
            onClick={() => setType('sell')}
            className={`h-full ${type !== 'sell' ? 'text-grey-secondary-darker' : ''}`}
            variant={type !== 'sell' ? 'transparent' : 'dark'}
          >
            {t`Sell`}
          </Button>
        </div>
        <div>
          <DropdownComponent
            primaryColor=""
            onChange={(item) => {
              setOrderType((item as { value: OrderType })?.value);
            }}
            value={
              orderTypes.find(({ value }) => value === orderType) ??
              orderTypes[0]
            }
            options={orderTypes}
            minW="md:min-w-auto"
          ></DropdownComponent>
        </div>
      </div>
      <>
        <div className="flex justify-between items-center">
          <p className="text-white font-bold">{t`Outcome`}</p>
          <Gear />
        </div>
        <div className="md:hidden">
          <span className="bg-green-opacity rounded-md py-[2px] px-2 text-green-light">
            {selectedOutcome} - 50¢
          </span>
        </div>
        <div className="grid-cols-2 gap-2 hidden md:grid">
          <Button
            data-testid="button-outcome-yes"
            noWordBreak
            onClick={() =>
              setSelectedOutcome && setSelectedOutcome(market.outcome1)
            }
            style={{ width: '100%' }}
            size="lg"
            variant={
              selectedOutcome !== market.outcome1 ? 'darkLighter' : 'green'
            }
          >
            <div className="whitespace-nowrap break-all overflow-hidden text-ellipsis">
              {market.outcome1}
            </div>
            {marketPrice.outcome1 > 0 && (
              <span className="pl-1">
                {Math.floor(marketPrice.outcome1 * 100)}¢
              </span>
            )}
          </Button>
          <Button
            data-testid="button-outcome-no"
            noWordBreak
            onClick={() =>
              setSelectedOutcome && setSelectedOutcome(market.outcome2)
            }
            style={{ width: '100%' }}
            size="lg"
            variant={
              selectedOutcome !== market.outcome2 ? 'darkLighter' : 'red'
            }
          >
            <div className="whitespace-nowrap break-all overflow-hidden text-ellipsis">
              {market.outcome2}
            </div>
            {marketPrice.outcome2 > 0 && (
              <span className="pl-1">
                {Math.floor(marketPrice.outcome2 * 100)}¢
              </span>
            )}
          </Button>
        </div>
      </>
      {orderType === 'limit' ? (
        <>
          <div className="flex justify-between text-white font-bold">
            <p>{t`Limit Price`}</p>
          </div>
          <MarketInput
            key={`market-input-limitPrice-${type}`}
            setIsValid={setIsValid}
            step={1}
            limitAmount
            orderType={type}
            onChange={(value) => {
              setLimitPrice(value / 100);
            }}
            defaultValue={
              marketPrice[
                selectedOutcome === market.outcome1 ? 'outcome1' : 'outcome2'
              ] * 100
            }
            marketId={market.id}
            selectedToken={
              selectedOutcome === market.outcome1 ? 'token1' : 'token2'
            }
          />
          <MarketInput
            key={`market-input-shares-${type}`}
            balanceType={'shares'}
            limitPrice={limitPrice}
            selectedToken={
              selectedOutcome === market.outcome1 ? 'token1' : 'token2'
            }
            orderType={type}
            setIsValid={setIsValid}
            showMaxButton
            marketId={market.id}
            onChange={(value) => {
              setSharesAmount(value);
            }}
          />

          <div className="-mt-4 md:mt-0 bg-grey-light rounded-md text-xs text-white font-bold px-3 py-1 max-w-auto self-end">
            {type === 'buy' ? (
              <p>
                {t`Balance`}:{' '}
                {FormatCurrency(collateralBalance.value, false, 2)}
              </p>
            ) : (
              <p>
                {t`Shares`}:{' '}
                {
                  sharesByMarketUpdate[market.id]?.value?.[
                    `token${selectedOutcome === market.outcome1 ? '1' : '2'}`
                  ]?.balance
                }
              </p>
            )}
          </div>
          <div className="text-xs text-white font-bold flex flex-col gap-2">
            <p>{t`Set expiration`}</p>
            <div>
              <DropdownComponent
                key={selectedExpiration.customTimestamp ?? 'default'}
                data-testid="market-expiration-type"
                options={expirationOptions}
                value={{
                  value: selectedExpiration.type.value,
                  label:
                    selectedExpiration.type.value === 'custom' &&
                    selectedExpiration.customTimestamp
                      ? `Custom: ${new Date(selectedExpiration.customTimestamp).toLocaleDateString('en-GB')}`
                      : selectedExpiration.type.label,
                }}
                border
                primaryColor=""
                onChange={(item) => {
                  const selectedValue = item as Option;

                  if (selectedValue.value === 'custom') {
                    handleCustomExpiration();
                  } else {
                    setSelectedExpiration({
                      type: selectedValue,
                      customTimestamp: undefined,
                    });
                  }
                }}
                minW="md:min-w-auto"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <MarketInput
            balanceType={type === 'buy' ? 'collateral' : 'shares'}
            setIsValid={setIsValid}
            orderType={type}
            key={`market-input-${type}-${orderType}`}
            showMaxButton
            marketId={market.id}
            onChange={(value) => {
              setMarketOrderAmount(value);
            }}
            selectedToken={
              selectedOutcome === market.outcome1 ? 'token1' : 'token2'
            }
          />
          <div className="-mt-4 md:mt-0 bg-grey-light rounded-md text-xs text-white font-bold px-3 py-1 max-w-auto self-end">
            {type === 'buy' && (
              <p>
                {t`Balance`}:{' '}
                {FormatCurrency(collateralBalance.value, false, 2)}
              </p>
            )}
            {type === 'sell' && (
              <p>
                {t`Shares`}:{' '}
                {
                  sharesByMarketUpdate[market.id]?.value?.[
                    `token${selectedOutcome === market.outcome1 ? '1' : '2'}`
                  ]?.balance
                }
              </p>
            )}
          </div>
        </>
      )}
      {pathname.includes('/mobile-order') ? (
        <>
          <OrderFooterSection border />
          <BuyButtions />
        </>
      ) : (
        <>
          <>
            <BuyButtions />
            <OrderFooterSection />
          </>
        </>
      )}
    </div>
  );
};
