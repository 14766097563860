export const getErrorMessage = (e: string) => {
  let message = '';

  switch (e) {
    case 'UserRejectedRequestError':
      message = 'User rejected the request';
      break;
    case 'TransactionExecutionError':
      message = 'User rejected the request';
      break;
    case 'TypeError':
      return;
    default:
      message = e;
      break;
  }
  return message;
};
