import React, { createContext, useContext, useState, ReactNode } from 'react';

type ModalType = 'success' | 'error' | 'processing';

export type ModalParams = {
  content: ReactNode;
  title?: string;
  subTitle?: string;
  type?: ModalType;
  isBottomBar?: boolean;
};

export type OpenModal = (params: ModalParams) => void;

interface ModalContextType extends ModalParams {
  isOpen: boolean;
  openModal: OpenModal;
  closeModal: () => void;
  isBottomBar?: boolean;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<ReactNode>(null);
  const [title, setTitle] = useState<string>();
  const [subTitle, setSubTitle] = useState<string>();
  const [type, setType] = useState<ModalType>();
  const [isBottomBar, setIsBottomBar] = useState<boolean>();

  const openModal = ({
    content,
    title,
    subTitle,
    type,
    isBottomBar,
  }: ModalParams) => {
    setContent(content);
    setTitle(title);
    setSubTitle(subTitle);
    setIsOpen(true);
    setType(type);
    setIsBottomBar(isBottomBar);
  };

  const closeModal = () => {
    setIsOpen(false);
    setContent(null);
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        content,
        title,
        subTitle,
        type,
        openModal,
        closeModal,
        isBottomBar,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
