export const copyToClipboard = async (text: string): Promise<boolean> => {
  function copy(text: BlobPart) {
    return new Promise<void>((resolve, reject) => {
      if (
        typeof navigator !== 'undefined' &&
        typeof navigator.clipboard !== 'undefined' &&
        navigator.permissions
      ) {
        const type = 'text/plain';
        const blob = new Blob([text], { type });
        const data = [new ClipboardItem({ [type]: blob })];
        navigator.permissions
          .query({ name: 'clipboard-write' as PermissionName })
          .then((permission) => {
            if (
              permission.state === 'granted' ||
              permission.state === 'prompt'
            ) {
              navigator.clipboard
                .write(data)
                .then(resolve, reject)
                .catch(reject);
            } else {
              reject(new Error('Permission not granted!'));
            }
          });
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported('copy')
      ) {
        const textarea = document.createElement('textarea');
        textarea.textContent = text as string;
        textarea.style.position = 'fixed';
        textarea.style.width = '2em';
        textarea.style.height = '2em';
        textarea.style.padding = '0';
        textarea.style.border = 'none';
        textarea.style.outline = 'none';
        textarea.style.boxShadow = 'none';
        textarea.style.background = 'transparent';
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
          document.execCommand('copy');
          document.body.removeChild(textarea);
          resolve();
        } catch (e) {
          document.body.removeChild(textarea);
          reject(e);
        }
      } else {
        reject(
          new Error('None of copying methods are supported by this browser!')
        );
      }
    });
  }

  try {
    await copy(text);
    return true;
  } catch (e) {
    return false;
  }
};
