import { formatUnits } from 'viem';
import { Event } from '../types/types';

export const calculateTotalBetSum = (event: Event): Event => {
  const totalBet =
    event.marketsView?.reduce((sum, market) => {
      return sum + parseFloat(market.totalBet ?? '0');
    }, 0) || 0;

  const totalBetInDollars = formatUnits(BigInt(totalBet), 6);

  return {
    ...event,
    totalBet: totalBetInDollars,
  };
};

export const calcSum = (numbers: number[]): number => {
  const sum =
    numbers?.reduce((sum, number) => {
      return sum + (number ?? 0);
    }, 0) || 0;

  return sum;
};

export const calculateBet = (shares: number, acquisitionPrice: number) => {
  return shares * acquisitionPrice;
};

export const calculateValue = (shares: number, currentMarketPrice: number) => {
  return shares * currentMarketPrice;
};

export const calculateTotalReturn = (value: number, bet: number) => {
  return value - bet;
};

export const calculateTotalReturnPercentage = (value: number, bet: number) => {
  if (bet === 0) return 0;
  return ((value - bet) / bet) * 100;
};

export const formatTotalReturn = (totalReturn: number, percentage: number) => {
  const isPositive = totalReturn >= 0;
  const sign = isPositive ? '+' : '-';

  const formattedValue = `${sign}$${Math.abs(totalReturn).toFixed(2)}`;
  const formattedPercentage = `${sign}${Math.abs(percentage).toFixed(2)}%`;

  return {
    formattedValue,
    formattedPercentage,
    isPositive,
  };
};
