import React, { ReactNode, useState } from 'react';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';

type AccordionProps = {
  title?: string;
  children: ReactNode;
  isOpened?: boolean;
};

const Accordion: React.FC<AccordionProps> = ({ title, children, isOpened }) => {
  const [isOpen, setIsOpen] = useState(isOpened);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-grey-secondary py-2">
      <div
        className="flex justify-between items-center py-2 cursor-pointer"
        onClick={toggleAccordion}
      >
        {title && <h2 className="text-sm font-bold text-white">{title}</h2>}
        <ChevronDown className={`${isOpen ? 'rotate-180' : ''}`} />
      </div>
      <div
        className={`flex flex-col gap-2 my-2 mx-2 text-sm  origin-top transition-transform duration-200 ${isOpen ? 'scale-y-100' : 'scale-y-0 max-h-0 absolute overflow-hidden'} `}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
