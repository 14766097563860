import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import restService from '../helpers/rest-service-helper';

export interface Contract {
  id: string;
  name: string;
  chain_id: number;
  address: string;
}

interface ContractContextType {
  data: Contract[];
  loading: boolean;
  error: Error | null;
}

const ContractContext = createContext<ContractContextType | undefined>(
  undefined
);

export const useContracts = (): ContractContextType => {
  const context = useContext(ContractContext);
  if (!context) {
    throw new Error('useContracts must be used within an ContractsProvider');
  }
  return context;
};

interface ContractsProviderProps {
  children: ReactNode;
}

export const ContractsProvider: React.FC<ContractsProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<Contract[]>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await restService.get<Contract[]>('/contracts');
        setData(data);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <ContractContext.Provider
      value={{ data: data as Contract[], loading, error }}
    >
      {children}
    </ContractContext.Provider>
  );
};
