import { FC } from 'react';

export const EventCardSkeleton: FC = () => {
  return (
    <div className="animate-pulse w-full max-w-[400px] mx-auto sm:my-5 md:my-1 bg-black-dark p-4 rounded-2xl">
      <div className="h-32 bg-grey-lighter rounded-md mb-4"></div>
      <div className="h-6 bg-grey-lighter rounded-md mb-2 w-3/4"></div>
      <div className="h-4 bg-grey-lighter rounded-md mb-2 w-1/2"></div>
      <div className="h-4 bg-grey-lighter rounded-md w-1/4"></div>
    </div>
  );
};
