import React from 'react';
import { format } from 'date-fns';

type DateFormatterProps = {
  dateString: string;
};

const DateFormatter: React.FC<DateFormatterProps> = ({ dateString }) => {
  const date = new Date(dateString ?? '');
  const formattedDate = format(date, 'MMM dd, yyyy');

  return <span>{formattedDate}</span>;
};

export default DateFormatter;
