import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCategories } from '../../hooks/categories-hook';
import { Category } from '../../types/types';
import { Button } from '../button/button-component';

export const MarketsCategories = ({
  onSelect,
}: {
  onSelect: (categoryId?: string) => void;
}) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<
    string | undefined
  >(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    useCategories({
      page: 1,
      limit: 30,
      sortBy: 'category.position',
      status: 'ACTIVE',
      order: 'ASC',
    }).then((categories) => {
      setCategories(categories);
    });
  }, []);

  const selectCategory = (category?: Category) => {
    if (selectedCategoryId === category?.id) return;
    setSelectedCategoryId(category?.id);
    onSelect(category?.id);
  };

  return (
    <div className="overflow-auto flex gap-2">
      <Button
        data-testid="button-category-filter-all"
        onClick={() =>
          selectCategory({
            id: undefined,
          })
        }
        size="custom"
        className={`${!selectedCategoryId ? 'bg-brand-blue' : 'bg-grey-light'} text-sm hover:bg-brand-blue p-3 text-nowrap self-center`}
      >
        {t`All`}
      </Button>
      {categories.map((category, key) => (
        <Button
          data-testid={`button-category-filter-${category}`}
          onClick={() => {
            selectCategory(category);
          }}
          key={`category-${key}`}
          className={`${selectedCategoryId === category.id ? 'bg-brand-blue' : 'bg-grey-light'} text-sm hover:bg-brand-blue p-3 text-nowrap self-center`}
          size="custom"
        >
          {category.name}
        </Button>
      ))}
    </div>
  );
};
