import { useTranslation } from 'react-i18next';
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ReactComponent as ChevronBack } from '../../assets/icons/chevron-back.svg';
import { ReactComponent as MarketsIcon } from '../../assets/icons/markets-icon.svg';
import { useAccount } from 'wagmi';
import { WalletOptions } from '../wallet-options';
import { useBlockchain } from '../../context/blockchain-context';
import { Button } from '../button/button-component';
import useViewport, { isMobile } from '../../hooks/viewport';
import { FormatCurrency } from '../../helpers/currency-helper';
import { useModal } from '../../context/modal-context';
import { ConfirmDepositModal } from '../confirm-deposit-modal/confirm-deposit-modal';
import { DepositButton } from '../deposit-button/deposit-button-component';
import { SearchInput } from '../search-input/search-input-component';
import { DropdownComponent } from '../dropdown/dropdown-component';
import { FiltersComponent, OnChangeParams } from '../filters/filters-component';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { MarketParams } from '../../types/types';
import Tabs from '../tabs/tabs-component';
import { TABS_MOCK } from '../../mocked/tabs';
import { UserBalance } from '../user-balance/user-balance-component';
import { useWebsocket } from '../../context/websocket-context';
import BottomBar from '../bottom-bar/bottom-bar-component';
import { ApproveTokensModal } from '../../pages/approve-tokens-modal/ApproveTokensModal';
import { marketHeadingFilters } from '../../helpers/market-heading-filters';
import { useTotalPositionsValue } from '../../hooks/porfolio-hook';
import { isInIframe } from '../../helpers/iframe-helper';

const BackButton = ({ href, title }: { href: string; title: string }) => {
  const navigate = useNavigate();
  return (
    <>
      <Button onClick={() => navigate(href)} variant="darkBlack">
        <div className="flex items-center gap-2 text-text-secondary">
          <ChevronBack />
          <p className="text-sm font-bold">{title}</p>
        </div>
      </Button>
    </>
  );
};

export const MarketHeaderComponent = ({
  marketParams,
  setMarketParams,
  login,
}: {
  marketParams?: MarketParams;
  setMarketParams: Dispatch<SetStateAction<MarketParams>>;
  login?: (address: `0x${string}`) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const { address, isConnected } = useAccount();
  const {
    evmAddress,
    showConfirmDepositHeading,
    showApproveTokensHeading,
    proxyWalletAddress,
    proxyWalletSetup,
  } = useBlockchain();
  const { collateralBalance } = useWebsocket();
  const { width } = useViewport();
  const { openModal } = useModal();
  const isOnMarketsOverview = pathname.startsWith('/market-overview');
  const isOnHome = pathname === '/';
  const navigate = useNavigate();
  const { urlFilter } = useParams();
  const totalPositionsValueData = useTotalPositionsValue();

  const mobileView = isMobile(width);
  const initialMarketParams = {
    page: 1,
    limit: mobileView ? 5 : 12,
    eventStatus: 'ACTIVE',
    sortBy: 'market.createdAt',
  };
  const options = [
    {
      value: 'markets',
      label: t`Market`,
    },
  ];

  const marketParamsPrev = useRef<MarketParams>();

  const filterChange = (
    changedFilters: {
      filter: OnChangeParams;
      checked: boolean;
    }[]
  ) => {
    let newMarketParams: any = { ...marketParams };

    changedFilters.forEach(({ filter, checked }) => {
      if (checked && filter.value !== 'any') {
        newMarketParams = {
          ...newMarketParams,
          [filter.name]: filter.value,
        };
      } else {
        delete newMarketParams[filter.name];
      }
    });
    return {
      ...newMarketParams,
      page: 1,
    };
  };

  const getPageTitle = () => {
    if (pathname.includes('/mobile-order')) {
      return t`Select amount`;
    } else if (isOnMarketsOverview) {
      return t`Market Overview`;
    } else if (pathname.includes('/portfolio')) {
      return t`Portfolio`;
    } else if (pathname.includes('/market/')) {
      return t`Market Details`;
    }
    return t`Back`;
  };

  useEffect(() => {
    let params = initialMarketParams;

    if (urlFilter) {
      const defaultFilters = marketHeadingFilters(urlFilter, t, marketParams)
        .map((filter) =>
          filter.items.map((item) => ({
            ...item,
            name: filter.name,
          }))
        )
        .flat()
        .filter((item) => item.defaultChecked)
        .map((filter) => ({
          filter,
          checked: true,
        }));
      params = filterChange(defaultFilters);
      marketParamsPrev.current = params;
    }
    if (
      !marketParams ||
      JSON.stringify(params) !== JSON.stringify(marketParamsPrev.current) ||
      urlFilter
    ) {
      marketParamsPrev.current = params;
      setMarketParams(params);
    }
  }, [urlFilter]);
  const onMarketDetails = (pathname: string) => {
    if (pathname.includes('/market/')) {
      const pattern = /^\/market\/.*$/;
      return pattern.test(pathname);
    }
    return false;
  };

  const PortfolioButtonContent = () => {
    if (!proxyWalletSetup) {
      return <div className="text-water-blue">$0.00</div>;
    }
    const { totalPositionsValue, isLoading, error } =
      totalPositionsValueData || { isLoading: true };
    const isCollateralLoading = collateralBalance.loading;
    const isDataLoading = isLoading || isCollateralLoading;

    if (isDataLoading) {
      return (
        <div className="text-water-blue">
          <UserBalance />
          <div className="animate-pulse">
            <div className="h-4 bg-grey-lighter rounded-md w-26"></div>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="text-water-blue">
          <UserBalance />
          {'$0.00'}
        </div>
      );
    }

    return (
      <div className="text-water-blue">
        <UserBalance />
        {FormatCurrency(
          +totalPositionsValue + +collateralBalance.value,
          false,
          2
        )}
      </div>
    );
  };

  const CashButtonContent = () => {
    if (!totalPositionsValueData) {
      return <div className="text-water-blue">$0.00</div>;
    }

    return (
      <div className="text-water-blue">
        {!proxyWalletAddress ? (
          '$0.00'
        ) : collateralBalance.loading ? (
          <div className="animate-pulse">
            <div className="h-4 bg-grey-lighter rounded-md w-26"></div>
          </div>
        ) : (
          FormatCurrency(collateralBalance.value, false, 2)
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={`md:fixed z-10 container ${onMarketDetails(pathname) ? 'max-w-[1170px] mx-auto' : ''} flex flex-col gap-4 bg-black-light top-0 ${!showConfirmDepositHeading && !showApproveTokensHeading ? 'md:pt-6' : ''} `}
      >
        {/* {showConfirmDepositHeading && (
          <Button
            size="sm"
            onClick={() =>
              openModal({
                content: <ConfirmDepositModal />,
                title: t`Confirm Deposit`,
              })
            }
            className="rounded-none w-[calc(100%+48px)] -ml-6 md:ml-inherit md:w-full mb-4"
            variant="blue"
          >{t`Confirm pending deposit`}</Button>
        )} */}
        {showApproveTokensHeading && (
          <Button
            data-testid="button-complete-profile-setup"
            size="sm"
            onClick={() =>
              openModal({
                content: <ApproveTokensModal />,
                title: t`Approve Tokens`,
              })
            }
            className="rounded-none w-[calc(100%+48px)] -ml-6 md:ml-inherit md:w-full mb-4"
            variant="blue"
          >{t`Complete Profile Setup`}</Button>
        )}
        <div className="hidden md:flex flex-col md:flex-row items-center gap-2 md:gap-4">
          <>
            {isOnHome ? (
              <h3 className="text-white text-xl md:text-2xl font-bold font-serif">
                {t`Predictive Markets`}
              </h3>
            ) : (
              <BackButton
                data-testid="button-Back-to-Home"
                href="/"
                title={t`Back to Home`}
              />
            )}
          </>
          <SearchInput
            data-testid="input-Search"
            className="flex-grow bg-transparent bg-search bg-no-repeat bg-[center_left_12px]"
            placeholder={t`Search markets`}
            type="text"
            onChange={({ target: { value } }) => {
              navigate('/market-overview', { state: { term: value } });
            }}
          />
          {isOnMarketsOverview && (
            <>
              <div>
                <DropdownComponent
                  primaryColor=""
                  onChange={() => {
                    console.log('OK');
                  }}
                  border
                  value={options[0]}
                  options={options}
                />
              </div>
              <FiltersComponent
                onClear={() => {
                  navigate('/market-overview');
                  setMarketParams(initialMarketParams);
                }}
                onChange={(filter, checked) => {
                  const params = filterChange([{ filter, checked }]);
                  setMarketParams(params);
                }}
                key={urlFilter}
                filters={marketHeadingFilters(
                  urlFilter as string,
                  t,
                  marketParams
                )}
              />
            </>
          )}
          {isConnected || isInIframe() ? (
            <>
              {evmAddress || isInIframe() ? (
                <div className="flex gap-4 items-center">
                  <NavLink
                    data-testid="button-Markets"
                    className="border-r pr-4 border-grey-secondary text-xs font-bold text-text-secondary flex flex-col items-center ml-8"
                    to="/market-overview"
                  >
                    <MarketsIcon />
                    <p>{t`Markets`}</p>
                  </NavLink>
                  <NavLink
                    className="text-xs text-center font-bold text-text-secondary"
                    to="/portfolio"
                    data-testid="button-Portfolio"
                  >
                    <PortfolioButtonContent />
                    <p>{t`Portfolio`}</p>
                  </NavLink>
                  <NavLink
                    className="text-xs text-center font-bold text-text-secondary"
                    to="/portfolio"
                    data-testid="button-Cash"
                  >
                    <CashButtonContent />
                    <p>{t`Cash`}</p>
                  </NavLink>
                  <DepositButton>
                    <Button
                      data-testid="button-Deposit"
                      size="md"
                      variant="blue"
                    >{t`Deposit`}</Button>
                  </DepositButton>
                </div>
              ) : (
                <>
                  {login && (
                    <Button
                      className={`${isMobile(width) ? 'w-[100%]' : ''}`}
                      size="md"
                      variant="blue"
                      onClick={async () =>
                        await login(address as `0x${string}`)
                      }
                    >
                      {t`Login`}
                    </Button>
                  )}
                </>
              )}
            </>
          ) : (
            !isInIframe() && <WalletOptions />
          )}
        </div>

        <>
          <div className="flex flex-col md:hidden gap-2">
            {isOnHome ? (
              <h3 className="text-white text-xl font-bold font-serif pb-2 pt-8">
                {t`Predictive Markets`}
              </h3>
            ) : (
              <>
                <div className="flex border-b border-grey-secondary items-center gap-2 py-2">
                  <div
                    onClick={() => navigate(-1)}
                    className="cursor-pointer bg-black-dark p-3 rounded-xl w-[38px] h-[38px] flex flex-col items-center justify-center"
                  >
                    <ChevronBack />
                  </div>
                  <p className="text-white text-sm">{getPageTitle()}</p>
                </div>
              </>
            )}
            {!pathname.includes('/mobile-order') && (
              <>
                {isConnected ? (
                  <>
                    {evmAddress ? (
                      <div className="grid grid-cols-3 bg-black-dark rounded-xl max-w-[254px] p-4">
                        <div className="flex flex-col">
                          <DepositButton>
                            <p className=" text-water-blue">
                              <UserBalance />
                              {FormatCurrency(collateralBalance.value, false)}
                            </p>
                            <p className="text-sm text-text-secondary">{t`Deposit`}</p>
                          </DepositButton>
                        </div>
                        <div className="border-r border-grey-secondary-darker justify-self-center"></div>
                        <div className="flex flex-col ">
                          <NavLink
                            className="text-sm text-text-secondary"
                            to="/portfolio"
                          >
                            <p className="text-water-blue">
                              <UserBalance />
                              {FormatCurrency(collateralBalance.value, false)}
                            </p>
                            <p>{t`Portfolio`}</p>
                          </NavLink>
                        </div>
                      </div>
                    ) : (
                      <>
                        {login && (
                          <Button
                            className={`${isMobile(width) ? 'w-[100%]' : ''}`}
                            size="md"
                            variant="blue"
                            onClick={async () =>
                              await login(address as `0x${string}`)
                            }
                          >
                            {t`Login`}
                          </Button>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  !isInIframe() && <WalletOptions />
                )}
              </>
            )}
          </div>
          {!pathname.includes('/mobile-order') && (
            <div className="overflow-auto flex md:hidden bg-black-dark rounded-xl text-sm font-bold text-text-secondary mb-4 p-2 items-center">
              {TABS_MOCK.map((tab, key) => (
                <p
                  className="py-3 px-6"
                  onClick={() => {
                    const filter = tab.title.toLowerCase();
                    navigate(
                      `/market-overview/${filter !== 'all' ? filter : ''}`
                    );
                  }}
                  key={`tab-${key}`}
                >
                  {tab.title}
                </p>
              ))}
            </div>
          )}
        </>

        <Tabs
          hasSearch
          hiddenOnMobile
          onChange={(tab) => {
            const filter = tab.title.toLowerCase();
            navigate(`/market-overview/${filter !== 'all' ? filter : ''}`);
          }}
          tabs={TABS_MOCK}
        />
      </div>
      <div
        className={`container ${onMarketDetails(pathname) ? 'max-w-[1170px] mx-auto' : ''} md:pt-6 px-4 md:pt-10 md:pb-28 md:px-6 flex gap-10 flex-col ${showConfirmDepositHeading || showApproveTokensHeading ? 'md:mt-[168px]' : 'md:mt-[136px]'}`}
      >
        <Outlet />
      </div>

      <div className="relative block md:hidden pb-16">
        <BottomBar />
      </div>
    </>
  );
};
