import restService from './rest-service-helper';
import { signMessage } from '@wagmi/core';
import { config, getBlockTimeForChainInSeconds } from '../config';
import { SiweMessage } from 'siwe';
import { TFunction } from 'i18next';
import { DecodedUserData, IOrder, Order } from '../types/types';
import { jwtDecode } from 'jwt-decode';
// import { signTypedData } from '@wagmi/core';
import {
  CTF_ABI,
  ERC20_ABI,
  SWAP_ROUTER_02_ABI,
  MAX_AMOUNT,
  SAFE_PROXY_ABI,
  USDC_ABI,
  ImplCollateral_ABI,
  ZeroAddress,
  NEG_RISK_ADAPTER_ABI,
} from '../constants/contracts';
import { OperationType, encodeMulti } from 'ethers-multisend';
import {
  encodeFunctionData,
  parseUnits,
  TransactionReceipt,
  TransactionReceiptNotFoundError,
  // TypedData,
} from 'viem';
import { readContract, getPublicClient } from '@wagmi/core';

const scheme = window.location.protocol.slice(0, -1);
const domain = window.location.host;
const origin = window.location.origin;
const expTime = 1000 * 3 * 60; // 3 minutes
type ProxyAddressData =
  | { proxyAddress: string; txHash: `0x${string}` }
  | undefined;

export async function createProxyWallet(
  t: TFunction,
  chainId: number,
  SAFE_PROXY_FACTORY: string
): Promise<ProxyAddressData> {
  return new Promise((resolve) => {
    window.proxy.addMessageListener(
      'metamarket.signatureRequest',
      async (message) => {
        console.log('metamarket.signatureRequest createProxyWallet');

        const signature = message.payload.signature;
        try {
          const { proxyAddress, txHash } = await restService.post<{
            proxyAddress: string;
            txHash: `0x${string}`;
          }>('/wallet/create', {
            signature,
          });
          resolve({ proxyAddress, txHash });
        } catch (e) {
          throw t`Deploy proxy wallet error`;
        }
      }
    );
    window.proxy.signatureRequest(
      {
        name: 'Metawin Contract Proxy Factory',
        chainId: chainId,
        verifyingContract: SAFE_PROXY_FACTORY,
      },
      {
        CreateProxy: [
          { name: 'paymentToken', type: 'address' },
          { name: 'payment', type: 'uint256' },
          { name: 'paymentReceiver', type: 'address' },
        ],
      },
      'CreateProxy',
      {
        paymentToken: ZeroAddress as `0x${string}`,
        payment: BigInt(0),
        paymentReceiver: ZeroAddress as `0x${string}`,
      }
    );
  });
}

export async function createOrder(
  order: IOrder,
  chainId: number,
  marketId: string,
  verifyingContract: string
) {
  return new Promise((resolve) => {
    // const OrderTypes = {
    //   EIP712Domain: [
    //     { name: 'name', type: 'string' },
    //     { name: 'version', type: 'string' },
    //     { name: 'chainId', type: 'uint256' },
    //     { name: 'verifyingContract', type: 'address' },
    //   ],
    //   Order: [
    //     { name: 'salt', type: 'uint256' },
    //     { name: 'maker', type: 'address' },
    //     { name: 'signer', type: 'address' },
    //     { name: 'taker', type: 'address' },
    //     { name: 'tokenId', type: 'uint256' },
    //     { name: 'makerAmount', type: 'uint256' },
    //     { name: 'takerAmount', type: 'uint256' },
    //     { name: 'expiration', type: 'uint256' },
    //     { name: 'nonce', type: 'uint256' },
    //     { name: 'feeRateBps', type: 'uint256' },
    //     { name: 'side', type: 'uint8' },
    //     { name: 'signatureType', type: 'uint8' },
    //   ],
    // } as const satisfies TypedData;
    // const signature = await signTypedData(config, {
    //   domain: {
    //     name: 'Metawin CTF Exchange',
    //     version: '1',
    //     chainId: chainId as unknown as bigint,
    //     verifyingContract: verifyingContract as `0x${string}`,
    //   },
    //   types: OrderTypes,
    //   primaryType: 'Order',
    //   message: order,
    // });
    window.proxy.addMessageListener(
      'metamarket.signatureRequest',
      async (message) => {
        console.log('metamarket.signatureRequest createOrder');

        const signature = message.payload.signature;
        try {
          const response = await restService.post<Order>('/orders', {
            ...order,
            makerAmount: order.makerAmount.toString(), // show amount
            takerAmount: order.takerAmount.toString(), // calculate number of shares based on the price
            signature,
            orderType: 'GTC',
            market: marketId,
          });
          resolve(response);
        } catch (e) {
          console.log('e', e);
          throw 'Error';
        }
      }
    );

    window.proxy.signatureRequest(
      {
        name: 'Metawin CTF Exchange',
        version: '1',
        chainId,
        verifyingContract,
      },
      {
        Order: [
          { name: 'salt', type: 'uint256' },
          { name: 'maker', type: 'address' },
          { name: 'signer', type: 'address' },
          { name: 'taker', type: 'address' },
          { name: 'tokenId', type: 'uint256' },
          { name: 'makerAmount', type: 'uint256' },
          { name: 'takerAmount', type: 'uint256' },
          { name: 'expiration', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
          { name: 'feeRateBps', type: 'uint256' },
          { name: 'side', type: 'uint8' },
          { name: 'signatureType', type: 'uint8' },
        ],
      },
      'Order',
      order
    );
  });
}

export async function executeWithMultisend(
  proxyWalletAddress: string,
  chainId: number,
  transactions: {
    operation: OperationType;
    to: string;
    value: string;
    data: `0x${string}`;
  }[],
  MULTISEND_ADDRESS: string,
  endPoint?: string
): Promise<string> {
  return new Promise((resolve) => {
    console.log(
      'proxyWalletAddress in executeWithMultisend',
      proxyWalletAddress
    );
    readContract(config, {
      abi: SAFE_PROXY_ABI,
      address: proxyWalletAddress as `0x${string}`,
      functionName: 'nonce',
    }).then((nonce) => {
      console.log('nonce in executeWithMultisend', nonce);

      const types = {
        SafeTx: [
          { name: 'to', type: 'address' },
          { name: 'value', type: 'uint256' },
          { name: 'data', type: 'bytes' },
          { name: 'operation', type: 'uint8' },
          { name: 'safeTxGas', type: 'uint256' },
          { name: 'baseGas', type: 'uint256' },
          { name: 'gasPrice', type: 'uint256' },
          { name: 'gasToken', type: 'address' },
          { name: 'refundReceiver', type: 'address' },
          { name: 'nonce', type: 'uint256' },
        ],
      };
      const encodedTxs = encodeMulti(transactions, MULTISEND_ADDRESS);
      const data = encodedTxs.data;
      const value = {
        to: MULTISEND_ADDRESS,
        value: 0,
        data,
        operation: 1,
        safeTxGas: 0,
        baseGas: 0,
        gasPrice: 0,
        gasToken: ZeroAddress,
        refundReceiver: ZeroAddress,
        nonce,
      };
      // const signature = await signTypedData(config, {
      //   domain: {
      //     chainId,
      //     verifyingContract: proxyWalletAddress as `0x${string}`,
      //   },
      //   types,
      //   primaryType: 'SafeTx',
      //   message: value,
      // });

      window.proxy.addMessageListener(
        'metamarket.signatureRequest',
        async (message) => {
          console.log('metamarket.signatureRequest executeWithMultisend');

          const signature = message.payload.signature;
          const r = signature.slice(0, 66);
          const s = '0x' + signature.slice(66, 130);
          let v = parseInt(signature.slice(130, 132), 16);
          if (v < 27) {
            v += 27;
          }
          const signatures = `${r}${s.slice(2)}${v.toString(16).padStart(2, '0')}`;

          try {
            console.log('proxyWalletAddress', proxyWalletAddress);
            const { txHash } = await restService.post<{
              txHash: string;
            }>(endPoint ?? '/wallet/execute', {
              data,
              signatures,
            });

            resolve(txHash);
          } catch (e) {
            console.error('SIGNATURES', signatures);
            console.error('DATA', data);
            console.error('VALUE', value);
            throw 'Error while multisend';
          }
        }
      );

      window.proxy.signatureRequest(
        {
          chainId,
          verifyingContract: proxyWalletAddress,
        },
        types,
        'SafeTx',
        value
      );
    });
  });
}

export async function swapTokensDeposit(
  proxyWalletAddress: string,
  chain_id: number,
  USDC_CONTRACT_ADDRESS: string,
  USDCe_ADDRESS: string,
  MULTISEND_ADDRESS: string,
  SWAP_ROUTER: string
): Promise<string> {
  const balance = await readContract(config, {
    abi: USDC_ABI,
    address: USDC_CONTRACT_ADDRESS as `0x${string}`,
    functionName: 'balanceOf',
    args: [proxyWalletAddress],
  });

  const transactions = [
    {
      operation: OperationType.Call,
      to: USDC_CONTRACT_ADDRESS,
      value: '0',
      data: encodeFunctionData({
        abi: ImplCollateral_ABI,
        args: [SWAP_ROUTER, MAX_AMOUNT],
        functionName: 'approve',
      }),
    },
    {
      operation: OperationType.Call,
      to: SWAP_ROUTER,
      value: '0',
      data: encodeFunctionData({
        abi: SWAP_ROUTER_02_ABI,
        functionName: 'exactInputSingle',
        args: [
          {
            tokenIn: USDC_CONTRACT_ADDRESS,
            tokenOut: USDCe_ADDRESS,
            fee: 500, // NB! Have to exactly match the pool's fee : 100, 500, 3000 or 10000
            recipient: proxyWalletAddress,
            deadline: Math.round((new Date().getTime() + 1000 * 5 * 60) / 1000), // 5 minutes after current date
            amountIn: balance, // balance of
            amountOutMinimum: '90', // 0.998829736602106% is mainnet value, since we are on testnet we can set it to 90 for now and lower it if GS013 is thrown
            sqrtPriceLimitX96: '0',
          },
        ],
      }),
    },
  ];
  return await executeWithMultisend(
    proxyWalletAddress,
    chain_id,
    transactions,
    MULTISEND_ADDRESS
  );
}

export async function swapTokensWithdraw(
  proxyWalletAddress: string,
  chain_id: number,
  amount: string,
  USDCe_ADDRESS: string,
  USDC_CONTRACT_ADDRESS: string,
  MULTISEND_ADDRESS: string,
  SWAP_ROUTER: string,
  recipient?: string
): Promise<string> {
  const transactions = [
    {
      operation: OperationType.Call,
      to: USDCe_ADDRESS,
      value: '0',
      data: encodeFunctionData({
        abi: ImplCollateral_ABI,
        args: [SWAP_ROUTER, MAX_AMOUNT],
        functionName: 'approve',
      }),
    },
    {
      operation: OperationType.Call,
      to: SWAP_ROUTER,
      value: '0',
      data: encodeFunctionData({
        abi: SWAP_ROUTER_02_ABI,
        functionName: 'exactInputSingle',
        args: [
          {
            tokenIn: USDCe_ADDRESS,
            tokenOut: USDC_CONTRACT_ADDRESS,
            fee: 500, // NB! Have to exactly match the pool's fee : 100, 500, 3000 or 10000
            recipient,
            deadline: Math.round((new Date().getTime() + 1000 * 5 * 60) / 1000), // 5 minutes after current date
            amountIn: parseUnits(amount, 6),
            amountOutMinimum: '90', // 0.998829736602106% is mainnet value, since we are on testnet we can set it to 90 for now and lower it if GS013 is thrown
            sqrtPriceLimitX96: '0',
          },
        ],
      }),
    },
  ];
  return await executeWithMultisend(
    proxyWalletAddress,
    chain_id,
    transactions,
    MULTISEND_ADDRESS
  );
}

export async function transferUSDCe(
  proxyWalletAddress: string,
  chain_id: number,
  amount: string,
  USDCe_ADDRESS: string,
  MULTISEND_ADDRESS: string,
  recipient?: string
): Promise<string> {
  const transactions = [
    {
      operation: OperationType.Call,
      to: USDCe_ADDRESS,
      value: '0',
      data: encodeFunctionData({
        abi: ERC20_ABI,
        args: [recipient, parseUnits(amount, 6)],
        functionName: 'transfer',
      }),
    },
  ];
  return await executeWithMultisend(
    proxyWalletAddress,
    chain_id,
    transactions,
    MULTISEND_ADDRESS
  );
}

export async function setupProxyWallet(
  proxyWalletAddress: string,
  nonce: string,
  chainId: number,
  UMA_CTF_ADAPTER: string,
  USDCe_ADDRESS: string,
  MULTISEND_ADDRESS: string,
  CTF_EXCHANGE_ADDRESS: string,
  CTF_ADDRESS: string,
  NEG_RISK_CTF_EXCHANGE: string,
  NEG_RISK_ADAPTER: string
): Promise<string> {
  return new Promise((resolve) => {
    const types = {
      SafeTx: [
        { name: 'to', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'data', type: 'bytes' },
        { name: 'operation', type: 'uint8' },
        { name: 'safeTxGas', type: 'uint256' },
        { name: 'baseGas', type: 'uint256' },
        { name: 'gasPrice', type: 'uint256' },
        { name: 'gasToken', type: 'address' },
        { name: 'refundReceiver', type: 'address' },
        { name: 'nonce', type: 'uint256' },
      ],
    };
    const transactions = [
      // Approve the Uma CTF Adapter as spender for collateral
      {
        operation: OperationType.Call,
        to: USDCe_ADDRESS,
        value: '0',
        data: encodeFunctionData({
          abi: ERC20_ABI,
          args: [UMA_CTF_ADAPTER, MAX_AMOUNT],
          functionName: 'approve',
        }),
      },
      // Approve CTF as spender for collateral
      {
        operation: OperationType.Call,
        to: USDCe_ADDRESS,
        value: '0',
        data: encodeFunctionData({
          abi: ERC20_ABI,
          args: [CTF_ADDRESS, MAX_AMOUNT],
          functionName: 'approve',
        }),
      },
      // Approve the CTF Exchange as spender for collateral
      {
        operation: OperationType.Call,
        to: USDCe_ADDRESS,
        value: '0',
        data: encodeFunctionData({
          abi: ERC20_ABI,
          args: [CTF_EXCHANGE_ADDRESS, MAX_AMOUNT],
          functionName: 'approve',
        }),
      },
      // Approve the CTF Exchange as spender for all CTF tokens (all different ERC1155 positionIds created in the CTF contract)
      {
        operation: OperationType.Call,
        to: CTF_ADDRESS,
        value: '0',
        data: encodeFunctionData({
          abi: CTF_ABI,
          args: [CTF_EXCHANGE_ADDRESS, true],
          functionName: 'setApprovalForAll',
        }),
      },
      // Approve the Neg Risk CTF Exchange as spender for collateral
      {
        operation: OperationType.Call,
        to: USDCe_ADDRESS,
        value: '0',
        data: encodeFunctionData({
          abi: ERC20_ABI,
          args: [NEG_RISK_CTF_EXCHANGE, MAX_AMOUNT],
          functionName: 'approve',
        }),
      },
      // Approve the Neg Risk CTF Exchange as spender for all CTF tokens (all different ERC1155 positionIds created in the CTF contract)
      {
        operation: OperationType.Call,
        to: CTF_ADDRESS,
        value: '0',
        data: encodeFunctionData({
          abi: CTF_ABI,
          args: [NEG_RISK_CTF_EXCHANGE, true],
          functionName: 'setApprovalForAll',
        }),
      },
      // Approve the Neg Risk Adapter as pender for all CTF tokens (all different ERC1155 positionIds created in the CTF contract)
      {
        operation: OperationType.Call,
        to: CTF_ADDRESS,
        value: '0',
        data: encodeFunctionData({
          abi: CTF_ABI,
          args: [NEG_RISK_ADAPTER, true],
          functionName: 'setApprovalForAll',
        }),
      },
    ];

    const encodedTxs = encodeMulti(transactions, MULTISEND_ADDRESS);
    const data = encodedTxs.data;
    const value = {
      to: MULTISEND_ADDRESS,
      value: 0,
      data,
      operation: 1,
      safeTxGas: 0,
      baseGas: 0,
      gasPrice: 0,
      gasToken: ZeroAddress,
      refundReceiver: ZeroAddress,
      // nonce: parseInt(nonce, 10),
      nonce,
    };

    // const signature = await signTypedData(config, {
    //   domain: {
    //     chainId,
    //     verifyingContract: proxyWalletAddress as `0x${string}`,
    //   },
    //   types,
    //   primaryType: 'SafeTx',
    //   message: value,
    // });
    window.proxy.addMessageListener(
      'metamarket.signatureRequest',
      async (message) => {
        // debugger; // eslint-disable-line no-debugger
        console.log('metamarket.signatureRequest setupProxyWallet');

        const signature = message.payload.signature;
        try {
          const { txHash } = await restService.post<{
            txHash: string;
          }>('/wallet/setup', {
            signature,
          });
          resolve(txHash);
        } catch (e) {
          throw 'Setup proxy wallet error';
        }
      }
    );

    window.proxy.signatureRequest(
      {
        chainId,
        verifyingContract: proxyWalletAddress,
      },
      types,
      'SafeTx',
      value
    );
  });
}

export async function redeemPositions(
  proxyWalletAddress: string,
  TO_ADDRESS: string,
  chainId: number,
  COLLATERAL: string,
  MULTISEND_ADDRESS: string,
  marketId: string,
  conditionId?: string,
  isMultiMarket?: boolean,
  amounts?: string[]
): Promise<string> {
  let transactions;

  if (isMultiMarket && amounts) {
    transactions = [
      {
        operation: OperationType.Call,
        to: TO_ADDRESS,
        value: '0',
        data: encodeFunctionData({
          abi: NEG_RISK_ADAPTER_ABI,
          args: [
            `0x${conditionId}`,
            [parseUnits(amounts[0], 6), parseUnits(amounts[1], 6)],
          ],
          functionName: 'redeemPositions',
        }),
      },
    ];
  } else {
    transactions = [
      {
        operation: OperationType.Call,
        to: TO_ADDRESS,
        value: '0',
        data: encodeFunctionData({
          abi: CTF_ABI,
          args: [
            COLLATERAL,
            '0x0000000000000000000000000000000000000000000000000000000000000000',
            `0x${conditionId}`,
            [1, 2],
          ],
          functionName: 'redeemPositions',
        }),
      },
    ];
  }
  return await executeWithMultisend(
    proxyWalletAddress,
    chainId,
    transactions,
    MULTISEND_ADDRESS,
    `/markets/redeem-positions/${marketId}`
  );
}

export async function generateNonce(address: string) {
  const { nonce } = await restService.post<{
    createdAt: string;
    email: string;
    evmAddress: string;
    id: string;
    nonce: string;
    proxyWallet: string;
    updatedAt: string;
  }>('/users/auth/generate-nonce', {
    evmAddress: address,
  });
  return nonce;
}

export type AuthResult = [DecodedUserData, string] | null;

export async function signInWithEthereum(
  t: TFunction,
  chainId: number,
  address?: string
): Promise<AuthResult> {
  if (address) {
    const nonce = await generateNonce(address);
    const message = await createSiweMessage(
      address,
      t`Sign in with Ethereum to the app.`,
      nonce,
      chainId
    );

    const signature = await signMessage(config, { message });
    try {
      const { authToken } = await restService.post<{ authToken: string }>(
        '/users/auth/verify-signature',
        {
          domain,
          type: 'JWT',
          message,
          signature: signature,
        }
      );
      return [jwtDecode(authToken), authToken];
    } catch (e) {
      throw 'Sign-in error';
    }
  }

  return null;
}

export async function splitPosition(
  proxyWalletAddress: string,
  chain_id: number,
  amount: bigint,
  conditionId: string,
  USDCe_ADDRESS: string,
  MULTISEND_ADDRESS: string,
  CTF_ADDRESS: string
): Promise<string> {
  const transactions = [
    {
      operation: OperationType.Call,
      to: USDCe_ADDRESS,
      value: '0',
      data: encodeFunctionData({
        abi: ERC20_ABI,
        args: [CTF_ADDRESS, amount],
        functionName: 'approve',
      }),
    },
    {
      operation: OperationType.Call,
      to: CTF_ADDRESS,
      value: '0',
      data: encodeFunctionData({
        abi: CTF_ABI,
        args: [
          USDCe_ADDRESS, // collateralToken
          '0x0000000000000000000000000000000000000000000000000000000000000000', // parentCollectionId
          conditionId,
          [1, 2], // partition
          amount,
        ],
        functionName: 'splitPosition',
      }),
    },
  ];
  return await executeWithMultisend(
    proxyWalletAddress,
    chain_id,
    transactions,
    MULTISEND_ADDRESS
  );
}

export async function mergePositions(
  proxyWalletAddress: string,
  chain_id: number,
  amount: bigint,
  conditionId: string,
  USDCe_ADDRESS: string,
  MULTISEND_ADDRESS: string,
  TO_ADDRESS: string,
  CTF_ADDRESS: string,
  isMultiMarket?: boolean
): Promise<string> {
  const transactions = [
    {
      operation: OperationType.Call,
      to: TO_ADDRESS,
      value: '0',
      data: encodeFunctionData({
        abi: CTF_ABI,
        args: [
          USDCe_ADDRESS, // collateralToken
          '0x0000000000000000000000000000000000000000000000000000000000000000', // parentCollectionId
          conditionId,
          [1, 2], // partition
          amount,
        ],
        functionName: 'mergePositions',
      }),
    },
  ];
  if (isMultiMarket) {
    transactions.unshift({
      operation: 0,
      to: CTF_ADDRESS,
      value: '0',
      data: encodeFunctionData({
        functionName: 'setApprovalForAll',
        abi: CTF_ABI,
        args: [TO_ADDRESS, true],
      }),
    });
  }
  return await executeWithMultisend(
    proxyWalletAddress,
    chain_id,
    transactions,
    MULTISEND_ADDRESS
  );
}

async function createSiweMessage(
  address: string,
  statement: string,
  nonce: string,
  chainId: number
) {
  const message = new SiweMessage({
    scheme,
    domain,
    address,
    statement,
    uri: origin,
    version: '1',
    chainId,
    nonce,
    expirationTime: new Date(Date.now() + expTime).toISOString(),
  });

  return message.prepareMessage();
}

export const checkForTxSuccess = (
  receipt: TransactionReceipt,
  txHash: string
) => {
  return receipt && txHash && txHash.length > 0 && receipt.status === 'success';
};

export const waitForTransactionCompletion = async (
  hash: `0x${string}`,
  confirmationsRequired: number
): Promise<TransactionReceipt> => {
  const publicClient = getPublicClient(config);
  const chainId = publicClient.chain.id;
  const averageBlockTime = getBlockTimeForChainInSeconds(chainId) * 1000; // Get block time in milliseconds

  return new Promise((resolve, reject) => {
    let receipt: TransactionReceipt | null = null;
    let foundReceipt = false;

    // Immediate polling logic
    let immediatePollingCount = 0;
    const MAX_IMMEDIATE_POLLS = averageBlockTime > 5000 ? 5 : 3; // Fewer immediate polls for fast chains like Base
    const IMMEDIATE_POLL_INTERVAL = averageBlockTime > 5000 ? 300 : 100; // Shorter polling interval for fast chains
    const INITIAL_DELAY = averageBlockTime > 5000 ? 3000 : 1000;
    // Immediate polling for faster initial detection
    const immediatePolling = async () => {
      try {
        if (!foundReceipt) {
          receipt = await publicClient.getTransactionReceipt({ hash });
          if (receipt && receipt.blockNumber) {
            console.log('Transaction found via immediate polling');
            foundReceipt = true;
            return;
          }
        }
      } catch (error) {
        if (!(error instanceof TransactionReceiptNotFoundError)) {
          reject(error);
          return;
        }
      }

      if (immediatePollingCount < MAX_IMMEDIATE_POLLS && !foundReceipt) {
        immediatePollingCount++;
        setTimeout(immediatePolling, IMMEDIATE_POLL_INTERVAL);
      }
    };

    // Start immediate polling
    setTimeout(immediatePolling, INITIAL_DELAY);

    // Subscribe to new blocks for confirmation tracking
    const unsubscribe = publicClient.watchBlockNumber({
      onBlockNumber: async (blockNumber) => {
        console.log('New block detected:', blockNumber);

        try {
          // Only fetch the receipt if it hasn't been found yet
          if (!foundReceipt) {
            receipt = await publicClient.getTransactionReceipt({ hash });
            if (receipt && receipt.blockNumber) {
              console.log('Transaction mined in block:', receipt.blockNumber);
              foundReceipt = true; // Mark that we have found the receipt
            } else {
              return; // If receipt is not available, wait for the next block
            }
          }

          // Check for confirmations if the receipt is found
          if (receipt) {
            const confirmations = blockNumber - receipt.blockNumber;

            if (confirmations >= confirmationsRequired) {
              console.log(
                'Transaction confirmed with required confirmations:',
                confirmations
              );

              if (receipt.status === 'reverted') {
                console.warn(
                  'Transaction reverted after being included in a block.'
                );
                cleanup();
                reject(new Error('Transaction reverted after being mined.'));
                return;
              }

              console.log('Transaction receipt:', receipt);
              cleanup();
              resolve(receipt);
            } else {
              console.log(
                `Waiting for more confirmations: ${confirmations}/${confirmationsRequired}`
              );
            }
          }
        } catch (error) {
          console.warn('Error fetching receipt:', error);
          if (!(error instanceof TransactionReceiptNotFoundError)) {
            cleanup();
            reject(error);
          }
        }
      },
      emitOnBegin: true,
      pollingInterval: 1000,
    });

    const cleanup = () => {
      if (unsubscribe) unsubscribe();
      immediatePollingCount = MAX_IMMEDIATE_POLLS; // Stop immediate polling
    };

    // 5 minutes timeout to prevent waiting indefinitely
    setTimeout(
      () => {
        if (!foundReceipt) {
          console.log('Transaction receipt polling timed out.');
          cleanup();
          reject(new Error('Transaction receipt polling timed out.'));
        }
      },
      5 * 60 * 1000
    );
  });
};
