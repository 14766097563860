import { Toaster } from 'react-hot-toast';
import './App.css';
import { Layout } from './components/layout/layout-component';
import { WagmiProvider } from 'wagmi';
import { config } from './config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ModalProvider } from './context/modal-context';
import { BlockchainProvider } from './context/blockchain-context';
import { WebSocketProvider } from './context/websocket-context';
import { ContractsProvider } from './context/contracts-context';
import { MarketPriceProvider } from './context/market-price-context';
import { OrderbookProvider } from './context/orderbook-context';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <BlockchainProvider>
          <WebSocketProvider>
            <ModalProvider>
              <MarketPriceProvider>
                <OrderbookProvider>
                  <Layout />
                  <Toaster
                    toastOptions={{ style: { padding: 0, margin: 0 } }}
                    containerStyle={{ padding: 0, margin: 0 }}
                  />
                </OrderbookProvider>
              </MarketPriceProvider>
            </ModalProvider>
          </WebSocketProvider>
        </BlockchainProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

const AppWithUmaProvider: React.FC = () => (
  <ContractsProvider>
    <App />
  </ContractsProvider>
);

export default AppWithUmaProvider;
