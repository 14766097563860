import { ChangeEvent, useState } from 'react';
import { ReactComponent as Filters } from '../../assets/icons/filter-add.svg';
import RadioInput from '../radio/radio-component';
import Accordion from '../accordion/accordion-component';
import { useTranslation } from 'react-i18next';

interface FilterGroup {
  title: string;
  name: string;
  items: Filter[];
  isOpenedByDefault?: boolean;
}

interface Filter {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  label: string;
  value?: string;
  defaultChecked?: boolean;
}

export interface OnChangeParams extends Filter {
  name: string;
}

export const FiltersComponent = ({
  filters,
  onClear,
  onChange,
}: {
  filters: FilterGroup[];
  onClear: () => void;
  onChange: (filter: OnChangeParams, checked: boolean) => void;
}) => {
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [isCleared, setIsCleared] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div
        onClick={() => setFiltersOpened(!filtersOpened)}
        className={`fixed w-[100%] h-[100%] top-0 left-0 z-0 ${filtersOpened ? 'block' : 'hidden'}`}
      ></div>
      <div className="hidden md:block relative z-10">
        <div
          className="p-4 bg-black-dark rounded-xl cursor-pointer"
          onClick={() => setFiltersOpened(!filtersOpened)}
        >
          <Filters />
        </div>
        <div
          className={`origin-top transition-transform duration-200 ${filtersOpened ? 'scale-y-100' : 'scale-y-0 max-h-0 absolute overflow-hidden'}  bg-black py-4 px-3 text-white absolute rounded-lg border border-grey-secondary mt-2 w-[320px] max-h-[400px] overflow-y-auto`}
        >
          {filters.map((filterGroup, key) => (
            <Accordion
              isOpened={filterGroup.isOpenedByDefault}
              key={`filter-group-${key}`}
              title={filterGroup.title}
            >
              {filterGroup.items.map((filter, key) => (
                <RadioInput
                  key={`filter-group-filter-${key}`}
                  id={filter.id}
                  defaultChecked={filter.defaultChecked}
                  name={filterGroup.name}
                  onFilterChange={(checked, e) => {
                    onChange({ name: filterGroup.name, ...filter }, checked);
                    if (filter.onChange && e) {
                      filter.onChange(e);
                    }
                  }}
                  isCleared={isCleared}
                  label={filter.label}
                />
              ))}
            </Accordion>
          ))}
          <p
            className="font-bold text-sm text-center pt-6 pb-3 cursor-pointer"
            onClick={() => {
              setIsCleared(true);
              setTimeout(() => {
                setIsCleared(false);
              }, 1500);
              onClear();
            }}
          >{t`Clear Filters`}</p>
        </div>
      </div>
    </>
  );
};
