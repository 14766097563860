import { t } from 'i18next';
const explorerUrl = process.env.REACT_APP_EXPLORER_URL;

export const renderTextWithLinks = (text: string): JSX.Element[] => {
  const urlRegex = /https?:\/\/[^\s]+/g;
  const parts = text.split(urlRegex);
  const urls = text.match(urlRegex);

  return parts.reduce((acc: JSX.Element[], part: string, index: number) => {
    acc.push(<span key={`text-${index}`}>{part}</span>);
    if (urls && index < urls.length) {
      const url = urls[index];
      acc.push(
        <a
          key={`link-${index}`}
          href={url}
          target="_blank"
          className="text-brand-blue"
          rel="noopener noreferrer"
        >
          {url}
        </a>
      );
    }
    return acc;
  }, []);
};

export const getTxDetailsUrl = (txHash: string) => (
  <p className="text-center">
    {t`You can check the transaction details`}{' '}
    <a
      href={`${explorerUrl}${txHash}`}
      target="_blank"
      className="text-brand-blue"
      rel="noopener noreferrer"
    >
      {t`here`}
    </a>
    .
  </p>
);
