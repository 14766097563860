import { useTranslation } from 'react-i18next';
import { Button } from '../../components/button/button-component';
import { useModal } from '../../context/modal-context';
import { ReactComponent as Avatar } from '../../assets/icons/avatar.svg';
import restService from '../../helpers/rest-service-helper';
import { useLocation, useNavigate } from 'react-router-dom';

export const CancelOrderModal = ({
  title,
  image,
  orderId,
}: {
  title: string;
  image: string | null;
  orderId: string;
}) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="flex flex-col text-sm gap-6 text-center">
      <p className="text-text-secondary">
        {t`This will Cancel the following order`}
      </p>
      <div className="flex rounded-2xl border border-grey-secondary text-white text-sm font-bold p-3 gap-4 items-center">
        <div className="max-w-10  h-10">
          {image ? (
            <img
              className="rounded-lg object-cover w-[100%] h-[100%]"
              src={image}
            />
          ) : (
            <Avatar className="min-w-[56px] min-h-[56px] bg-grey rounded-xl p-2" />
          )}
        </div>
        <p>{title}</p>
      </div>
      <Button
        variant="blue"
        onClick={async () => {
          await restService.post(`/orders/${orderId}/cancel`);
          closeModal();
          navigate(location.pathname, {
            state: { refetch: !(location?.state?.refetch ?? false) },
          });
        }}
      >{t`Confirm`}</Button>
      <Button
        variant="transparent"
        onClick={closeModal}
        className="text-text-secondary"
      >
        {t`Cancel`}
      </Button>
    </div>
  );
};
