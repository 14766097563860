import { useNavigate } from 'react-router-dom';
import { Market, MarketResolvedTo } from '../../types/types';
import { FormatChance } from '../../helpers/chance-helper';
import { ResolvedMarketCardComponent } from './resolved-market-card-component';
import { MarketResolution } from '../market-resolution/market-resolution-component';

export const MultiOutcome = ({
  eventSlug,
  markets,
}: {
  eventSlug?: string;
  markets?: Market[];
}) => {
  const navigate = useNavigate();

  return (
    <div className="max-h-[120px] h-full overflow-y-auto pb-2">
      {(markets ?? []).map((market, key) => (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(`/market/${eventSlug}/${market.slug}`);
          }}
          className={`pr-3 pt-2 hover:text-white cursor-pointer ${!market.isResolved ? 'hover:underline flex justify-between' : 'md:grid md:grid-cols-2 md:gap-1 md:pr-0'}`}
          key={`participants-${key}`}
        >
          <span>{market.name}</span>
          {market.isResolved ? (
            <ResolvedMarketCardComponent
              isMultiMarket
              marketResolvedTo={market.marketResolvedTo as MarketResolvedTo}
              outcome1={market.outcome1 as string}
              outcome2={market.outcome2 as string}
            />
          ) : (
            <MarketResolution resolution={market.outcome1_chance ?? '0'} />
          )}
        </div>
      ))}
    </div>
  );
};
