import React, { createContext, useState, ReactNode, useContext } from 'react';

// Define the shape of the blockchain data
interface BlockchainData {
  evmAddress: string | null;
  proxyWalletAddress: string | null;
  setEvmAddress: (evmAddress: string | null) => void;
  setProxyWalletAddress: (address: string | null) => void;
  proxyWalletSetup: boolean;
  setProxyWalletSetup: (proxyWalletSetup: boolean) => void;
  showConfirmDepositHeading: boolean;
  setShowConfirmDepositHeading: (showConfirmDepositHeading: boolean) => void;
  showApproveTokensHeading: boolean;
  setApproveTokensHeading: (showApproveTokensHeading: boolean) => void;
  userId: string | null;
  setUserId: (userId: string | null) => void;
  username: string | null;
  setUsername: (username: string | null) => void;
  imageURL: string | null;
  setImageURL: (userId: string | null) => void;
}

// Create the context with default values
const BlockchainContext = createContext<BlockchainData | undefined>(undefined);

// Define the provider component
export const BlockchainProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [evmAddress, setEvmAddress] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const [proxyWalletAddress, setProxyWalletAddress] = useState<string | null>(
    null
  );
  const [proxyWalletSetup, setProxyWalletSetup] = useState<boolean>(false);

  const [showConfirmDepositHeading, setShowConfirmDepositHeading] =
    useState(false);
  const [showApproveTokensHeading, setApproveTokensHeading] = useState(false);

  return (
    <BlockchainContext.Provider
      value={{
        evmAddress,
        proxyWalletAddress,
        setEvmAddress,
        setProxyWalletAddress: (proxyWalletAddress) =>
          setProxyWalletAddress(proxyWalletAddress ?? ''.toLowerCase()),
        proxyWalletSetup,
        setProxyWalletSetup,
        showConfirmDepositHeading,
        setShowConfirmDepositHeading,
        showApproveTokensHeading,
        setApproveTokensHeading,
        userId,
        setUserId,
        username,
        setUsername,
        imageURL,
        setImageURL,
      }}
    >
      {children}
    </BlockchainContext.Provider>
  );
};

// Create a custom hook for easier consumption of the context
export const useBlockchain = (): BlockchainData => {
  const context = useContext(BlockchainContext);
  if (context === undefined) {
    throw new Error('useBlockchain must be used within a BlockchainProvider');
  }
  return context;
};
