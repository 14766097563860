import { Decimal } from 'decimal.js';
import restService from '../../helpers/rest-service-helper';
import { Market, Order } from '../../types/types';

export const getOrders = async (
  market: Market,
  status: string,
  maker?: string
) => {
  let data = await restService.get<Order[]>(
    `/orders?market=${market.id}&status=${status}${maker ? '&owner=' + maker : ''}`
  );
  data = data.map((order) => {
    return {
      ...order,
      amount_left: Decimal.sub(order.amount, order.matchedAmount).toNumber(),
    };
  });

  const { orders1, orders2 } = createOpositeOrders(
    data,
    market.tokenId1,
    market.tokenId2
  );

  return { orders1, orders2, data };
};
export const createOpositeOrders = (
  orders: Order[],
  tokenId1: string,
  tokenId2: string
) => {
  const ordersOutcome1 = orders.filter((order) => order.tokenId === tokenId1);

  const ordersOutcome2 = orders.filter((order) => order.tokenId === tokenId2);

  const orders1: Order[] = [
    ...ordersOutcome1,
    ...ordersOutcome2.map((order) => ({
      ...order,
      side: order.side === 0 ? 1 : 0,
      tokenId: tokenId1 ?? '',
      price: Math.round((1 - order.price) * 1e6) / 1e6,
    })),
  ];
  const orders2: Order[] = [
    ...ordersOutcome2,
    ...ordersOutcome1.map((order) => ({
      ...order,
      side: order.side === 0 ? 1 : 0,
      tokenId: tokenId2 ?? '',
      price: Math.round((1 - order.price) * 1e6) / 1e6,
    })),
  ];
  return { orders1, orders2 };
};

export const markeBuySellOrders = (orders: Order[], market: Market) => {
  let prevTotal = 0;
  let prevComulativeShares = 0;
  const sellOrdersDataToken1 = orders
    .filter((order) => order.side === 1)
    .filter((order) => order.tokenId === market.tokenId1)
    .sort((a: Order, b: Order) => a.price - b.price)
    .map((item) => ({
      ...item,
      total: (prevTotal += item.price * item.amount_left),
      comulativeShares: (prevComulativeShares += item.amount_left),
    }))
    .sort((a: Order, b: Order) => b.price - a.price);

  prevTotal = 0;
  prevComulativeShares = 0;

  const buyOrdersDataToken1 = orders
    .filter((order) => order.side === 0)
    .filter((order) => order.tokenId === market.tokenId1)
    .map((item) => ({
      ...item,
      total: (prevTotal += item.price * item.amount_left),
      comulativeShares: (prevComulativeShares += item.amount_left),
    }));
  prevTotal = 0;
  prevComulativeShares = 0;
  const sellOrdersDataToken2 = orders
    .filter((order) => order.side === 1)
    .filter((order) => order.tokenId === market.tokenId2)
    .sort((a: Order, b: Order) => a.price - b.price)
    .map((item) => ({
      ...item,
      total: (prevTotal += item.price * item.amount_left),
      comulativeShares: (prevComulativeShares += item.amount_left),
    }))
    .sort((a: Order, b: Order) => b.price - a.price);

  prevTotal = 0;
  prevComulativeShares = 0;
  const buyOrdersDataToken2 = orders
    .filter((order) => order.side === 0)
    .filter((order) => order.tokenId === market.tokenId2)
    .map((item) => ({
      ...item,
      comulativeShares: (prevComulativeShares += item.amount_left),
      total: (prevTotal += item.price * item.amount_left),
    }));
  return {
    [market.outcome1]: {
      buyOrdersData: buyOrdersDataToken1,
      sellOrdersData: sellOrdersDataToken1,
    },
    [market.outcome2]: {
      buyOrdersData: buyOrdersDataToken2,
      sellOrdersData: sellOrdersDataToken2,
    },
  };
};

export const groupOrdersByPrice = (orders: Order[]): Order[] => {
  const groupedOrders = new Map<string, Order>();

  orders.forEach((order) => {
    // Create a composite key using price and side
    const key = `${order.price}-${order.side}`;

    if (groupedOrders.has(key)) {
      const group = groupedOrders.get(key);
      if (group) {
        group.amount_left += order.amount_left; // Accumulate amount_left
        group.amount += order.amount; // Accumulate amount
        group.matchedAmount += order.matchedAmount; // Accumulate matchedAmount
        group.owners = Array.from(
          new Set([...(group.owners ?? []), order.owner])
        );
      }
    } else {
      // If not grouped yet, create a new entry
      groupedOrders.set(key, { ...order, owners: [order.owner] });
    }
  });

  // Convert the map to an array
  return Array.from(groupedOrders.values())
    .sort((a: Order, b: Order) => b.price - a.price)
    .map((order) => ({
      ...order,
      total: order.price * order.amount_left,
    }))
    .filter((order) => order.price !== 1 && order.price !== 0);
};

export const getOrderbookLoading = (orders: Order[], tokenId?: string) => {
  return {
    sell:
      orders.filter((order) => order.side === 1 && order.tokenId === tokenId)
        .length > 0,
    buy:
      orders.filter((order) => order.side === 0 && order.tokenId === tokenId)
        .length > 0,
  };
};
