import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ExpandableComponent } from '../../components/expandable/expandable-component';
import { PaginatedResponse, UserActivity } from '../../types/types';
import CustomTable from '../../components/custom-table/custom-table-component';
import { PortfolioTableHeader } from './PortfolioTableHeader';
import { useUserActivity } from '../../hooks/porfolio-hook';
import { getElapsedTime } from '../../helpers/date-helper';
import { formatUnits } from 'viem';
import { FormatCurrency } from '../../helpers/currency-helper';
import { NavLink } from 'react-router-dom';
import { useBlockchain } from '../../context/blockchain-context';
import { isBuyOrSell, isSplitOrMergeOrRedeem } from '../../helpers/checkers';

interface History {
  market: string;
  image: string;
  type: string;
  outcome: string;
  price: string;
  shares: string;
  value?: string;
  date: string;
  bgColor?: string;
  textColor?: string;
}

export const HistoryTable = () => {
  const [tableKey, setTableKey] = useState('initialKey');
  const { t } = useTranslation();
  const { proxyWalletSetup } = useBlockchain();
  const [sortBy, setSortBy] = useState('timestamp');
  const [order, setOrder] = useState<'DESC' | 'ASC'>('DESC');
  const [history, setHistory] = useState<PaginatedResponse<History>>();
  const [term, setTerm] = useState<string>();
  const [status, setStatus] = useState<string>();
  const [page, setPage] = useState(1);
  const { userId } = useBlockchain();

  const transformUserActivity = (activities: UserActivity[]): History[] => {
    return activities.map((activity) => ({
      market: activity.name,
      image: activity.image,
      type: activity.type,
      shares: activity.amount,
      ...(isBuyOrSell(activity.type)
        ? {
            bgColor:
              activity.partition[0] === 1
                ? 'bg-green-opacity'
                : 'bg-red-opacity',
            textColor:
              activity.partition[0] === 1
                ? 'text-green-text'
                : 'text-red-error',
          }
        : {}),
      outcome: isSplitOrMergeOrRedeem(activity.type)
        ? '-'
        : activity.partition[0] === 1
          ? activity.outcome1
          : activity.outcome2,
      value: activity.amount,
      date: new Date(+activity.timestamp * 1000).toISOString(),
      id: activity.id,
      slug: activity.slug,
      price: isBuyOrSell(activity.type) ? activity.price : '-',
    }));
  };

  const useHistory = async (
    page: number,
    pageSize: number,
    term?: string,
    status?: string,
    sortBy?: string,
    order?: 'DESC' | 'ASC'
  ): Promise<PaginatedResponse<History>> => {
    if (!proxyWalletSetup) {
      return {
        data: [],
        first: 0,
        last: 0,
        limit: 0,
        total: 0,
      };
    }
    const userActivity = await useUserActivity(
      page,
      pageSize,
      term,
      status,
      sortBy,
      order
    );

    return {
      ...userActivity,
      data: transformUserActivity(userActivity.data),
    };
  };

  const fetchHistoryData = async (currentPage: number, rowsPerPage: number) => {
    const userActivity = await useUserActivity(
      currentPage,
      rowsPerPage,
      term,
      status,
      sortBy,
      order
    );

    const transformedData = transformUserActivity(userActivity.data);

    return {
      ...userActivity,
      data: transformedData,
    };
  };

  useEffect(() => {
    const fetchAndUpdateHistory = async () => {
      try {
        const fetchedHistory = await fetchHistoryData(page, 10);
        const isFirstPage = page === 1;

        const updatedData = isFirstPage
          ? fetchedHistory.data
          : [...(history?.data ?? []), ...fetchedHistory.data];

        setHistory({
          ...fetchedHistory,
          data: updatedData,
        });
      } catch (error) {
        console.log('Error fetching history data:', error);
      }
    };

    fetchAndUpdateHistory();
  }, [page, term, sortBy]);

  return (
    <div className="flex flex-col w-[100%] gap-4 mt-4">
      {history?.data && (
        <>
          <PortfolioTableHeader
            onChange={(value) => {
              setSortBy(value.split('-')[0]);
              setOrder(
                (value.split('-')?.[1].toUpperCase() ?? 'DESC') as
                  | 'ASC'
                  | 'DESC'
              );
              setPage(1);
              setTableKey(`user-activity-${value}`);
            }}
            onSearch={(term) => {
              setTerm(term);
              setPage(1); // Reset page to 1 when search term changes
            }}
            statusOptions={[
              {
                value: 'timestamp-desc',
                label: t`Newest`,
              },
              {
                value: 'timestamp-asc',
                label: t`Oldest`,
              },
              {
                value: 'amount-desc',
                label: t`Share`,
              },
            ]}
          />
          <div className="hidden md:block">
            {userId && (
              <CustomTable
                key={tableKey}
                setTableKey={setTableKey}
                align="center"
                rowClasses="h-[81px]"
                emptyState={
                  <tbody>
                    <tr>
                      <td className="py-6 text-center" colSpan={8}>
                        <div className="grid gap-2">
                          <p className="text-white text-base font-bold">{t`History is empty.`}</p>
                          <p className="text-text-secondary text-sm">{t`This section shows all your activity.`}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                }
                columns={[
                  {
                    id: 'market',
                    label: t('Market'),
                    align: 'left',
                    render: (item) => {
                      return (
                        <NavLink to={`/market/${item?.slug}/${item?.id}`}>
                          <div className="flex gap-4 items-center">
                            <div className="max-w-10  h-10">
                              <img
                                className="rounded-lg object-cover w-[100%] h-[100%]"
                                src={item?.image}
                              />
                            </div>
                            {item?.market}
                          </div>
                        </NavLink>
                      );
                    },
                  },
                  {
                    id: 'type',
                    label: t('Type'),
                    render: (item) => (
                      <span>
                        {item?.type.charAt(0).toUpperCase()}
                        {item?.type.slice(1).toLowerCase()}
                      </span>
                    ),
                  },
                  {
                    id: 'outcome',
                    label: t('Outcome'),
                    render: (item) => {
                      return (
                        <span
                          className={`p-2 rounded-md ${item?.bgColor} ${item?.textColor}`}
                        >
                          {item?.outcome}
                        </span>
                      );
                    },
                  },
                  {
                    id: 'price',
                    label: t('Price'),
                    render: (item) => (
                      <>
                        {item?.price !== '-' ? (
                          <>{Math.floor(item?.price * 100)}¢</>
                        ) : (
                          item.price
                        )}
                      </>
                    ),
                  },
                  {
                    id: 'shares',
                    label: t('Shares'),
                    render: (item) => (
                      <>
                        {parseFloat(
                          formatUnits(item?.shares as unknown as bigint, 6)
                        ).toFixed(2)}
                      </>
                    ),
                  },
                  {
                    id: 'value',
                    label: t('Value'),
                    type: 'currency',
                    currency: {
                      isAmount: false,
                    },
                    render: (item) => (
                      <>
                        {FormatCurrency(
                          +parseFloat(
                            formatUnits(item?.value as unknown as bigint, 6)
                          ).toFixed(2),
                          false
                        )}
                      </>
                    ),
                  },
                  {
                    id: 'date',
                    label: t('Date'),
                    render: (item) => (
                      <span className="text-text-secondary">
                        {getElapsedTime(item?.date)}
                      </span>
                    ),
                  },
                ]}
                hook={(page, pageSize) =>
                  useHistory(page, pageSize, term, status, sortBy, order)
                }
              />
            )}
          </div>
          {userId && (
            <>
              {history.data.length === 0 ? (
                <div className="py-6 text-center block md:hidden">
                  <div className="grid gap-2">
                    <p className="text-white text-base font-bold">{t`History is empty.`}</p>
                    <p className="text-text-secondary text-sm">{t`This section shows all your activity.`}</p>
                  </div>
                </div>
              ) : (
                <div id="scrollableContainer" style={{ overflowY: 'auto' }}>
                  <InfiniteScroll
                    scrollableTarget="scrollableContainer"
                    dataLength={(history?.data ?? []).length}
                    next={() => setPage(page + 1)}
                    hasMore={history?.last !== history?.total}
                    loader={<></>}
                    className="bg-black-dark md:hidden flex flex-col gap-2 rounded-2xl p-4"
                  >
                    {history?.data?.length > 0 &&
                      history.data.map((item, index) => (
                        <ExpandableComponent
                          key={`history-${index}`}
                          title={item?.market}
                          logo={item?.image}
                          items={
                            <>
                              <div className="flex justify-between pb-4 px-4 items-center ">
                                <div className="flex gap-2 items-center">
                                  <span>{item?.type}</span>
                                  <span
                                    className={`${item.textColor} ${item.bgColor} rounded-md p-1`}
                                  >
                                    {isBuyOrSell(item?.type) && item?.outcome}
                                  </span>
                                </div>
                                <span>
                                  {FormatCurrency(
                                    parseFloat(
                                      formatUnits(
                                        item?.value as unknown as bigint,
                                        6
                                      )
                                    ),
                                    false,
                                    2
                                  )}
                                </span>
                              </div>
                              <div className="flex justify-between pb-4 px-4 items-center text-text-secondary">
                                <div>
                                  <span>
                                    {parseFloat(
                                      formatUnits(
                                        item?.shares as unknown as bigint,
                                        6
                                      )
                                    ).toFixed(2)}{' '}
                                    shares{' '}
                                  </span>
                                  {isBuyOrSell(item?.type) && (
                                    <span>
                                      at{' '}
                                      {item?.price !== '-'
                                        ? `${parseFloat(item?.price).toFixed(2)}¢`
                                        : item.price}
                                    </span>
                                  )}
                                </div>
                                <span>{getElapsedTime(item?.date)}</span>
                              </div>
                            </>
                          }
                        />
                      ))}
                  </InfiniteScroll>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
