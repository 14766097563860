import React from 'react';
import { ReactComponent as Home } from '../../assets/icons/home-2.svg';
import { ReactComponent as Markets } from '../../assets/icons/bottom-bar-markets.svg';
import { ReactComponent as Search } from '../../assets/icons/bottom-bar-search.svg';
import { ReactComponent as Portfolio } from '../../assets/icons/bottom-bar-portfolio.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './bottom-bar-component.css';

const BottomBar: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-black-dark   flex py-2 px-6 justify-between ">
      <NavLink
        to="/"
        className={`flex flex-col gap-1 items-center ${location.pathname === '/' ? 'text-white' : 'text-bottom-bar-text'}`}
      >
        <Home
          className={`${location.pathname === '/' ? 'fill-active' : 'fill-inactive'}`}
        />
        <span className="text-sm">{t`Home`}</span>
      </NavLink>
      <NavLink
        to="/market-overview"
        className={`flex flex-col gap-1 items-center ${location.pathname.startsWith('/market-overview') ? 'text-white' : 'text-bottom-bar-text'}`}
      >
        <Markets
          className={`${location.pathname.startsWith('/market-overview') ? 'fill-active' : 'fill-inactive'}`}
        />
        <span className="text-sm">{t`Markets`}</span>
      </NavLink>
      <NavLink
        to="/search"
        className={`flex flex-col gap-1 items-center ${location.pathname.startsWith('/search') ? 'text-white' : 'text-bottom-bar-text'}`}
      >
        <Search
          className={`${location.pathname.startsWith('/search') ? 'fill-active' : 'fill-inactive'}`}
        />
        <span className="text-sm">{t`Search`}</span>
      </NavLink>
      <NavLink
        to="/portfolio"
        className={`flex flex-col gap-1 items-center ${location.pathname.startsWith('/portfolio') ? 'text-white' : 'text-bottom-bar-text'}`}
      >
        <Portfolio
          className={`${location.pathname.startsWith('/portfolio') ? 'fill-active' : 'fill-inactive'}`}
        />
        <span className="text-sm">{t`Portfolio`}</span>
      </NavLink>
    </div>
  );
};

export default BottomBar;
