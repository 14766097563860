import { t } from 'i18next';
import { Button } from '../../components/button/button-component';
import { CancelOrderModal } from '../portfolio/CancelOrderModal';
import { ReactComponent as X } from '../../assets/icons/close-white.svg';
import { useModal } from '../../context/modal-context';
import { Market, Order } from '../../types/types';
import { getRemainingTime } from '../../helpers/date-helper';
import { FormatCurrency } from '../../helpers/currency-helper';
import { getOutcomeColors } from '../../helpers/resolved-market-helper';

export const OpenOrders = ({
  market,
  orders,
}: {
  market: Market;
  orders: Order[];
}) => {
  const { openModal } = useModal();

  return (
    <>
      {orders.length > 0 && (
        <div className="bg-black-dark p-4 rounded-2xl text-sm overflow-auto">
          <div className="min-w-[400px] md:min-w-[100%]">
            <p className="text-xl text-white font-bold border-b border-grey-secondary pb-4">
              {t`Open Orders`}
            </p>
            <div className="grid px-4 grid-cols-7 border-b border-grey-secondary py-4 items-center text-text-secondary text-sm">
              <div>{t`Side`}</div>
              <div>{t`Outcome`}</div>
              <div>{t`Price`}</div>
              <div>{t`Filled`}</div>
              <div>{t`Total`}</div>
              <div>{t`Expiration`}</div>
              <div className="justify-self-center">{t`Cancel`}</div>
            </div>
            <>
              <div className="text-white px-4 text-sm">
                {orders.map((order, key) => (
                  <div
                    className="py-4 grid grid-cols-7 items-center gap-4 border-b border-grey-secondary"
                    key={`open-order-${key}`}
                  >
                    <div>{order.side === 1 ? t`Sell` : t`Buy`}</div>
                    <div
                      className={`${getOutcomeColors(order.outcome, true)} px-4`}
                    >
                      {order.outcome}
                    </div>
                    <div>{(order.price * 100).toFixed(0)}¢</div>
                    <div>
                      {order.matchedAmount.toFixed(2)}/{order.amount.toFixed(2)}
                    </div>
                    <div>
                      {FormatCurrency(order.amount * order.price, false, 2)}
                    </div>
                    <div>
                      {order.expiration
                        ? getRemainingTime(
                            new Date(order.expiration).toISOString()
                          )
                        : t`Until Canceled`}
                    </div>
                    <div className="text-center">
                      <Button
                        onClick={() => {
                          openModal({
                            title: 'Cancel Order?',
                            content: (
                              <CancelOrderModal
                                orderId={order.id}
                                title={market.name}
                                image={market.image}
                              />
                            ),
                          });
                        }}
                        className="min-w-10 min-h-10 py-3 px-2 text-center justify-center flex mx-auto"
                        size="custom"
                        variant="red"
                      >
                        <X />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
};
