import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Button } from '../button/button-component';
import { FormatCurrency } from '../../helpers/currency-helper';
import { PaginatedResponse } from '../../types/types';
import { getElapsedTime } from '../../helpers/date-helper';
import InfiniteScroll from 'react-infinite-scroll-component';

interface Column {
  id: string;
  label: string;
  align?: 'left' | 'right' | 'center';
  render?: (row?: RowData) => ReactNode;
  currency?: {
    isAmount: boolean;
  };
  type?: 'text' | 'boolean' | 'currency' | 'date' | 'index';
}

export interface RowData {
  [key: string]: any;
}

interface Action<T> {
  label?: string;
  icon?: ReactNode;
  handler: (row: T[]) => void;
  show?: (row: T) => boolean;
  disabled?: (row: T) => boolean;
  type?: 'confirm';
}

interface CustomTableProps<T> {
  columns: Column[];
  selectable?: boolean;
  hook: (
    page: number,
    rowsPerPage: number,
    prevData?: T[]
  ) => Promise<PaginatedResponse<T>>; // Asynchronous hook function
  actions?: Action<T>[];
  tableKey?: string;
  setTableKey?: Dispatch<SetStateAction<string>>;
  hasPagination?: boolean;
  rowClasses?: string;
  emptyState?: ReactNode;
  align?: 'left' | 'right' | 'center';
}

const CustomTable = <T,>({
  columns,
  hook,
  actions,
  tableKey,
  rowClasses = 'bg-black-light',
  emptyState,
  align,
}: CustomTableProps<T>) => {
  const [paginatedRows, setPaginatedRows] = useState<T[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  // Handler for executing an action
  const handleAction = async (row: T, action: Action<T>) => {
    await action.handler([row]);
  };

  const [pagination, setPagination] = useState<PaginatedResponse<T>>();

  useEffect(() => {
    hook(page, 10, paginatedRows)
      .then((response) => {
        setPaginatedRows(
          response.first <= 1
            ? response.data
            : [...paginatedRows, ...response.data]
        );
        setPagination(response);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.error('Error fetching rows:', error);
      });
  }, [hook, tableKey, page]);

  const renderCell = (row: RowData, column: Column) => {
    if (column.render) {
      return column.render(row);
    } else {
      switch (column.type) {
        case 'currency':
          return row[column.id]
            ? FormatCurrency(row[column.id], column?.currency?.isAmount)
            : '-';
        case 'date':
          return getElapsedTime(row[column.id]);
        default:
          return row[column.id];
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          className="spinner m-auto"
          style={{
            width: 24,
            height: 24,
            border: '4px solid #fff',
            borderColor: '#fff transparent #fff transparent',
          }}
        ></div>
      ) : (
        <InfiniteScroll
          className={` ${isLoading ? 'invisible' : ''}`}
          dataLength={paginatedRows.length}
          next={() => setPage(page + 1)}
          hasMore={pagination?.last !== pagination?.total}
          loader={<></>}
          // scrollableTarget="root"
        >
          <div className="relative overflow-x-auto sm:rounded-lg">
            <table className="w-[100%] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="bg-black-dark font-medium text-text-secondary">
                <tr className={`${align ? 'text-' + align : ''}`}>
                  {columns.map((column, colIndex) => (
                    <th
                      key={`col-${colIndex}-${column.id}`}
                      scope="col"
                      className={`py-3 px-2 rounded-${colIndex === 0 ? 'tl' : 'tr'}-lg ${column.align ? 'text-' + column.align : ''}`}
                    >
                      {column.label}
                    </th>
                  ))}
                  {actions && actions.length > 0 && (
                    <th scope="col" className="py-3 px-2">
                      Action
                    </th>
                  )}
                </tr>
              </thead>

              <>
                {paginatedRows.length > 0 && !isLoading ? (
                  <tbody>
                    {paginatedRows.map((row, rowIndex) => (
                      <tr
                        key={`row-${rowIndex}`}
                        className={`${rowIndex === 0 ? 'border-t' : ''} ${align ? 'text-' + align : ''} ${rowClasses} border-b border-grey-secondary`}
                      >
                        {columns.map((column, i) => (
                          <td
                            key={`cell-${rowIndex}-${column.id}-${i}`}
                            scope="row"
                            align={column.align}
                            className="py-3 px-2 text-sm text-white whitespace-nowrap font-medium"
                          >
                            {renderCell(row as RowData, column)}
                          </td>
                        ))}
                        {actions && (
                          <td align="right">
                            {(actions ?? []).map((action, actionIndex) => (
                              <Button
                                disabled={
                                  action.disabled &&
                                  action.disabled(row) === true
                                }
                                key={`action-${rowIndex}-${actionIndex}`}
                                onClick={async () => {
                                  await handleAction(row, action);
                                }}
                              >
                                {action.label}
                                {action.icon}
                              </Button>
                            ))}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                ) : emptyState ? (
                  emptyState
                ) : (
                  <p>No data</p>
                )}
              </>
            </table>
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

export default CustomTable;
