export const FormatCurrency = (
  amount: number,
  isAmount = true,
  decimalPoint?: number
) => {
  let formattedNumber: string | number = amount / (isAmount ? 100 : 1);
  if (Number.isInteger(formattedNumber)) {
    formattedNumber = formattedNumber.toFixed(2);
  }
  if (decimalPoint) {
    formattedNumber = (+formattedNumber).toFixed(decimalPoint);
  }
  if (isNaN(formattedNumber as number)) {
    return '-';
  }
  return <>${formattedNumber}</>;
};
