import { FormatCurrency } from '../../helpers/currency-helper';
import { useTranslation } from 'react-i18next';
import { OrderBookTabs } from '../order-book-tabs-component/order-book-tabs-component';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Market } from '../../types/types';
import { useOrders } from '../../context/orderbook-context';
import { ReactComponent as ArrowLimitOrder } from '../../assets/icons/arrow-limit-order.svg';
import { useBlockchain } from '../../context/blockchain-context';

export const OrderBookGraph = ({
  variant,
  market,
  selectedOutcome,
  setSelectedOutcome,
}: {
  variant: 'multiOutcome' | 'singleOutcome';
  market: Market;
  selectedOutcome?: string;
  setSelectedOutcome: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const { t } = useTranslation();
  const { userId } = useBlockchain();
  const { buySellOrders, orderbookLoading } = useOrders();
  const [highlightedOrderId, setHighlightedOrderId] = useState<string>();
  const timeoutRefs = useRef<NodeJS.Timeout[]>([]); // To store timeout IDs
  const blinkStateThreeTimes = (orderId: string) => {
    clearAllTimeouts(); // Prevent stacking

    for (let i = 0; i < 6; i++) {
      const timeout = setTimeout(() => {
        setHighlightedOrderId((prevState) =>
          !prevState ? orderId : undefined
        );
      }, i * 300); // Toggle every 500ms
      timeoutRefs.current.push(timeout);
    }
  };

  const clearAllTimeouts = () => {
    timeoutRefs.current.forEach((timeout) => clearTimeout(timeout));
    timeoutRefs.current = [];
  };
  const getSpreadPrice = () =>
    ((buySellOrders.sell.length > 0
      ? buySellOrders.sell[buySellOrders.sell.length - 1]?.price
      : 0) -
      (buySellOrders.buy?.[0]?.price ?? 0)) *
    100;

  useEffect(() => {
    const highligtOrder = buySellOrders.all.find(
      (order) => new Date().getTime() - (order.highlightTimestamp ?? 0) <= 5000
    );
    if (highligtOrder) {
      blinkStateThreeTimes(highligtOrder.id);
    } else {
      setHighlightedOrderId(undefined);
    }
  }, [buySellOrders.all]);

  return (
    <div className="bg-black-dark p-4 rounded-2xl text-sm">
      <div className="min-w-[400px] md:min-w-[100%]">
        {variant === 'singleOutcome' && (
          <>
            <p className="text-xl text-white font-bold border-b border-grey-secondary pb-3 mb-2">
              {t`Order Book`}
            </p>
            <div className="my-4">
              {market.outcome1 && market.outcome2 && (
                <OrderBookTabs
                  selectedOutcome={selectedOutcome}
                  setSelectedOutcome={setSelectedOutcome}
                  onChange={(tab) => {
                    setSelectedOutcome(tab.id);
                  }}
                  tabs={[
                    { id: market.outcome1, title: market.outcome1 },
                    { id: market.outcome2, title: market.outcome2 },
                  ]}
                />
              )}
            </div>
          </>
        )}

        {variant === 'multiOutcome' && (
          <>
            <div className="my-4">
              {market.outcome1 && market.outcome2 && (
                <OrderBookTabs
                  selectedOutcome={selectedOutcome}
                  setSelectedOutcome={setSelectedOutcome}
                  onChange={(tab) => {
                    setSelectedOutcome(tab.id);
                  }}
                  tabs={[
                    { id: market.outcome1, title: market.outcome1 },
                    { id: market.outcome2, title: market.outcome2 },
                  ]}
                />
              )}
            </div>
          </>
        )}

        <div className="grid grid-cols-2 border-b border-grey-secondary pb-2 items-center font-bold">
          <div className="text-grey-secondary-darker">
            {t`Trade`} {selectedOutcome}
          </div>
          <div className="grid grid-cols-3">
            <div className="text-grey-secondary-darker">{t`Price`}</div>
            <div className="text-grey-secondary-darker">{t`Shares`}</div>
            <div className="text-grey-secondary-darker">{t`Total`}</div>
          </div>
        </div>
        <div className="overflow-auto max-h-[420px]">
          {buySellOrders.sell.map((order, key) => {
            return (
              <div
                key={`red-item-${key}`}
                className={`grid grid-cols-2 hover:bg-red-opacity ${order.id === highlightedOrderId ? 'bg-red-opacity' : ''} py-[6px] gap-4 items-center relative`}
              >
                <div
                  style={{ width: `${order.barWidth}%` }}
                  className={'bg-red-error-opacity h-[36px] '}
                ></div>
                <div className="grid grid-cols-3">
                  <div className="text-red-error flex gap-1 items-center -ml-2">
                    {userId &&
                      (order.owners ?? [order.owner]).includes(userId) && (
                        <ArrowLimitOrder className="fill-red" />
                      )}
                    {(order.price * 100).toFixed(0)}¢
                  </div>
                  <div className="text-white">
                    {order?.amount_left.toFixed(
                      order?.amount_left % 1 !== 0 ? 2 : 0
                    )}
                  </div>
                  <div className="text-white">
                    {FormatCurrency(order.total ?? 0, false, 2)}
                  </div>
                  {orderbookLoading[
                    selectedOutcome === market.outcome1 ? 'token1' : 'token2'
                  ].sell === true && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm animate-pulse z-10 pointer-events-none"></div>
                  )}
                </div>
              </div>
            );
          })}

          <div className="grid grid-cols-2 border-t border-b border-grey-secondary py-3 gap-4 items-center">
            <div className="text-grey-secondary-darker">
              {t`Last`}{' '}
              {(
                (selectedOutcome === market.outcome1
                  ? buySellOrders.sell.length > 0
                    ? buySellOrders.sell[buySellOrders.sell.length - 1]?.price
                    : 0
                  : buySellOrders.buy?.[0]?.price ?? 0) * 100
              ).toFixed(0)}
              ¢
            </div>
            <div className="text-grey-secondary-darker">
              {t`Spread`} {getSpreadPrice().toFixed(2)} ¢
            </div>
          </div>
          {buySellOrders.buy.map((order, key) => (
            <div
              key={`green-item-${key}`}
              className={`grid grid-cols-2 py-[6px] hover:bg-green-opacity ${order.id === highlightedOrderId ? 'bg-green-opacity' : ''} gap-4 items-center relative`}
            >
              <div
                style={{ width: `${order.barWidth}%` }}
                className={'bg-green-light-opacity h-[36px]'}
              ></div>
              <div className="grid grid-cols-3">
                <div className="text-green-light flex gap-1 items-center -ml-2">
                  {userId &&
                    (order.owners ?? [order.owner]).includes(userId) && (
                      <ArrowLimitOrder className="fill-green" />
                    )}
                  {(order.price * 100).toFixed(0)}¢
                </div>
                <div className="text-white">
                  {order.amount_left &&
                    order.amount_left.toFixed(
                      order.amount_left % 1 !== 0 ? 2 : 0
                    )}
                </div>
                <div className="text-white">
                  {' '}
                  {FormatCurrency(order.total ?? 0, false, 2)}
                </div>
                {orderbookLoading[
                  selectedOutcome === market.outcome1 ? 'token1' : 'token2'
                ].buy === true && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm animate-pulse z-10 pointer-events-none"></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
