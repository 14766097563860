import { Link, useLocation } from 'react-router-dom';
import { MarketsCategories } from '../../components/markets-categories/markets-categories-component';
import { useTranslation } from 'react-i18next';
import { MarketParams } from '../../types/types';
import { Dispatch, SetStateAction } from 'react';
import { Button } from '../../components/button/button-component';
import { ReactComponent as NoResults } from '../../assets/icons/no-results.svg';
import gameThumb from '../../assets/game-thumb.png';
import { EventCard } from '../../components/market-card/event-card-component';
import { useEventsData } from '../../hooks/markets-hook';
import { calculateTotalBetSum } from '../../helpers/calculate-helper';
import { EventCardSkeleton } from '../../components/event-card-skeleton/event-card-skeleton';
import InfiniteScroll from '../../components/infinite-scroll/infinite-scroll-component';

export const MarketOverviewPage = ({
  hasInfiniteScroll = true,
  marketParams,
  setMarketParams,
}: {
  marketParams: MarketParams;
  setMarketParams: Dispatch<SetStateAction<MarketParams>>;
  hasInfiniteScroll?: boolean;
}) => {
  const { t } = useTranslation();

  const highLightCard = (
    <div className="mb-6 md:mb-0 rounded-2xl bg-blue-gradient bg-left flex text-white justify-between">
      <div className="py-6 px-4 w-[215px]">
        <p className="font-serif text-lg font-bold">
          2024 Presidential Election
        </p>
        <p className="text-sm">Lorem ipsum dolor </p>
        <Button className="mt-10" variant="darkOpacity">
          {t`Bet now`}
        </Button>
      </div>
      <img className="max-w-[150px] rounded-2xl" src={gameThumb} alt="" />
    </div>
  );
  const location = useLocation();
  const { term } = location?.state ?? { term: undefined };

  const { events, loading, pagination, setLoading } = useEventsData({
    ...marketParams,
    ...(term ? { term, page: 1 } : {}),
  });

  const eventsWithTotalBet = events.map((event) => {
    return calculateTotalBetSum(event);
  });
  const { limit } = marketParams;

  return (
    <>
      {!hasInfiniteScroll && (
        <div className="flex flex-col gap-4">
          <h3 className="text-white text-xl font-bold">{t`Highlights`}</h3>
          <div className="flex overflow-auto md:grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {highLightCard}
            {highLightCard}
            {highLightCard}
          </div>
        </div>
      )}

      <MarketsCategories
        onSelect={(categoryId) => {
          const params = {
            ...marketParams,
            category: categoryId,
            page: 1,
          };
          if (!categoryId) {
            delete params.category;
          }
          setLoading(true);
          setMarketParams(params);
        }}
      />

      <div>
        {loading && limit && (
          <div className="md:grid gap-4 md:grid-cols-3 lg:grid-cols-4">
            {Array.from({ length: limit }).map((_, index) => (
              <EventCardSkeleton key={`skeleton-${index}`} />
            ))}
          </div>
        )}

        {!loading && events.length > 0 && (
          <InfiniteScroll
            className={`md:grid gap-4 md:grid-cols-3 lg:grid-cols-4 ${loading ? 'invisible' : ''}`}
            dataLength={events.length}
            threshold={200}
            next={() =>
              setMarketParams({
                ...marketParams,
                page: marketParams.page + 1,
              })
            }
            hasMore={
              hasInfiniteScroll ? pagination?.last !== pagination?.total : false
            }
            loader={<></>}
          >
            {(eventsWithTotalBet ?? []).map((event, key) => {
              return (
                <Link key={`card-${key}`} to={`/market/${event.slug}`}>
                  <EventCard
                    image={event.image}
                    slug={event?.slug}
                    title={event.name}
                    totalBet={+event.totalBet}
                    single={event.single}
                    variant={event.single ? 'singleOutcome' : 'multiOutcome'}
                    markets={event.marketsView}
                  />
                </Link>
              );
            })}
          </InfiniteScroll>
        )}
      </div>
      {events.length === 0 && loading === false && (
        <div className="flex flex-col items-center gap-4 max-w-[340px] text-center mx-auto">
          <NoResults />
          <p className="text-white font-bold text-center text-2xl">{t`Nothing in this Category`}</p>
          <p className="text-sm text-grey-secondary-darker">{t`Oops! There are no markets in this category. Try our search or select other category.`}</p>
        </div>
      )}
      {!hasInfiniteScroll && pagination?.last !== pagination?.total && (
        <Link to="/market-overview" className="mx-auto">
          <Button
            data-testid="button-View-All"
            className="max-w-[120px] mx-auto"
          >{t`View All`}</Button>
        </Link>
      )}
    </>
  );
};
