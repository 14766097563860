import { ReactComponent as ChevronDown } from '../../assets/icons/chevron-down.svg';
import { ReactNode, useState } from 'react';

export const ExpandableComponent = ({
  expanded,
  expandedItems,
  title,
  logo,
  items,
}: {
  title: string;
  logo: string;
  expanded?: boolean;
  expandedItems?: ReactNode;
  items: ReactNode;
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  return (
    <div className="text-sm text-white">
      <div
        className={`border ${expandedItems ? 'border-b-0' : ''} border-grey-secondary rounded-2xl`}
      >
        <div className="flex justify-center items-center gap-2 p-4">
          <img src={logo} width={40} height={40} alt="" className="rounded"/>
          <p className="font-bold">{title}</p>
        </div>
        {items}
        {expandedItems && (
          <div className="border-t-0 border-b border-grey-secondary rounded-t-none rounded-2xl bg-black-lighter p-4 ">
            <div
              className={`${!isExpanded ? 'hidden' : ''} gap-4 flex flex-col mb-3`}
            >
              {expandedItems}
            </div>
            <ChevronDown
              className={`${isExpanded ? 'rotate-180' : ''} text-center m-auto cursor-pointer`}
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
