import { Dispatch, SetStateAction, useState } from 'react';

interface Tab {
  id: string;
  title?: string;
}

export const OrderBookTabs = ({
  tabs,
  onChange,
  border,
  selectedOutcome,
  setSelectedOutcome,
}: {
  tabs: Tab[];
  onChange?: (tab: Tab) => void;
  border?: boolean;
  selectedOutcome?: string;
  setSelectedOutcome?: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const activeTabClassNames = 'text-white border-b-2 border-brand-blue';
  const inactiveTabClassNames = 'text-grey-secondary-darker';
  const [activeTab, setActiveTabs] = useState(tabs[0].id);
  return (
    <div className="font-bold text-sm text-white overflow-auto">
      <div
        className={`flex gap-4 ${border ? 'border-b border-grey-secondary' : ''}`}
      >
        {tabs.map((tab, index) => (
          <p
            key={`tab-${index}`}
            className={`cursor-pointer pb-1 ${(selectedOutcome ?? activeTab) === tab.id ? activeTabClassNames : inactiveTabClassNames}`}
            onClick={() => {
              if (setSelectedOutcome) {
                setSelectedOutcome(tab.id);
              }
              setActiveTabs(tab.id);
              if (onChange) {
                onChange(tab);
              }
            }}
          >
            {tab.title}
          </p>
        ))}
      </div>
    </div>
  );
};
