import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

export const Chart = ({ lineColor }: { lineColor?: string }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: () => '#4f4f4f',
        },
        ticks: {
          color: '#fff',
        },
      },
      y: {
        grid: {
          color: () => '#4f4f4f',
        },
        ticks: {
          color: '#fff',
        },
      },
    },
  };
  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ];

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() =>
          faker.datatype.number({ min: -1000, max: 1000 })
        ),
        borderColor: lineColor ?? '#00B250',
        backgroundColor: lineColor ?? '#00B250',
      },
    ],
  };
  return <Line options={options} data={data} />;
};
