import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import './i18n';
import { translations } from './translations/translations';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ['metawin'],
    resources: translations,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    fallbackLng: 'en',
    debug: false,
  });

export default i18n;
