import React from 'react';
import { useModal } from '../../context/modal-context';
import ModalSuccess from '../../assets/icons/modal-success-icon.png';
import ModalError from '../../assets/icons/modal-error-icon.png';
import ModalProcessing from '../../assets/icons/processing.png';

import { Button } from '../button/button-component';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const ModalComponent: React.FC = () => {
  const { isOpen, content, title, subTitle, type, closeModal, isBottomBar } =
    useModal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={`${isOpen ? 'opacity-100 bg-opacity-50 ease-in-out transition-opacity transition-bg-opacity duration-300' : 'bg-opacity-0 opacity-0 max-w-0 max-h-0 w-0 h-0'} fixed modal-wrapper z-50 backdrop-blur-sm w-full h-full flex items-start justify-center bg-black top-0 left-0 transform-none`}
    >
      <div
        className={`top-[300px] relative bg-black shadow-lg max-w-md w-[100%] ${!isBottomBar ? 'rounded-xl border' : 'rounded-tl-xl rounded-tr-xl'} border-grey-secondary p-6 flex flex-col justify-center  gap-4 w-[100%] md:w-[448px]`}
      >
        {title && (
          <div
            className={`items-center flex flex-col md:flex-row md:gap-0 gap-4 relative w-[100%] text-lg md:text-2xl ${!isBottomBar ? 'justify-center' : 'text-center'}`}
          >
            <p className="text-white  font-bold">{title}</p>
            <div
              className={`${isBottomBar ? 'border-t border-grey-secondary-darker' : ''} w-[100%] md:hidden`}
            ></div>
            <Button
              data-testid="button-X"
              size="custom"
              variant="darkBlack"
              className={`text-white rounded-md absolute right-0 w-[44px] h-[44px] ${isBottomBar ? 'hidden' : ''}`}
              onClick={() => {
                closeModal();
                if (type === 'error' || type === 'success') {
                  navigate(location.pathname, {
                    state: { refetch: !(location?.state?.refetch ?? false) },
                  });
                }
              }}
            >
              <div className="text-base">X</div>
            </Button>
          </div>
        )}
        {subTitle && (
          <p className="text-text-secondary text-base text-center">
            {subTitle}
          </p>
        )}
        {type === 'success' && (
          <img
            className="max-w-[192px] rounded-2xl mx-auto"
            src={ModalSuccess}
            alt=""
          />
        )}
        {type === 'processing' && (
          <img
            className="rounded-2xl max-w-[196px] mx-auto"
            src={ModalProcessing}
            alt=""
          />
        )}
        {type === 'error' && (
          <img
            className="max-w-[200px] rounded-2xl mx-auto"
            src={ModalError}
            alt=""
          />
        )}
        <div className="text-white">{content}</div>
        {(type === 'error' || type === 'success') && (
          <Button
            variant="blue"
            onClick={() => {
              closeModal();
              navigate(location.pathname, {
                state: { refetch: !(location?.state?.refetch ?? false) },
              });
            }}
          >{t`Done`}</Button>
        )}
      </div>
    </div>
  );
};

export default ModalComponent;
